import React, { useState } from "react";
import "../Components/Forget.css";
import coolman from "../Assets/Images/coolman.png";
import { useFormik } from "formik";
import { OffSchemas } from "../Schemas";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
const initialValues = {
  email: "",
};

const ForgetPassword = () => {
  const [loading, setloading] = useState(false);

  const Navigat = useNavigate();

  const onSubmit = async () => {
    // console.log("click");
    setloading(true);
    const formdata = new FormData();
    formdata.append("email", values.email);
    try {
      let cofe = {
        url: "/user/reset_request",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          if (respons.status === 0) {
            toast.error(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          } else if (respons.status === 1) {
            toast.success(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          // console.log(respons);
          setloading(false);
          if (respons.message === "Reset Link has been send to your gmail") {
            Navigat("/Login");
          }
        })
        .catch((error) => {
          // setloading(false),
          toast.error(error, {
            position: "top-right",
            theme: "colored",
          });
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };
  const { values, handleBlur, touched, handleChange, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: OffSchemas,
      onSubmit,
    });
  return (
    <>
      <div className="Forget-password-main-div">
        <div className="forget-password-section-div">
          <div className="Logo-span-mix">
            <div className="Logo-forget-pass">
              <img className="img-logo-forget" src={coolman} />
            </div>
            <div className="Span-long-short">
              <div className="Span-para-forget-divv">
                <span className="Forget-pass-span-tagg">Forgot Password ?</span>
              </div>
              <div className="Plz-enter-email-span">
                <span className="Enter-email-span-ppx">
                  Please enter the email address you ‘d like have your password
                  reset information sent to.
                </span>
              </div>

              <div className="Email-btn-submitt-divv">
                <form onSubmit={handleSubmit}>
                  <div className="email-box-divv mt-4">
                    <div>
                      <svg
                        className="svg-margin"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_18_651)">
                          <path
                            d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
                            fill="#211B24"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_18_651">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <input
                      className="i-t-a"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.email && touched.email ? (
                    <p className="mastu-eroor">{errors.email} </p>
                  ) : null}
                  <div className="btn-submit-divv mt-4">
                    <button type={"submit"} className="submit-btn-forget">
                      Submit
                    </button>
                  </div>
                  <div className="btn-Login-for-divv mt-4">
                    <button
                      type={"button"}
                      className="Login-btn-forget"
                      onClick={() => Navigat("/Login")}
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
