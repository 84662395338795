const mapStyle = [
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        hue: "#9C04F5",
      },
      {
        saturation: -11,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -78,
      },
      {
        hue: "#9C04F5",
      },
      {
        lightness: -47,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        hue: "#9C04F5",
      },
      {
        saturation: -79,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        lightness: 30,
      },
      {
        weight: 1.3,
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        hue: "#9C04F5",
      },
      {
        saturation: -16,
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "all",
    stylers: [
      {
        saturation: -72,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        saturation: -65,
      },
      {
        hue: "#9C04F5",
      },
      {
        lightness: 8,
      },
    ],
  },
];
export default mapStyle;
