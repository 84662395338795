import React, { useState, useEffect } from "react";
import axios from "../Schemas/Api";
import { Backdrop, CircularProgress } from "@mui/material";

const Yourpayouts = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  const [conproperty, setConproperty] = useState([]);
  const [Loading, setLoading] = useState(false);

  const GetSavelist = () => {
    setLoading(true);
    axios({
      method: "get",
      url: "/user/display_payout_calculate",
      headers: {
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setConproperty(data.content);
        setLoading(false);
        // console.log("beta !!!!!", data.content);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };
  useEffect(() => {
    GetSavelist();
  }, []);
  return (
    <>
      <section className="PaymentPayouts-main-section">
        <div className="PaymentPayouts-main-div">
          <span className="pymanbt-span">About your payouts</span>
          <div>
            <div className="mt-4">
              <span
                dangerouslySetInnerHTML={{ __html: conproperty.data }}
              ></span>
            </div>
          </div>
        </div>
      </section>
      {Loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Yourpayouts;
