import React, { useState, useEffect } from "react";
// import CohostPopup from "./CohostPopup";
import "../Components/BankDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import back from "../Assets/Images/Backicon.png";
import { Backdrop, CircularProgress } from "@mui/material";
import LoginNavbar from "./LoginNavbar";
import Footer from "./Footer";
import BankPopup from "./BankPopup";
const BankDetails = () => {
  const [loding, setLoding] = useState(false);
  const [show, setShow] = useState(false);
  const [Bank, setBank] = useState("");
  const token = JSON.parse(localStorage.getItem("login"));
  const handleget = () => {
    setShow(true);
  };
  const navigate = useNavigate();

  const Deletebank = async (item) => {
    // console.log(item, "iiiii");
    setLoding(true);
    try {
      const response = await axios.post(
        "/remove_bank_details",
        {
          bank_id: item.data.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token.data.token.token}`,
          },
        }
      );
      // console.log(response, "ollllooo");
      setLoding(false);
      if (response.data.status == 1) {
        GetBankdetails();
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (response.data.status == 0) {
        toast.error(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    } catch (error) {
      setLoding(false);
      // console.log(error);
    }
  };

  const GetBankdetails = async () => {
    setLoding(true);
    try {
      const response = await axios.post(
        "/get_bank_details",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token.data.token.token}`,
          },
        }
      );
      // console.log(response, "iiiiiii");
      setBank(response.data);
      setLoding(false);
    } catch (error) {
      setLoding(false);
      // console.log(error);
    }
  };
  useEffect(() => {
    GetBankdetails();
  }, []);
  // console.log(Bank);
  return (
    <>
      <LoginNavbar />
      <div>
        <span className="backbtn" onClick={() => navigate(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <BankPopup
        show={show}
        onHide={() => setShow(false)}
        GetBankdetails={GetBankdetails}
      />
      <section className="Searchedpr-main-section">
        <div className="MyProperty-main-div">
          <div className="my-prop-and-cohost">
            <span className="My-prop_cohost">Bank Details</span>
            <div className="add-btn-my" onClick={handleget}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_25_2755)">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
                    fill="#9C04F5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_25_2755">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="add-my-span">Add Bank</span>
            </div>
          </div>
          {Bank.message === "No bank details found" ? (
            <div className="Not-found mt-5">No Banks found</div>
          ) : (
            <div className="Cohost_main_divvv_ppx">
              <div className="d-p-sn-div">
                {/* <div className="storeco-div">
                        <img
                          className="Texas-img"
                          src={curelem.cohost.profile_pic}
                          alt=""
                        />
                      </div> */}
                <div className="date_bank_name">
                  <span className="store-span_name">
                    {Bank?.data?.account_number}
                  </span>
                  <span className="date-span-1">{Bank?.data?.bank_name}</span>
                </div>
                <div
                  onClick={() => Deletebank(Bank)}
                  style={{ cursor: "pointer" }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z"
                      fill="#211B24"
                    />
                  </svg>
                </div>
              </div>
              <div className="underr_the_lineee mt-2"></div>
            </div>
          )}
        </div>
      </section>
      {!loding && <div></div>}
      {loding && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default BankDetails;
