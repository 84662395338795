import React, {useEffect, useState} from "react";
import "../Components/MyWallet.css";
import {Backdrop, CircularProgress} from "@mui/material";
import axios from "../Schemas/Api";
import moment from "moment";
import Footer from "./Footer";
import LoginNavbar from "./LoginNavbar";
import back from "../Assets/Images/Backicon.png";
import {useNavigate} from "react-router-dom";

const Refundreceived = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  const [loding, setLoding] = useState(false);
  const [Loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const [Propaty, setproperty] = useState([]);

  const GetSavelist = () => {
    setLoding(true);
    axios({
      method: "get",
      url: "/refund_receive_history",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setLoding(false);
        setproperty(data.data);
        // console.log("beta !!!!!", data);
      })
      .catch((err) => {
        setLoding(false);
        // console.log(err);
      });
  };
  useEffect(() => {
    GetSavelist();
  }, []);
  return (
    <>
      <LoginNavbar />
      <div>
        <span className="backbtn" onClick={() => Navigate(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <section className="My-Wallet-section">
        <div className="My-Wallet-div">
          <div className="doler-div">
            <div className="histry-input-btn-div">
              <div className="ignor-div">
                <span className="income-span">Refunds received history</span>
              </div>
              <div className="d-p-sn">
                <div className="store-name-div">
                  <span className="store-span">Name</span>
                </div>
                <div className="date-price-div">
                  <span className="date-span">Date</span>
                  <div>
                    <span className="price-span">Refund</span>
                  </div>
                </div>
              </div>
              {!Loading && Propaty?.length >= 1 ? (
                <div>
                  {Propaty?.map((curelem, index) => {
                    return (
                      <div key={index} className="d-p-sn-div">
                        <div className="store-name-div">
                          <img
                            className="Texas1-img"
                            src={curelem.property_image}
                            alt=""
                          />
                          <span className="store-span-1">
                            {curelem.property_address}
                          </span>
                        </div>
                        <div className="date-price-11">
                          <span className="date-span-1">
                            {" "}
                            {moment(curelem.date)
                              .format("YYYY-MM-DD")
                              .toString()}
                          </span>
                          <div>
                            <span className="price-span-1">
                              {curelem.refund_details.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="Not-found">No refunds yet.</div>
              )}
            </div>
          </div>
        </div>
      </section>
      {!loding && <div></div>}
      {loding && (
        <div>
          <Backdrop
            sx={{color: "#9c04f5", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Refundreceived;
