import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { OnSchemas } from "../Schemas";
import Mobile from "../Assets/Images/mobile.png";

const MobileModal = ({ show, onHide }) => {
  const token = JSON.parse(localStorage.getItem("login"));
  const [code, setCode] = useState([]);
  const [open, setOpen] = useState(false);

  const initialValues = {
    Mobile: "",
    CountryCode: "",
    taxpayerid: "",
    taxpayer: "",
  };

  const AddMobileNumber = async () => {
    try {
      const res = await axios.post(
        "/get_mobile_number",
        {
          countryCode: values.CountryCode,
          mobileNumber: values.Mobile,
          tax_payer_name: values.taxpayer,
          tax_payer_id: values.taxpayerid,
        },
        {
          headers: {
            Authorization: `${token.data.token.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(res, "hhhhhhhh");
      // if (res.data.status == 0) {
      //   toast.error(res.data.message, {
      //     position: "top-right",
      //     theme: "colored",
      //   });
      if (res.data.status == 1) {
        localStorage.setItem("login", JSON.stringify(res.data));
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (res.data.status == 0) {
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        // console.log(res, "rrrrrrr");
        setOpen(false);
        // console.log("wasd wasd");
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const GetCountrycode = () => {
    axios({
      method: "get",
      url: "/country_codes",
    })
      .then((res) => res.data)
      .then((data) => {
        setCode(data.data);
        // console.log(data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    GetCountrycode();
  }, []);

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: OnSchemas,
  });

  // console.log(values.CountryCode, "kkkkkk");
  // console.log(values.Mobile, "kkkkkk");

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <div className="Property-card-divv-ppx">
        <div className="Property-ppx-cvv w-100 mt-2">
          <span className="spandivv-ppx-property">Mobile Number</span>
        </div>
        <div className="input-and-country-merge">
          <div className="Ek-svg-hu-me mt-4">
            <div>
              <label htmlFor="Peaky">
                <img className="Mobile-l-ogo" src={Mobile} alt="" />
              </label>
            </div>

            <div className="Dropdown-divv-select-country">
              <select
                name="CountryCode"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.CountryCode}
                className="Drop-country-selact-tag">
                <option disabled={true}>Select Country Code</option>
                {code?.map((item) => {
                  // console.log(item);
                  return (
                    <option key={item.id} value={item.country_code}>
                      {item.country_code} {item.country_name}
                    </option>
                  );
                })}
              </select>
            </div>
            {errors.CountryCode && touched.CountryCode ? (
              <p className="form-errror">{errors.CountryCode} </p>
            ) : null}
            <input
              className="dropdown-mobile-input"
              type="number"
              name="Mobile"
              value={values.Mobile}
              onChange={handleChange}
              placeholder={"Enter Phone number"}
              onBlur={handleBlur}
            />
          </div>
        </div>
        {errors.Mobile && touched.Mobile ? (
          <p
            className="mastu-eroor"
            style={{
              justifyContent: "center",
              // marginRight: "1rem",
              // width: "80%",
            }}>
            {errors.Mobile}{" "}
          </p>
        ) : null}
        <div className="input-and-country-merge">
          <div className="Ek-svg-hu-me mt-4">
            <input
              className="dropdown-mobile-input"
              type="text"
              name="taxpayer"
              value={values.taxpayer}
              onChange={handleChange}
              placeholder="Tax Payer Name"
              onBlur={handleBlur}
              style={{ marginLeft: "1rem" }}
            />
          </div>
        </div>
        {errors.taxpayer && touched.taxpayer ? (
          <p
            className="mastu-eroor"
            style={{
              justifyContent: "center",
              // marginRight: "1rem",
              // width: "80%",
            }}>
            {errors.taxpayer}{" "}
          </p>
        ) : null}
        <div className="input-and-country-merge">
          <div className="Ek-svg-hu-me mt-4">
            <input
              className="dropdown-mobile-input"
              type="text"
              name="taxpayerid"
              value={values.taxpayerid}
              onChange={handleChange}
              placeholder="Tax Number"
              onBlur={handleBlur}
              style={{ marginLeft: "1rem" }}
            />
          </div>
        </div>
        {errors.taxpayerid && touched.taxpayerid ? (
          <p
            className="mastu-eroor"
            style={{
              justifyContent: "center",
              // marginRight: "1rem",
              // width: "80%",
            }}>
            {errors.taxpayerid}{" "}
          </p>
        ) : null}
        <div className="Phone-number-btnn-divv">
          <button
            className="save-btn-phone-code"
            onClick={() => {
              AddMobileNumber();
              setOpen(false);
            }}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MobileModal;
