import React, { useState, useEffect, useContext } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "../Components/MyProfile.css";
import { Routes, Route, Link } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import { Spin as Hamburger } from "hamburger-react";
import axios from "../Schemas/Api";
import Modal from "react-bootstrap/Modal";
import { RiShareFill } from "react-icons/ri";
import { toast } from "react-toastify";
import aryy from "../Assets/Images/backarry.png";
import back from "../Assets/Images/Backicon.png";
import {
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import { GlobalContext } from "../GlobalContext";

const MyProfile = () => {
  const [isOpen, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [Okshows, setOkShows] = useState(false);
  const [coshow, setcoShow] = useState(false);
  const token = JSON.parse(localStorage.getItem("login"));
  const { setOkShow } = useContext(GlobalContext);

  // console.log(token.data.referral_id, "token.data.referral_id");

  // const [save, setSave] = useState();
  // const token = JSON.parse(localStorage.getItem("login"));
  // const pauseid = JSON.parse(localStorage.getItem("Pauseid"));
  // console.log(token);
  const message = `Join me using the Co-host ID: ${token?.data?.referral_id}. Let's make this unforgettable together!`;
  const navigate = useNavigate();

  const handlelogout = () => {
    localStorage.removeItem("login");
    toast.success("User Logged Out Successfully", {
      position: "top-right",
      theme: "colored",
    });
    navigate("/");
    setShow(false);
    // window.location.reload(true);
    navigate("/", { replace: true });
  };

  const no = () => {
    // navigate("/MyProfile");
    setShow(false);
    setOkShows(false);
  };

  const Deleteaccount = async () => {
    axios({
      method: "post",
      url: "/account_delete",
      headers: {
        authorization: `${token?.data?.token?.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.status == 0) {
          localStorage.removeItem("login");
          Navigat("/");
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
        // setLinkVerify(data.data.url);
        // console.log(data);

        // setData(prevData => [...prevData, ...data.data]);
      })
      .catch((err) => {
        // console.log(err.response.data);
      });
  };

  // const shareReferralOnFacebook = () => {
  //   // Initialize Facebook SDK (make sure you have the Facebook SDK initialized)
  //   window.FB.ui({
  //     method: "share",
  //     href: window.location.href, // You can provide your website URL here
  //     quote: `Join me using the Co-host ID: ${referralID}. Let's make this unforgettable together!`,
  //   });
  // };

  const openWhatsAppWeb = () => {
    window.open(
      `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  const shareOnFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href
      )}&quote=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  const shareOnTwitter = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      message
    )}`;
    window.open(tweetUrl, "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        window.location.href
      )}&title=${encodeURIComponent(message)}`,
      "_blank"
    );
  };
  const shareViaEmail = () => {
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(message)}`;
    window.open(mailtoLink);
  };
  const emailSubject = "Join me as a Co-host";
  const emailAddress = "info@homevr.online";
  const Navigat = useNavigate();
  const Popup = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Property-card-divv-ppx">
            <div className="Property-p-cvv mt-2">
              <span className="spandivv-ppx-property">
                Are you sure you want to log out?
              </span>
            </div>

            <div className="Button-divv-prop">
              <div className="btnn-divv-pro-class">
                <button
                  onClick={handlelogout}
                  className="btn-property-stripe-main"
                >
                  Log Out
                </button>
              </div>
              <div className="btnn-divv-pro-later">
                <button onClick={no} className="btn-prop-later-main">
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  const Popu = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Property-card-divv-ppx">
            <div className="Property-ppx-cvv mt-2">
              <span className="spandivv-ppx-property">
                Do you want to Delete Account?
              </span>
            </div>
            <div className="Button-divv-property">
              <div className="btnn-divv-pro-class">
                <button
                  onClick={Deleteaccount}
                  className="btn-property-del-main"
                >
                  Delete Account
                </button>
              </div>
              <div className="btnn-divv-pro-later">
                <button onClick={no} className="btn-pro-Del-main">
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };
  const Cohostshare = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Property-card-divv-ppx">
            <div className="Property-ppx-cvv mt-2">
              <span className="share-ppx-property">Share On:</span>
            </div>
            <div className="Property-p-cvv-added ">
              <span className="sharecoo-ppx-property">
                I've been granted Co-host access for Home Vacation Rentals! Join
                me using the Co-host ID: "{token?.data?.referral_id}". Let's
                make this Home Vacation Rentals unforgettable together!{" "}
              </span>
            </div>
            <div className="Button-divv-property mt-2">
              <button
                onClick={shareOnFacebook}
                style={{ backgroundColor: "#fff" }}
              >
                <FacebookIcon
                  size={32}
                  round={true}
                  style={{ backgroundColor: "#fff" }}
                />
              </button>
              <button
                onClick={openWhatsAppWeb}
                style={{ backgroundColor: "#fff" }}
              >
                <WhatsappIcon
                  size={32}
                  round={true}
                  style={{ backgroundColor: "#fff" }}
                />
              </button>
              <button
                style={{ backgroundColor: "#fff" }}
                onClick={shareOnTwitter}
              >
                <TwitterIcon
                  size={32}
                  round={true}
                  style={{ backgroundColor: "#fff" }}
                />
              </button>
              <button
                onClick={shareOnLinkedIn}
                style={{ backgroundColor: "#fff" }}
              >
                <LinkedinIcon
                  size={32}
                  round={true}
                  style={{ backgroundColor: "#fff" }}
                />
              </button>
              {/* <button onClick={shareViaEmail} style={{backgroundColor: "#fff"}}>
                <EmailIcon
                  size={32}
                  round={true}
                  style={{backgroundColor: "#fff"}}
                /> */}
              {/* </button> */}
              <button style={{ backgroundColor: "#fff" }}>
                <a
                  href={`mailto:info@homevr.online?subject=${encodeURIComponent(
                    emailSubject
                  )}&body=${encodeURIComponent(message)}`}
                  className="Home-spann-sec-para"
                  style={{ backgroundColor: "#fff" }}
                >
                  <EmailIcon
                    size={32}
                    round={true}
                    style={{ backgroundColor: "#fff" }}
                  />
                </a>
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  const handleguest = (path) => {
    // console.log(path, "pathjhh");
    // const currentPath = window.location.pathname;
    if (token?.data?.is_guest === true) {
      setOkShow(true);
    } else if (path === "Delete") {
      setOkShows(true);
    } else {
      Navigat(path);
      // console.log(path, "pathhh");
      setOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("login")) {
      Navigat("/");
    }
  }, []);
  return (
    <>
      {" "}
      <Cohostshare show={coshow} onHide={() => setcoShow(false)} />
      <Popu show={Okshows} onHide={() => setOkShows(false)} />
      <Popup show={show} onHide={() => setShow(false)} />
      <div className="out-lat-second">
        <section className="My-profile-section">
          <div className="My-profile-div">
            <div className="ham-burgar-clssss">
              <Hamburger toggle={() => setOpen(!isOpen)} toggled={isOpen} />
            </div>
            <div className={!isOpen ? "nonne" : "claint-ke-buma-bum"}>
              <div className="main-div-profile">
                <div>
                  <div>
                    <span className="backbtn" onClick={() => navigate("/Home")}>
                      <img src={back} alt="" />
                    </span>
                  </div>
                  <div className="w-100 mb-4 mt-4">
                    <span className="Account-Settings-span">
                      Account Settings
                    </span>
                  </div>

                  <NavLink
                    onClick={() => {
                      setOpen(!isOpen);
                    }}
                    to="/MyProfile/PersonalInformation"
                    className="svg-span-icon"
                  >
                    {" "}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1307)">
                        <path
                          d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1307">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="all-saidebar-span">
                      Personal Information
                    </span>
                  </NavLink>

                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // to="/MyProfile/PaymentPayouts"
                    className="svg-span-icon mt-3"
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    onClick={() => handleguest("/MyProfile/PaymentPayouts")}
                    activeClassName="active"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1311)">
                        <path
                          d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM14 15H18V17H14V15Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1311">
                          {" "}
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="all-saidebar-span">
                      Payments & Payouts
                    </span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>

                  <div className="w-100 mt-4">
                    <span className="Account-Settings-span">Hosting</span>
                  </div>
                  <div
                    // to="/MyProfile/MyProperty"
                    className="svg-span-icon mt-3"
                    onClick={() => setcoShow(true)}
                  >
                    <span className="Share_spandivv">
                      <RiShareFill
                        style={{
                          width: "24px",
                          height: "24px",
                          color: "#9C04F5",
                        }}
                      />
                    </span>
                    <span className="all-saidebar-span">
                      Share Co-host referral id
                    </span>
                  </div>
                  {/* <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div> */}
                  <div
                    // to="/MyProfile/MyProperty"
                    className="svg-span-icon mt-3"
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    onClick={() => handleguest("/MyProfile/MyProperty")}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1303)">
                        <path
                          d="M21 19H23V21H1V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V19H19V11H17V9H20C20.2652 9 20.5196 9.10536 20.7071 9.29289C20.8946 9.48043 21 9.73478 21 10V19ZM5 5V19H13V5H5ZM7 11H11V13H7V11ZM7 7H11V9H7V7Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1303">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="all-saidebar-span">My Property</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    onClick={() => handleguest("/MyProfile/MyBookings")}
                    // to="/MyProfile/MyBookings"
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1295)">
                        <path
                          d="M5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V22.143C20.0001 22.2324 19.9763 22.3202 19.9309 22.3973C19.8855 22.4743 19.8204 22.5378 19.7421 22.5811C19.6639 22.6244 19.5755 22.6459 19.4861 22.6434C19.3968 22.641 19.3097 22.6146 19.234 22.567L12 18.03L4.766 22.566C4.69037 22.6135 4.60339 22.6399 4.5141 22.6424C4.42482 22.6449 4.33649 22.6235 4.2583 22.5803C4.1801 22.5371 4.11491 22.4738 4.06948 22.3969C4.02406 22.32 4.00007 22.2323 4 22.143V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM18 4H6V19.432L12 15.671L18 19.432V4Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1295">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">My Travelling</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    onClick={() => handleguest("/MyProfile/Twomybooking")}
                    // to="/MyProfile/Twomybooking"
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1295)">
                        <path
                          d="M5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V22.143C20.0001 22.2324 19.9763 22.3202 19.9309 22.3973C19.8855 22.4743 19.8204 22.5378 19.7421 22.5811C19.6639 22.6244 19.5755 22.6459 19.4861 22.6434C19.3968 22.641 19.3097 22.6146 19.234 22.567L12 18.03L4.766 22.566C4.69037 22.6135 4.60339 22.6399 4.5141 22.6424C4.42482 22.6449 4.33649 22.6235 4.2583 22.5803C4.1801 22.5371 4.11491 22.4738 4.06948 22.3969C4.02406 22.32 4.00007 22.2323 4 22.143V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM18 4H6V19.432L12 15.671L18 19.432V4Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1295">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">
                      {" "}
                      Booking My Property
                    </span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    onClick={() => handleguest("/MyProfile/Jointimeshare")}
                    to="/MyProfile/Jointimeshare"
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_929)">
                        <path
                          d="M21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM19 20V4H5V20H19ZM8 11H11V13H8V11ZM8 7H11V9H8V7ZM8 15H11V17H8V15ZM13 15H16V17H13V15ZM13 11H16V13H13V11ZM13 7H16V9H13V7Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_929">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Timeshare</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    // to="/MyProfile/Timeshare"
                    onClick={() => handleguest("/MyProfile/Timeshare")}
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_929)">
                        <path
                          d="M21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM19 20V4H5V20H19ZM8 11H11V13H8V11ZM8 7H11V9H8V7ZM8 15H11V17H8V15ZM13 15H16V17H13V15ZM13 11H16V13H13V11ZM13 7H16V9H13V7Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_929">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">
                      Apply to join timeshare club
                    </span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    // to={"/MyProfile/Wishlist"}
                    onClick={() => handleguest("/MyProfile/Wishlist")}
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_933)">
                        <path
                          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604V18.604Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_933">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Wishlist</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div className="w-100 mt-4">
                    <span className="Account-Settings-span">Support</span>
                  </div>
                  <div className="svg-span-icon mt-4">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_961)">
                        <path
                          d="M21 8.00002C21.5304 8.00002 22.0391 8.21074 22.4142 8.58581C22.7893 8.96088 23 9.46959 23 10V14C23 14.5305 22.7893 15.0392 22.4142 15.4142C22.0391 15.7893 21.5304 16 21 16H19.938C19.6942 17.9333 18.7533 19.7112 17.2917 21C15.8302 22.2888 13.9486 23 12 23V21C13.5913 21 15.1174 20.3679 16.2426 19.2427C17.3679 18.1174 18 16.5913 18 15V9.00002C18 7.40872 17.3679 5.8826 16.2426 4.75738C15.1174 3.63216 13.5913 3.00002 12 3.00002C10.4087 3.00002 8.88258 3.63216 7.75736 4.75738C6.63214 5.8826 6 7.40872 6 9.00002V16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0392 1 14.5305 1 14V10C1 9.46959 1.21071 8.96088 1.58579 8.58581C1.96086 8.21074 2.46957 8.00002 3 8.00002H4.062C4.30603 6.06692 5.24708 4.2893 6.70857 3.0007C8.17007 1.7121 10.0516 1.0011 12 1.0011C13.9484 1.0011 15.8299 1.7121 17.2914 3.0007C18.7529 4.2893 19.694 6.06692 19.938 8.00002H21ZM7.76 15.785L8.82 14.089C9.77308 14.6861 10.8754 15.0018 12 15C13.1246 15.0018 14.2269 14.6861 15.18 14.089L16.24 15.785C14.9693 16.5812 13.4995 17.0023 12 17C10.5005 17.0023 9.03074 16.5812 7.76 15.785Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_961">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">
                      <a
                        href="mailto:info@homevr.online"
                        className="Home-spann-sec-para"
                      >
                        Get help{" "}
                      </a>
                    </span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    onClick={() => handleguest("/MyProfile/ChangePassword")}
                    // to="/MyProfile/ChangePassword"
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_965)">
                        <path
                          d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_965">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Change Password</span>
                  </div>

                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => setOkShows(true)}
                    onClick={() => handleguest("Delete")}
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z"
                        fill="#9c04f5"
                      />
                    </svg>
                    <div className="span-clor-change-del">
                      <span className="all-saidebar-span">Delete Account</span>
                    </div>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div className="svg-span-icon mt-3">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_953)">
                        <path
                          d="M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM8 7H16V9H8V7ZM8 11H16V13H8V11ZM8 15H13V17H8V15Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_953">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div className="span-clor-change-del">
                      <a
                        href="https://app.termly.io/document/terms-and-conditions/b39a8290-14e1-47ea-b654-89b54d7d8a69"
                        className="all-saidebar-span"
                      >
                        Terms & Conditions
                      </a>
                    </div>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    className="svg-span-icon mt-3"
                    onClick={() => setShow(true)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_957)">
                        <path
                          d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_957">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Logout</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                </div>
                <div>
                  <div className="log-line-w"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="My-profile-section-22">
          <div className="My-profile-div">
            <div>
              <span className="backbtn" onClick={() => navigate("/Home ")}>
                <img src={back} alt="" />
              </span>
              <div className="main-div-profile">
                <div>
                  <div className="w-100 mb-4">
                    <span className="Account-Settings-span">
                      Account Settings
                    </span>
                  </div>

                  <NavLink
                    onClick={() => {
                      setOpen(!isOpen);
                    }}
                    to="/MyProfile/PersonalInformation"
                    className="svg-span-icon mt-3"
                  >
                    {" "}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1307)">
                        <path
                          d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1307">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="all-saidebar-span">
                      Personal Information
                    </span>
                  </NavLink>

                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    className="svg-span-icon mt-3"
                    onClick={() => handleguest("/MyProfile/PaymentPayouts")}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1311)">
                        <path
                          d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM14 15H18V17H14V15Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1311">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="all-saidebar-span">
                      Payments & Payouts
                    </span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div className="w-100 mt-4">
                    <span className="Account-Settings-span">Hosting</span>
                  </div>
                  <div
                    // to="/MyProfile/MyProperty"
                    className="svg-span-icon mt-4"
                    onClick={() => setcoShow(true)}
                  >
                    <span className="Share_spandivv">
                      <RiShareFill
                        style={{
                          width: "24px",
                          height: "24px",
                          color: "#9C04F5",
                        }}
                      />
                    </span>
                    <span className="all-saidebar-span">
                      Share Co-host referral id
                    </span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // to="/MyProfile/MyProperty"
                    className="svg-span-icon mt-3"
                    onClick={() => handleguest("/MyProfile/MyProperty")}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1303)">
                        <path
                          d="M21 19H23V21H1V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V19H19V11H17V9H20C20.2652 9 20.5196 9.10536 20.7071 9.29289C20.8946 9.48043 21 9.73478 21 10V19ZM5 5V19H13V5H5ZM7 11H11V13H7V11ZM7 7H11V9H7V7Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1303">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="all-saidebar-span">My Property</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    // to="/MyProfile/MyBookings"
                    className="svg-span-icon mt-3"
                    onClick={() => handleguest("/MyProfile/MyBookings")}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1295)">
                        <path
                          d="M5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V22.143C20.0001 22.2324 19.9763 22.3202 19.9309 22.3973C19.8855 22.4743 19.8204 22.5378 19.7421 22.5811C19.6639 22.6244 19.5755 22.6459 19.4861 22.6434C19.3968 22.641 19.3097 22.6146 19.234 22.567L12 18.03L4.766 22.566C4.69037 22.6135 4.60339 22.6399 4.5141 22.6424C4.42482 22.6449 4.33649 22.6235 4.2583 22.5803C4.1801 22.5371 4.11491 22.4738 4.06948 22.3969C4.02406 22.32 4.00007 22.2323 4 22.143V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM18 4H6V19.432L12 15.671L18 19.432V4Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1295">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">My Traveling</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}

                    // to="/MyProfile/Twomybooking"
                    className="svg-span-icon mt-3"
                    onClick={() => handleguest("/MyProfile/Twomybooking")}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1295)">
                        <path
                          d="M5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V22.143C20.0001 22.2324 19.9763 22.3202 19.9309 22.3973C19.8855 22.4743 19.8204 22.5378 19.7421 22.5811C19.6639 22.6244 19.5755 22.6459 19.4861 22.6434C19.3968 22.641 19.3097 22.6146 19.234 22.567L12 18.03L4.766 22.566C4.69037 22.6135 4.60339 22.6399 4.5141 22.6424C4.42482 22.6449 4.33649 22.6235 4.2583 22.5803C4.1801 22.5371 4.11491 22.4738 4.06948 22.3969C4.02406 22.32 4.00007 22.2323 4 22.143V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM18 4H6V19.432L12 15.671L18 19.432V4Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1295">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">
                      Booking My Property
                    </span>
                  </div>
                  {/* <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div className="svg-span-icon mt-3">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_1299)">
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM7 12H9C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12H17C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_1299">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Host & Experience</span>
                  </div> */}
                  {/* <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div className="svg-span-icon mt-3">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_925)">
                        <path
                          d="M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824V20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711V18.711Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_925">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Chat</span>
                  </div> */}
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>

                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    // to="/MyProfile/Jointimeshare"
                    onClick={() => handleguest("/MyProfile/Jointimeshare")}
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_929)">
                        <path
                          d="M21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM19 20V4H5V20H19ZM8 11H11V13H8V11ZM8 7H11V9H8V7ZM8 15H11V17H8V15ZM13 15H16V17H13V15ZM13 11H16V13H13V11ZM13 7H16V9H13V7Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_929">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Timeshare</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    // to="/MyProfile/Timeshare"
                    className="svg-span-icon mt-3"
                    onClick={() => handleguest("/MyProfile/Timeshare")}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_929)">
                        <path
                          d="M21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM19 20V4H5V20H19ZM8 11H11V13H8V11ZM8 7H11V9H8V7ZM8 15H11V17H8V15ZM13 15H16V17H13V15ZM13 11H16V13H13V11ZM13 7H16V9H13V7Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_929">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">
                      Apply to join timeshare club
                    </span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    // to={"/MyProfile/Wishlist"}
                    className="svg-span-icon mt-3"
                    onClick={() => handleguest("/MyProfile/Wishlist")}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_933)">
                        <path
                          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604V18.604Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_933">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Wishlist</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div className="w-100 mt-4">
                    <span className="Account-Settings-span">Support</span>
                  </div>
                  <div className="svg-span-icon mt-4">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_961)">
                        <path
                          d="M21 8.00002C21.5304 8.00002 22.0391 8.21074 22.4142 8.58581C22.7893 8.96088 23 9.46959 23 10V14C23 14.5305 22.7893 15.0392 22.4142 15.4142C22.0391 15.7893 21.5304 16 21 16H19.938C19.6942 17.9333 18.7533 19.7112 17.2917 21C15.8302 22.2888 13.9486 23 12 23V21C13.5913 21 15.1174 20.3679 16.2426 19.2427C17.3679 18.1174 18 16.5913 18 15V9.00002C18 7.40872 17.3679 5.8826 16.2426 4.75738C15.1174 3.63216 13.5913 3.00002 12 3.00002C10.4087 3.00002 8.88258 3.63216 7.75736 4.75738C6.63214 5.8826 6 7.40872 6 9.00002V16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0392 1 14.5305 1 14V10C1 9.46959 1.21071 8.96088 1.58579 8.58581C1.96086 8.21074 2.46957 8.00002 3 8.00002H4.062C4.30603 6.06692 5.24708 4.2893 6.70857 3.0007C8.17007 1.7121 10.0516 1.0011 12 1.0011C13.9484 1.0011 15.8299 1.7121 17.2914 3.0007C18.7529 4.2893 19.694 6.06692 19.938 8.00002H21ZM7.76 15.785L8.82 14.089C9.77308 14.6861 10.8754 15.0018 12 15C13.1246 15.0018 14.2269 14.6861 15.18 14.089L16.24 15.785C14.9693 16.5812 13.4995 17.0023 12 17C10.5005 17.0023 9.03074 16.5812 7.76 15.785Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_961">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div className="Home-spann-section-divv">
                      <a
                        href="mailto:info@homevr.online"
                        className="Home-spann-sec-para"
                      >
                        Get help
                      </a>
                    </div>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => {
                    //   setOpen(!isOpen);
                    // }}
                    // to="/MyProfile/ChangePassword"
                    onClick={() => handleguest("/MyProfile/ChangePassword")}
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_965)">
                        <path
                          d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_965">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Change Password</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    // onClick={() => setOkShows(true)}
                    onClick={() => handleguest("Delete")}
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z"
                        fill="#9c04f5"
                      />
                    </svg>
                    <div className="span-clor-change-del">
                      <span className="all-saidebar-span">Delete Account</span>
                    </div>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div className="svg-span-icon mt-3">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_953)">
                        <path
                          d="M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM8 7H16V9H8V7ZM8 11H16V13H8V11ZM8 15H13V17H8V15Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_953">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div className="Home-spann-section-divv">
                      <a
                        href="https://app.termly.io/document/terms-and-conditions/b39a8290-14e1-47ea-b654-89b54d7d8a69"
                        className="Home-spann-sec-para"
                      >
                        Terms & Conditions
                      </a>
                    </div>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                  <div
                    onClick={() => setShow(true)}
                    className="svg-span-icon mt-3"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_67_957)">
                        <path
                          d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"
                          fill="#9C04F5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_67_957">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="all-saidebar-span">Logout</span>
                  </div>
                  <div className="e-x-tra mt-2">
                    <div className="line-icon-div"></div>
                  </div>
                </div>
                <div>
                  <div className="log-line-w"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Outlet />
      </div>
    </>
  );
};

export default MyProfile;
