import React from "react";
import "../Components/FreeTime.css";
// import Time from "../Assets/Images/FreeTimeImg.png";
// import timeimg from "../Assets/Images/timeimg.webp";
import ftm1 from "../Assets/Images/ftm1.png";
import NearYou from "../Components/NearYou";
import HowItWorks from "../Components/HowItWorks";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const FreeTime = () => {
  const navigate = useNavigate();
  return (
    <>
      <NearYou />
      <HowItWorks />
      <section className="FreeTime-main-section">
        <div className="FreeTime-main-div">
          <motion.div
            initial={{ x: -25, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <img className="freetime-img-1" src={ftm1} alt="" />
            {/* image change */}
          </motion.div>
          <motion.div
            initial={{ x: 25, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="timeshare-span-div-2"
          >
            {/* <span className="timeshare-span">Free timeshare for hosts</span> */}
            <span className="timeshare-span">Free Homeswap for hosts</span>

            <div className="mt-3">
              <span className="adipiscing-span">
                Don't let the days that your property has no bookings go to
                waste. Gift some of those days to other hosts and in return,
                receive something similar.
              </span>
            </div>
            <div className="mt-2 buttn-to">
              <div className="Time-share-no">
                <button
                  className="Know-More-Fit"
                  onClick={() => navigate("/Timeshare-rules")}
                >
                  {/* Discover The Timeshare Rules */}
                  Discover The Host Homeswap Rules
                </button>
                <button
                  className="Know-More-btn-too"
                  onClick={() => navigate("/Timeshare-poolstar-ranking")}
                >
                  {/* Timeshare Pool Star Ranking How it Works */}
                  Homeswap Star Grading How it Works
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default FreeTime;
