import React from "react";
import "../Components/BecomeHost.css";
import fingar from "../Assets/Images/fingarimg.png";
import tir from "../Assets/Images/tirimg.png";
const BecomeHost = () => {
  return (
    <section className="BecomeHost-sec">
      <div className="BecomeHost-main-div">
        <div className="next-div">
          <div>
            <div>
              <img className="fingar-img" src={fingar} alt="" />
            </div>
            <div className="mt-3">
              <span className="r-span">Review Process</span>
            </div>
            <div className="span-r-div mt-3">
              <span className="fair-span">
                Fair reviews from both host and guest
              </span>
              <div>
                <span className="r-span2">released simultaneously</span>
              </div>
            </div>
            <div className="mt-5 btn-divo">
              <button className="out-btn">Find out More</button>
              <div>
                <button className="b-btn">Become a Host</button>
              </div>
            </div>
          </div>
          <div className="mb-5">
            <div>
              <img className="tir-img" src={tir} alt="" />
            </div>
            <div className="mt-3">
              <span className="r-span">Mission statement</span>
            </div>
            <div className="span-r-div mt-3">
              <span className="fair-span">
                Provide diversely, promote togetherness and
              </span>
              <div>
                <span className="r-span2">expand opportunities.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BecomeHost;
