import React from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import "../Components/Invoicehistory.css";
const Invoicehistory = () => {
  let route = useLocation();
  const maindata = route.state;
  // console.log(maindata);
  return (
    <>
      <div>
        <div className="invoice-container">
          <div className="invoice-header">
            <img
              src="https://homevr.online/static/media/Home%20logo.02a32df9ec388dcaf1f0.png"
              height="50px"
            />
            <h1>Invoice</h1>
            <p>Invoice Number: HOMEVR-208</p>
            <p>Date: {moment().format("DD MMMM YYYY")} </p>
          </div>
          <div className="invoice-details">
            <div className="invoice-address">
              <h3>Host</h3>
              <p>Host Name : 5000</p>
              <p>Property Address : delhi</p>
              <p>User registration : 208</p>
            </div>
            <div className="invoice-address">
              <h3>Customer</h3>
              <p>Customer Name : fullname</p>
            </div>
          </div>
          <div className="d-p-sn">
            <div className="date-price-div">
              <span className="income-span">Invoice date</span>
              <div>
                <span className="description-span">service description</span>
              </div>
            </div>
            <div className="store-name-div">
              <span className="store-span">Total</span>
            </div>
          </div>
          <div className="date-price-total-per-div">
            <div className="date-inc-main-divv mt-3">
              <div className="Date-service-main">
                <div className="div-inc-dte-spann">
                  <span className="invoice-spann">
                    {" "}
                    {moment().format("YYYY-MM-DD").toString()}
                  </span>
                </div>
                <div>
                  <span className="service-span">price-per-night</span>
                </div>
              </div>
              <div className="div-total-main">
                <span className="total-span">Total</span>
              </div>
            </div>
          </div>
          <div className="underline-divv-invoice mt-2"></div>
          <div className="date-price-total-per-div">
            <div className="inc-main-divv mt-3">
              <div className="Date-service-main">
                <div className="div-inc-dte-spann"></div>
                <div>
                  <span className="service-span">booking fees</span>
                </div>
              </div>
              <div className="div-total-main">
                <span className="total-span">Total</span>
              </div>
            </div>
          </div>
          <div className="underline-setting-divv">
            <div className="book-underline-divv-invoice mt-2"></div>
          </div>
          <div className="date-price-total-per-div">
            <div className="inc-main-divv mt-3">
              <div className="Date-service-main">
                <div className="div-inc-dte-spann"></div>
                <div>
                  <span className="service-span">cleaning fees</span>
                </div>
              </div>
              <div className="div-total-main">
                <span className="total-span">Total</span>
              </div>
            </div>
          </div>
          <div className="underline-setting-divv">
            <div className="book-underline-divv-invoice mt-2"></div>
          </div>
          <div className="Total-main-uuu">
            <span className="Total-tagg-span">Total: € 55511</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoicehistory;
