import React, { useContext, useState } from "react";
import "../Components/AfterLogin.css";
import Windo from "../Assets/Images/WindoHome.png";
import Longsedo2 from "../Assets/Images/Longsedo2.png";
import PropertyType from "../Components/PropertyType";
import LoginNavbar from "./LoginNavbar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Footer from "./Footer";
import MobileModal from "./MoblieModal";
import { GlobalContext } from "../GlobalContext";

const initialValues = {
  Mobile: "",
  CountryCode: "",
};
const AfterLogin = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  // console.log(token.data.identity_verified, "fghjjgfhgf");
  // console.log(token.data.is_guest, "jsajcasbc");
  // console.log(token?.data?.token?.token, "jsajcasbc");

  // console.log(token, "mili");
  const [showMore, setShowMore] = useState(false);
  const [loading, setloading] = useState(false);
  // const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [wishlistdata, setwishlistdata] = useState([]);
  const [neaddata, setneardata] = useState([]);
  const [notification, setNotification] = useState([]);
  const [search, setSearch] = useState([]);
  const { setOkShow, show, setShow } = useContext(GlobalContext);

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (token.data.is_guest === true) {
      setOkShow(true);
    } else {
      setShow(true);
    }
  };

  // console.log(token.token);
  const Search = async (txt) => {
    const formdata = new FormData();
    formdata.append("q", txt);
    try {
      let research = {
        url: "/search_property",
        method: "Get",
        data: formdata,
        headers: {
          Authorization: `${token?.data?.token?.token}`,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };
      axios(research)
        .then((res) => res.data)
        .then((response) => {
          setSearch(response);
          // console.log(response);
        });
    } catch (error) {
      // console.log(error);
      if (error.message === "Network Error") {
        localStorage.removeItem("login");
        Navigat("/");
        toast.error(error.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    }
  };

  // useEffect(() => {
  //   Search();
  // }, []);

  const Notify = async () => {
    try {
      let find = {
        url: "/get_notification_count",
        method: "Get",
        headers: {
          Authorization: `${token?.data?.token?.token}`,
        },
      };
      axios(find)
        .then((res) => res.data)
        .then((response) => {
          setNotification(response);
          // console.log(response);
        });
    } catch (error) {
      // console.log(error);
      if (error.message == "Network Error") {
        localStorage.removeItem("login");
        Navigat("/");
        toast.error(error.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    }
  };
  useEffect(() => {
    Notify();
  }, []);

  const getnearproperty = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("lat", localStorage.getItem("lat"));
    formdata.append("lon", localStorage.getItem("lan"));

    try {
      let config = {
        url: "/nearest",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `${token?.data?.token?.token}`,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((respons) => {
          // console.log("data...", respons.Property_data);
          // console.log(respons, "rtyui");
          setneardata(respons.Property_data);
          setloading(false);
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
      if (error.request.status === 0) {
        localStorage.removeItem("login");
        Navigat("/");
        toast.error(error.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    }
  };

  // useEffect(() => {}, []);

  // useEffect(() => {
  //   const lat = localStorage.getItem("lat");
  //   const lon = localStorage.getItem("lan");

  //   if (lat && lon) {
  //     getnearproperty();
  //   } else if (!lat && !lon) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         localStorage.setItem("lat", position.coords.latitude);
  //         localStorage.setItem("lan", position.coords.longitude);
  //         getnearproperty();
  //       },
  //       (error) => {
  //         console.error(error);
  //         setloading(false);
  //         getnearproperty(); // Call the API even if geolocation access is denied
  //       }
  //     );
  //   } else {
  //     getnearproperty();
  //   }
  // }, []);

  useEffect(() => {
    const lat = localStorage.getItem("lat");
    const lon = localStorage.getItem("lan");

    if (lat && lon) {
      getnearproperty();
    } else {
      const geolocationTimeout = setTimeout(() => {
        // If the user does not respond to the geolocation prompt
        // console.log("User did not respond to geolocation prompt, calling API with default values");
        getnearproperty();
      }, 2000); // 10 seconds timeout

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(geolocationTimeout);
          localStorage.setItem("lat", position.coords.latitude);
          localStorage.setItem("lan", position.coords.longitude);
          getnearproperty();
        },
        (error) => {
          clearTimeout(geolocationTimeout);
          console.error(error);
          getnearproperty();
        }
      );
    }
  }, []);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(() => { });
  }, []);
  useEffect(() => {
    if (token?.data?.identity_verified === false) {
      localStorage.removeItem("login");
      navigate("/Login");
    } else if (!localStorage.getItem("login")) {
      navigate("/");
    }
  }, []);
  // console.log(neaddata)

  const handledetail = async (id) => {
    const newdata = {
      index: "2",
      id: { ...id, beBhai: id.property_data?.id },
    };
    navigate("/PropertyDetails", {
      state: {
        newdata,
      },
    });
  };

  const [Booking, setBooking] = useState([]);

  const velu = JSON.parse(localStorage.getItem("Remov"));

  const Navigat = useNavigate();
  const wishlist = async (id) => {
    const data = {
      property_id: id,
    };

    try {
      const res = await axios.post(
        "/wishlist",
        {
          property_id: data.property_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token?.data?.token?.token}`,
          },
        }
      );
      if (res.data.status === 1) {
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (res.data.status === 0) {
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      getnearproperty();
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (
        token?.data?.social_id &&
        token?.data?.has_number == false &&
        token?.data?.phone_number == ""
      ) {
        setOpen(true);
      }
    }, 3000);
  }, [token]);

  // this code for home screen navifation
  // useEffect(() => {
  //   if (!token || !token.data || !token.data.token || !token.data.token.token) {
  //     navigate("/");
  //   }
  // }, [token]);
  // useEffect(() => {
  //   if (!localStorage.getItem("login")) {
  //     Navigat("/");
  //   }
  // }, []);

  // handleguest function
  const handleGuest = () => {
    if (token.data.is_guest === true) {
      setOkShow(true);
    } else {
      navigate("/Search");
    }
  };
  const handleGuest1 = () => {
    // if (token.data.is_guest === true) {
    //   setOkShow(true);
    // } else {
    navigate("/Know-more");
    // }
  };
  const ico = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2257)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z"
          fill="#9C04F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2257">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const ico2 = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2238)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2238">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  // console.log(velu)
  const naviga = useNavigate();

  return (
    <>
      <LoginNavbar />
      <MobileModal show={open} onHide={() => setOpen(false)} />
      <PropertyType show={show} onHide={() => setShow(false)} />
      <section className="AfterLogin-sec">
        <div className="AfterLogin-main-div">
          <div className="drop-svg-main">
            <div className="svg-p-div ">
              {" "}
              <div>
                <svg
                  className="svg-margin"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_20_1236)">
                    <path
                      d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875Z"
                      fill="#211B24"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_20_1236">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <input
                className="i-t-a"
                // onClick={() => navigate("/Search")}
                onClick={handleGuest}
                type="search"
                id="gsearch"
                name="gsearch"
                onChange={(e) => {
                  Search(e.target.value);
                }}
                placeholder="Search"
              />
            </div>

            <button onClick={handleShow} className="drop-down-div">
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z"
                  fill="white"
                />
              </svg>
              <span className="Read-less-span">Filter</span>
            </button>
          </div>
          {showMore && <PropertyType />}

          {!showMore ? (
            <div className="pozison-ko-handel">
              <div className="Aftar-login-img">
                <img className="wimndo-img" src={Windo} alt="" />
                <div className="aftar-login-div">
                  <img className="Long-sedo-img" src={Longsedo2} alt="" />
                </div>
              </div>
              <div className="oppo-phone">
                <div className="placeee-divvv">
                  <span className="perfect-place-span">
                    Find the perfect home for your holiday accommodation
                  </span>
                </div>
                <div className="span-div-place"></div>
                <div className="Know-More-div">
                  <button
                    className="Know-More-btn"
                    // onClick={() => navigate("/Knowmore")}
                    onClick={handleGuest1}
                  >
                    Know More
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="nearyou">
          <span className="Near-You-span-taggg">Near You</span>

          {loading ? (
            <div className="loding-rolar">
              <CircularProgress />
            </div>
          ) : (
            <div className="carddivmain">
              {neaddata?.map((curelem, index) => {
                return (
                  <div key={index}>
                    <div className="mainnn-divvv-svg" key={index}>
                      <div
                        className="star-svgg-main-divv-ppx"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="bangloz-img-11"
                          src={curelem.images[0]?.picture_name}
                          alt=""
                          onClick={() => handledetail(curelem)}
                        />
                      </div>
                      <div className="svg-span-div-main-span">
                        <div className="svg-divv-avg">
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_2_446)">
                              <path
                                d="M7 9.91671L3.57117 12.0109L4.50333 8.10254L1.4525 5.48921L5.45708 5.16837L7 1.45837L8.54292 5.16837L12.5481 5.48921L9.49667 8.10254L10.4288 12.0109L7 9.91671Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2_446">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="svg-divv-avg">
                          <span>{curelem.property_data.average_ratings}</span>
                        </div>
                        <div className="d-flex">
                          <span className="Grandview-span-1">
                            {curelem.Location}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="Accessible-span">
                          {curelem.property_data.title}
                        </span>
                        <span className="Accessible-span">
                          {curelem.property_data.address.slice(0, 25)}...
                        </span>
                      </div>
                      <div className="mt-3 span-naghit-div-main">
                        <div>
                          <span className="night-span">
                            €{curelem.property_data.price_per_night}{" "}
                          </span>
                          <span className="night-span-2">/ night</span>
                        </div>

                        {!token.data.is_guest && (
                          <div
                            onClick={() => wishlist(curelem.property_data.id)}
                            style={{ cursor: "pointer" }}
                          >
                            {curelem.property_data.wishlist.wishlist ===
                              true ? (
                              <span>{ico}</span>
                            ) : curelem.property_data.wishlist.wishlist ===
                              false ? (
                              <span>{ico2}</span>
                            ) : (
                              <span>{ico2}</span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <Footer />
      </section>
    </>
  );
};

export default AfterLogin;
