import React, { useState, useEffect, useCallback, Children } from "react";
import "../Components/BookingRequest.css";
import Reqast from "../Assets/Images/Reqast.png";
import Visa from "../Assets/Images/VisaPay.png";
import logo from "../Assets/Images/orangyallow.png";
import pay from "../Assets/Images/Paypal.png";
import aryy from "../Assets/Images/backarry.png";
import { useFormik } from "formik";
import { ortSchemas } from "../Schemas";
import axios from "../Schemas/Api";
import DateRangePicker from "rsuite/DateRangePicker";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Payment from "./Payment";
import back from "../Assets/Images/Backicon.png";

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { usePaymentInputs } from "react-payment-inputs";
import Modal from "react-bootstrap/Modal";
import Stripecontainor from "./Stripecontainor";
import Footer from "./Footer";
import { Navbar } from "react-bootstrap";
import LoginNavbar from "./LoginNavbar";
import { Grid } from "rsuite";

// console.log(stripePromise);
const initialValues = {
  text: "",
  Coupon: "",
  Cardnumber: "",
  Jerome: "",
};

const BookingRequest = () => {
  let route = useLocation();
  const budy = route.state;
  // console.log(budy, "ho gai der");

  // console.log(id.property_data.id);
  // console.log(rout.state, "ids-------");
  const [add, setAdd] = useState();
  // console.log(add, "adddd nkisx");
  // console.log(add?.data?.booking_fees, "bboking fees");

  // const [pay, setPay] = useState();
  // console.log(add.data, "dckucbhlij");
  const [startDate, setStartDate] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  // console.log({ errorMessage }, "  {errorMessage}");
  const [CouponValue, setCouponValue] = useState("");
  const [Chadul, setChadul] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setloading] = useState(false);
  const [use, setUse] = useState(budy.id.property_detail);
  const [promptVisible, setPromptVisible] = useState(false);

  const children = budy?.id?.property_data?.child;
  // console.log(children, "ch");

  const adult = budy?.id?.property_data?.adult;
  // console.log(adult, "ad");

  useEffect(() => {
    const chaild = budy?.id?.property_data?.child;
    // const adults = budy.id.property_detail.adult;
    if (budy?.id?.property_data?.adult && budy?.id?.property_data?.child) {
      setCount(budy?.id?.property_data?.child);
      setPluss(budy?.id?.property_data?.adult);
      setChadul(true);
    }

    setUse([]);
  }, []);
  // console.log(use, "use mam");
  const [selectedDates, setSelectedDates] = useState(
    budy?.PuraData?.milja?.searchResults
  );
  // console.log(selectedDates, "selectedDates");
  useEffect(() => {
    const startDate = moment().startOf("day").toDate();
    const endDate = moment().add(1, "days").endOf("day").toDate();
    setSelectedDates([startDate, endDate]);
  }, []);

  // ye code mene add kiya hai
  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };
  // console.log(selectedDates, "selectedDatesugyg");
  const handleDateChange = (newDates) => {
    // Filter out past dates
    const today = new Date();
    const filteredDates = newDates.map((date) =>
      date <= today ? today : date
    );
    let [startDate, endDate] = filteredDates;
    if (startDate && endDate && startDate.getTime() === endDate.getTime()) {
      // If start date and end date are the same, adjust the end date by adding one day
      endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 1);
      filteredDates[1] = endDate;
    }
    setSelectedDates(filteredDates);
    // const isDisabled = newDates.some((date) => isDateDisabled(date));
    const isDisabled = newDates
      .filter((date) => !isToday(date))
      .some((date) => isDateDisabled(date));

    // console.log(filteredDates, "filterdates");
    // console.log(isDisabled, "isdisabel");

    if (isDisabled) {
      setPromptVisible(true);
    } else {
      setPromptVisible(false);
    }
  };

  const [Orignel, setOrignel] = useState(budy?.id?.property_detail);

  const token = JSON.parse(localStorage.getItem("login"));
  const [ChildrenMain, setChildrenMain] = useState(0);
  const [AdultsMain, setAdultsMain] = useState(1);
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();

  const Booking = async () => {
    setloading(true);
    if (selectedDates && selectedDates.length >= 2) {
      const start_date = moment(selectedDates[0])?.format("YYYY-MM-DD");
      const end_date = moment(selectedDates[1])?.format("YYYY-MM-DD");

      axios({
        method: "post",
        url: "/total_amount",
        data: {
          property_id: budy?.id?.property_data?.id,
          start_date: start_date,
          end_date: end_date,
          coupon: CouponValue,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: token?.data?.token?.token,
        },
      })
        .then((res) => res.data)
        .then((data) => {
          setloading(false);
          // console.log(data.data, "data.data")
          setAdd(data);
        })
        .catch((err) => {
          setloading(false);
        });
    } else {
      // Handle the case when selectedDates is not yet populated
      setloading(false);
      console
        .error
        // "selectedDates is undefined or does not have enough elements"
        ();
    }
  };
  useEffect(() => {
    Booking();
  }, [selectedDates]);
  // New booking -----------------------------------
  const [neaddata, setneardata] = useState([]);
  const [coupons, setCoupons] = useState([]);
  // console.log(coupons);
  // console.log(coupons, "as raja");
  // console.log(neaddata);
  // const getnearproperty = async () => {

  const Navigat = useNavigate();
  const { values, handleBlur, handleChange, handleSubmit, errors } = useFormik({
    initialValues: initialValues,
    validationSchema: ortSchemas,
  });
  const Maindata = {
    property_id: budy.id.property_data.id,
    start_date: moment(selectedDates === undefined ? "" : selectedDates[0])
      ?.format("YYYY-MM-DD")
      ?.toString(),
    end_date: moment(selectedDates === undefined ? "" : selectedDates[1])
      ?.format("YYYY-MM-DD")
      ?.toString(),
    coupon: coupons === undefined ? "" : CouponValue,
    cleaning_fees: add?.data?.cleaning_fees,
    service_fees: add?.data?.service_fees,
    total_charge: add?.data?.total_amount,
    booking_fees: add?.data?.booking_fees,

    final_subtotal: add?.data?.final_subtotal,
    description: values.Jerome,
    // discount: AfterApplaytoken == null ? 'null' : AfterApplaytoken.flat_discount,
    guests: `${adult + " "}adult, ${children + " "}child`,
  };
  // console.log(Maindata);
  const BookingRequestApis = () => {
    const senddate = startDate.map((item) => {
      return item;
    });
    // console.log(budy.id.beBhai, "senddate mile");ssss

    const formdata = new FormData();

    formdata.append(
      "start_date",
      moment(selectedDates[0]).format("YYYY-MM-DD").toString()
    );
    formdata.append(
      "end_date",
      moment(selectedDates[1]).format("YYYY-MM-DD").toString()
    );
    formdata.append("guests", `${count + " "}adult, ${pluss + " "}child`);

    formdata.append("cleaning_fees", add.data.cleaning_fees);
    formdata.append("discount", add.data.cleaning_fees);
    formdata.append("service_fees", add.data.service_fees);
    formdata.append("total_charge", add.data.total_amount);
    formdata.append("description", "lkjij");
    formdata.append("property_id", budy.id.beBhai);
    formdata.append("coupon", coupons);

    axios({
      method: "post",
      url: "/bookings",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token.data.token.token,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data, "hghjgaasuikjhsudh");
        setneardata(data);
        if (data.stutus === 0) {
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        } else if (data.stutus === 1) {
          // setShow(true);
          // Navigat("/Payment");
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
        if (data.status === 1) {
          localStorage.setItem("Stripe", JSON.stringify(data));
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err, {
          theme: "colored",
          position: "top-right",
        });
      });
  };
  // console.log(token.token);

  const Coupon = async () => {
    const formdata = new FormData();
    try {
      let poly = {
        url: "/coupons",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `${token?.data?.token?.token}`,
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          setCoupons(respons.coupon_list);
          // console.log(respons);
        });
    } catch (error) {
      // console.log("userap...", error);
      toast.error(error.response.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    Coupon();
  }, []);

  const CheckCupon = () => {
    setloading(true);
    // console.log(
    //   "🚀 ~ file: BookingRequest.js:270 ~ CheckCupon ~ add.data.total_amount:",
    //   add.data.total_amount
    // );
    axios({
      method: "post",
      url: "/check_coupon_availability",
      data: {
        coupon: CouponValue,
        total_amount: add?.data?.total_amount,
      },
      headers: {
        "Content-Type": "application/json",
        authorization: `${token?.data?.token?.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log("🚀  CheckCupon  data:", data);

        if (data.status === 0) {
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        } else if (data.status === 1) {
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
        setloading(false);
        if (data.message === "coupon applied successfully") {
          Booking();
        }
      })
      .catch((err) => {
        setloading(false);
        toast.error(err, {
          position: "top-right",
          theme: "colored",
        });
        // console.log(err.response);
      });
  };

  const [disabledDates, setDisabledDates] = useState([]);
  const isDateDisabled = (date) => {
    const dateValue = date.toISOString().split("T")[0];
    return disabledDates.includes(dateValue) || date < new Date();
  };
  // const isDateDisabled = (date) => {
  //   // Assuming `disabledDates` is an array of date strings in YYYY-MM-DD format
  //   return disabledDates.includes(date.toISOString().split("T")[0]);
  // };
  // console.log(disabledDates, "disabledDates");
  const getUnavliabledate1 = async () => {
    axios({
      method: "post",
      url: "/unavailable_dates",
      data: {
        property_id: budy?.id?.property_data?.id,
      },

      headers: {
        authorization: `${token?.data?.token?.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        const disabledDatesArray = data.data.map(
          (dateString) => new Date(dateString)
        );
        setDisabledDates(disabledDatesArray);
        // setDisabledDates(data.data);
        // console.log(disabledDates, "Date mil jaaa");
      })

      .catch((er) => {
        // console.log(er);
      });
  };
  useEffect(() => {
    getUnavliabledate1();
  }, []);

  const [checkcuop, setCheckcuop] = useState();

  const check = async () => {
    setloading(true);

    // Check if selectedDates is defined and is an array
    if (Array.isArray(selectedDates) && selectedDates.length >= 2) {
      const senddate = startDate.map((item) => {
        return item;
      });

      axios({
        method: "post",
        url: "/check_availibility",
        data: {
          property_id: budy.id.property_data.id,
          start_date: moment(selectedDates[0])?.format("YYYY-MM-DD"),
          end_date: moment(selectedDates[1])?.format("YYYY-MM-DD"),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: token?.data?.token?.token,
        },
      })
        .then((res) => res.data)
        .then((data) => {
          setloading(false);
          // console.log(data, "dataaaaaa");
          setCheckcuop(data);
          if (data.stutus === 0) {
            toast.error(data.message, {
              position: "top-right",
              theme: "colored",
            });
          } else if (data.stutus === 1) {
            toast.success(data.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
        });
    } else {
      // Handle the case when selectedDates is not defined or not an array
      setloading(false);
      // console.error("selectedDates is undefined or not an array")
    }
  };

  const handleButtonClick = () => {
    if (isChecked === false) {
      setErrorMessage("Please accept the terms and conditions.");
    } else if (AdultsMain === 0 && ChildrenMain === 0) {
      // Notify the user to select at least one adult or child
      toast.error("Please select one adult or child.", {
        position: "top-right",
        theme: "colored",
      });
    } else if (moment(selectedDates[0]).isSame(selectedDates[1])) {
      // Alert that start date and end date cannot be identical
      alert("You can't select the same date as start date and end date.");
    } else {
      BookingChack();
    }
  };
  const BookingChack = async () => {
    setloading(true);
    const senddate = startDate.map((item) => {
      return item;
    });
    axios({
      method: "post",
      url: "/check_availibility",
      data: {
        property_id: budy?.id?.property_data?.id,
        start_date: moment(selectedDates[0])?.format("YYYY-MM-DD"),
        end_date: moment(selectedDates[1])?.format("YYYY-MM-DD"),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token?.data?.token?.token,
      },
    })
      // .then((res) => res.data)
      .then((res) => {
        // console.log(res.data);
        return res.data;
      })
      .then((data) => {
        setloading(false);
        setCheckcuop(data);
        if (data.stutus === 0) {
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        } else if (data.stutus === 1) {
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
        setloading(false);
        if (data.stutus === 1) {
          Navigat("/AddPaymentmethods", {
            state: Maindata,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.message, {
          position: "top-right",
          theme: "colored",
        });
        setloading(false);
      });
  };

  // const [showMore, setShowMore] = useState([]);
  const [showby, setShowby] = useState(false);

  const [sadat, setSadat] = useState(false);
  const [valueget, setValueget] = useState([]);

  // console.log(CouponValue, "CouponValue");
  const handelval = (item) => {
    setValueget(() => [item.coupon, item.terms]);
    setSadat(false);
    // budy.id.property_data.id;
    setCouponValue(item.coupon);
    // console.log(item, "item matlab ");
  };
  const handleClick = () => {
    setSadat(!sadat);
  };
  const [count, setCount] = useState(1);
  // console.log(count, "tggbfh");

  const [pluss, setPluss] = useState(0);
  // console.log(AdultsMain);
  const [show, showwww] = useState(false);

  const addCountHandler = () => {
    if (AdultsMain >= adult) {
      setShowby(true);
    } else {
      setAdultsMain(AdultsMain + 1);
    }
  };
  const removeCountHandler = () => {
    if (AdultsMain === 1) {
      setAdultsMain(1);
    } else {
      setAdultsMain(AdultsMain - 1);
    }
  };

  const plusCountHandler = () => {
    if (ChildrenMain >= children) {
      setShowby(true);
    } else {
      setChildrenMain(ChildrenMain + 1);
    }
  };
  const minusCountHandler = () => {
    if (ChildrenMain === 0) {
      setChildrenMain(0);
    } else {
      setChildrenMain(ChildrenMain - 1);
    }
  };

  const navigate = useNavigate();

  const handlelogout = () => {
    localStorage.removeItem("login");
    navigate("/");
    setShowby(false);
  };
  const no = () => {
    navigate("/BookingRequest");
    setShowby(false);
  };

  useEffect(() => {
    check();
  }, [selectedDates]);

  const Popup = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Property-card-divv-ppx">
            <div className="Property-ppx-cvv mt-2">
              <span className="spandivv-ppx-property">
                Do you want to Log Out?
              </span>
            </div>

            <div className="Button-divv-property">
              <div className="btnn-divv-pro-class">
                <button
                  onClick={handlelogout}
                  className="btn-property-stripe-main"
                >
                  Log Out
                </button>
              </div>
              <div className="btnn-divv-pro-later">
                <button onClick={no} className="btn-pro-later-main">
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return (
    <>
      <LoginNavbar />
      <div>
        <span className="backbtn" onClick={() => navigate(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <Popup showby={showby} onHide={() => setShowby(false)} />
      <section className="BookingRequest-main-section">
        <div className="BookingRequest-main-div">
          <div className="muja-phchano-ma-hu-kon">
            <img
              className="trip-img-div"
              src={budy.id.images[0]?.picture_name}
              alt=""
            />
            <div className="spaan-bhai-ap-ho">
              <span className="span-dada-khud">
                {budy.id?.property_data?.address}
              </span>
              <span className="Accessible-span-dusra">
                {budy.id?.property_data?.amenities[0]?.amenity} and more
              </span>
              <div className="mt-2">
                <span className="trip-enjoy">
                  €{budy.id?.property_data?.price_per_night}
                </span>
                <span>/ night</span>
              </div>
            </div>
            <div className="text-ke-dad">
              <div className="yor-dets">
                <span className="trip-enjoy">Your Trip</span>
                <div className="svg-or-span-ap">
                  <div className="svg-or-contain mt-2">
                    <span className="dates-karage">Dates</span>
                  </div>
                  <div>
                    <DateRangePicker
                      className="custom-date-picker"
                      showOneCalendar
                      onChange={handleDateChange}
                      value={selectedDates}
                    />
                  </div>
                  {promptVisible && (
                    <Modal
                      show={promptVisible}
                      onHide={() => setPromptVisible(false)}
                    >
                      <div className="Invalid-section">
                        <span className="Invalid-date-selection">
                          Invalid Selection
                        </span>
                      </div>

                      <div className="You-section">
                        <span className="You-date-selection">
                          You cannot select past or booked dates.
                        </span>
                      </div>
                      <div className="Diaabled-dates-cls-btnn">
                        <button
                          className="Diabled-btnn-close"
                          onClick={() => setPromptVisible(false)}
                        >
                          Close
                        </button>
                      </div>
                    </Modal>
                  )}
                </div>

                <div className="svg-or-span-ap mt-3">
                  <div className="svg-or-contain mt-2">
                    <span className="dates-karage">Guest</span>
                    <div className="Guest-api-count">
                      {/* <span> {pluss}</span> */}
                      <span> {adult}</span>

                      <span> Adults</span>
                    </div>

                    <div className="Guest-api-count">
                      {/* <span>{count}</span> */}
                      <span>{children}</span>
                      <span>Children</span>
                    </div>
                  </div>

                  <div className="plucha-and-maines">
                    <div>
                      <div className="mt-3">
                        <span className="Adults-span">Adults</span>
                      </div>

                      <div className="caunt-div mt-3">
                        <button
                          className="Mainas-btn"
                          onClick={removeCountHandler}
                        >
                          <svg
                            width="12"
                            height="2"
                            viewBox="0 0 12 2"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M12 0V2H0V0H12Z" fill="#211B24" />
                          </svg>
                        </button>
                        {AdultsMain}
                        <button
                          className="Mainas-btn"
                          onClick={addCountHandler}
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z"
                              fill="#211B24"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="mt-3">
                        <span className="Adults-span">Children</span>
                      </div>
                      <div className="caunt-div mt-3">
                        <button
                          className="Mainas-btn"
                          onClick={minusCountHandler}
                        >
                          <svg
                            width="12"
                            height="2"
                            viewBox="0 0 12 2"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M12 0V2H0V0H12Z" fill="#211B24" />
                          </svg>
                        </button>
                        {ChildrenMain}
                        <button
                          className="Mainas-btn"
                          onClick={plusCountHandler}
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z"
                              fill="#211B24"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="aajao-line-end-me mt-4">
                <div className="koi-bhi-ho"></div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="Pay-with-wihot mt-4">
                  <div>
                    <span className="trip-enjoy">Pay with</span>
                    <div className="imagis-sab-aajao">
                      <div className="img-ko-sahi-kare mt-3">
                        <img className="visa-ko-chota-kare" src={Visa} alt="" />
                      </div>
                      <div className="img-ko-sahi-kare mt-3">
                        <img className="visa-ko-chota-kare" src={logo} alt="" />
                      </div>
                      <div className="img-ko-sahi-kare mt-3">
                        <img className="visa-ko-chota-kare" src={pay} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="span-selact-rtag mt-4">
                    <span>Coupon</span>
                    <div className="city-select-section">
                      <svg
                        onClick={handleClick}
                        className="city-select-svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_24_2280)">
                          <path
                            d="M2 9.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V9.5C21.337 9.5 20.7011 9.76339 20.2322 10.2322C19.7634 10.7011 19.5 11.337 19.5 12C19.5 12.663 19.7634 13.2989 20.2322 13.7678C20.7011 14.2366 21.337 14.5 22 14.5V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V14.5C2.66304 14.5 3.29893 14.2366 3.76777 13.7678C4.23661 13.2989 4.5 12.663 4.5 12C4.5 11.337 4.23661 10.7011 3.76777 10.2322C3.29893 9.76339 2.66304 9.5 2 9.5ZM4 7.968C4.75121 8.3403 5.38347 8.91505 5.8255 9.62746C6.26754 10.3399 6.50176 11.1616 6.50176 12C6.50176 12.8384 6.26754 13.6601 5.8255 14.3725C5.38347 15.085 4.75121 15.6597 4 16.032V19H20V16.032C19.2488 15.6597 18.6165 15.085 18.1745 14.3725C17.7325 13.6601 17.4982 12.8384 17.4982 12C17.4982 11.1616 17.7325 10.3399 18.1745 9.62746C18.6165 8.91505 19.2488 8.3403 20 7.968V5H4V7.968ZM9 9H15V11H9V9ZM9 13H15V15H9V13Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_24_2280">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <input
                        className="Coupon-Selact-input-ho"
                        placeholder=" Selact Coupon"
                        onClick={handleClick}
                        value={CouponValue}
                      ></input>
                      <span onClick={CheckCupon} className="applye-kiya">
                        {" "}
                        Apply
                      </span>
                    </div>
                  </div>
                </div>
                {coupons.map((item, index) => {
                  return (
                    <>
                      <div className="coupon-terms-div">
                        {sadat ? (
                          <div
                            className="seconad-div-main-ke-bad"
                            onClick={() => handelval(item)}
                          >
                            <span>{item.coupon}</span>
                            <span>{item.terms}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  );
                })}
              </form>
              <div className="aajao-line-end-me mt-4">
                <div className="koi-bhi-ho"></div>
              </div>
              <div className="Pay-with-do mt-4">
                <span className="trip-enjoy">Price Detail</span>
                <div className="aju-baju-niklo mt-3">
                  <span className="dolar-ma-pya-karo">
                    € {add?.data?.price_per_night} x {add?.data?.booking_days}{" "}
                    night
                  </span>
                  <span className="rupiya-pya ">
                    {" "}
                    € {add?.data?.total_amount}
                  </span>
                </div>

                <div className="aju-baju-niklo mt-1">
                  <span className="dolar-ma-pya-karo">Cleaning Fee</span>
                  <span className="rupiya-pya ">
                    € {add?.data?.cleaning_fees}
                  </span>
                </div>

                <div className="aju-baju-niklo mt-1">
                  <span className="dolar-ma-pya-karo">Booking Fees</span>
                  <span className="rupiya-pya ">
                    €{add?.data?.booking_fees}{" "}
                  </span>
                </div>

                <div className="aju-baju-niklo mt-3">
                  <span className="trip-enjoy">Total Amount</span>
                  <span className="trip-enjoy">
                    {" "}
                    € {add?.data?.final_subtotal}
                  </span>
                </div>
              </div>
              <div className="aajao-line-end-me mt-4">
                <div className="koi-bhi-ho"></div>
              </div>
              <div className="Pay-with-do mt-4">
                <span className="trip-enjoy">Message to the host</span>
                <div className="khtam-hona-ko-he mt-2">
                  <span className="let-ho-gya">
                    Let the host know why you’re travelling and when you’ll
                    check in.
                  </span>
                </div>
                <div className="img-ko-satekaru mt-3">
                  <img
                    className="host-profile-main-user"
                    src={budy.id.property_data.host_profile}
                    alt=""
                  />
                  <span className="Jerome-span">
                    {budy.id.property_data.host_name}
                  </span>
                </div>
                <div className="span-ke-mooj mt-3">
                  <textarea
                    id="w3review"
                    name="Jerome"
                    rows="4"
                    cols="100"
                    className="text-to-lekho"
                    placeholder="Write Here"
                    value={values.Jerome}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="cake-karo">
                  <div>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                  </div>
                  <div className="font-alag-he">
                    <div className="hoga-jo-hona-he">
                      <span className="by-book-span">
                        By clicking book now you agreed to the
                      </span>
                    </div>
                    <div>
                      <a
                        className="terms-span-terms"
                        href="https://app.termly.io/document/terms-and-conditions/b39a8290-14e1-47ea-b654-89b54d7d8a69"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms and Conditions
                      </a>

                      <span> and</span>

                      <a
                        className="terms-span"
                        href="https://app.termly.io/document/privacy-policy/ad36185b-ad0f-4e46-8913-0c148a682321"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy.
                      </a>
                    </div>
                  </div>
                </div>

                {isChecked === false ? (
                  <span className="accept-conditions-span">
                    {" "}
                    {errorMessage}
                  </span>
                ) : (
                  ""
                )}

                <div className="Request-btnn mt-5">
                  <button
                    type="submit"
                    className="Cancel-Reques-btn"
                    onClick={() => handleButtonClick()}
                  >
                    Request to Book
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};
export default BookingRequest;
