import { createContext, useEffect, useState } from "react";

// for the refresh data
const usePersistentState = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : defaultValue;
  const [value, setValue] = useState(initial);

  useEffect(() => {
    if (value !== undefined) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};
export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [idget, setIdget] = useState(null);
  const [Okshow, setOkShow] = useState(false);
  // const [sshow, setSshow] = useState(false);
  const [show, setShow] = useState(false);

  const [filterdata, setFilterdata] = usePersistentState("filterdata", []);
  return (
    <GlobalContext.Provider
      value={{
        idget,
        setIdget,
        Okshow,
        setOkShow,
        filterdata,
        setFilterdata,
        show,
        setShow,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
