import React, { useContext, useEffect, useState } from "react";
import "../Components/PersonalInformation.css";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import pic from "../Assets/Images/Editpicuser.png";
import { GlobalContext } from "../GlobalContext";

// import Popup from "./Popup";

const PersonalInformation = () => {
  const { setOkShow } = useContext(GlobalContext);

  const Navigat = useNavigate();
  const [userdeta, setuserdeta] = useState([]);

  // console.log(userdeta, "ustad");
  const [loading, setloading] = useState(false);
  const url =
    "https://admin.athomevacationrentals.online/home/base/static/profile_pic/";
  const token = JSON.parse(localStorage.getItem("login"));
  // console.log(token);
  const Getuser = async () => {
    try {
      setloading(true);
      const res = await axios.get("/getuser", {
        headers: { authorization: `${token.data.token.token}` },
      });
      if (res.data.status === 1) {
        // console.log("Get-User....", res);
        setloading(false);
        setuserdeta(res.data.data);
      } else if (res.data.status === 0) {
        // console.log("Get-User....", res);
        setloading(false);
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        navigate("/");
        localStorage.removeItem("login");
      }
      {
        setloading(false);
        // console.log("Bhai error aa rahi hai jam k", res);
      }
    } catch (error) {
      // console.log("error hai bhai", error);
      setloading(false);
      if (error.message === "Network Error") {
        localStorage.removeItem("login");
        Navigat("/");
        toast.error(error.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    }
  };
  useEffect(() => {
    Getuser();
  }, []);

  const navigate = useNavigate();
  const handlenavigate = async () => {
    if (token?.data?.is_guest === true) {
      setOkShow(true);
    } else {
      Navigat("/EditProfile ", {});
    }
  };
  const handlenavigate1 = async () => {
    if (token?.data?.is_guest === true) {
      setOkShow(true);
    } else {
      Navigat("/AddProperty ", {});
    }
  };
  return (
    <>
      <section className="PersonalInformation-sect">
        <div className="PersonalInformation-div">
          <div className="">
            <div className="">
              <span className="Personal-span">Personal Information</span>
            </div>

            <div className="mt-3">
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <img className="boy-img-1" src={userdeta?.profile_pic} alt="" />
              )}
            </div>
            <div className="mt-3">
              <span className="Richard-span">{userdeta?.fullname}</span>
            </div>
            <div className="edit-add-btn-svg-div">
              <button
                // onClick={() => navigate("/AddProperty")}
                onClick={handlenavigate1}
                className="svg-btn-icon"
              >
                {" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 9V5H11V9H15V11H11V15H9V11H5V9H9ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18Z"
                    fill="white"
                  />
                </svg>
                Add Property
              </button>
              <button
                // onClick={() => navigate("/EditProfile")}
                onClick={handlenavigate}
                className="edit-icon-svg"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_67_977)">
                    <path
                      d="M6.414 16L16.556 5.85799L15.142 4.44399L5 14.586V16H6.414ZM7.243 18H3V13.757L14.435 2.32199C14.6225 2.13452 14.8768 2.02921 15.142 2.02921C15.4072 2.02921 15.6615 2.13452 15.849 2.32199L18.678 5.15099C18.8655 5.33852 18.9708 5.59283 18.9708 5.85799C18.9708 6.12316 18.8655 6.37746 18.678 6.56499L7.243 18ZM3 20H21V22H3V20Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_67_977">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Edit Profile
              </button>
            </div>
          </div>
          <div>
            <div>
              <span className="mo-bile-span">Mobile</span>
            </div>
            <div className="code-mo-div">
              {/* <span className="cont-span"> {userdeta?.phone_number}</span> */}
              <span className="cont-span">
                {userdeta.country_code} {userdeta.phone_number}
              </span>
            </div>
          </div>
          <div>
            <div>
              <span className="mo-bile-span">Email</span>
            </div>
            <div>
              <span className="cont-span">{userdeta?.email}</span>
            </div>
          </div>
        </div>
      </section>

      <Outlet />
    </>
  );
};

export default PersonalInformation;
