import React, {useState, useEffect} from "react";
import "../Components/BookingDetails.css";
import Trip from "../Assets/Images/yourtrip.png";
import Visa from "../Assets/Images/VisaPay.png";
import Modal from "react-bootstrap/Modal";
import Footer from "./Footer";
import "react-datepicker/dist/react-datepicker.css";
import LoginNavbar from "../Components/LoginNavbar";
import axios from "../Schemas/Api";
import {useLocation, useNavigate} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import {toast} from "react-toastify";
import {Data} from "@react-google-maps/api";

const BookingDetails = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  const [loding, setloading] = useState(false);
  const [detail, setDetail] = useState([]);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  let route = useLocation();

  const [startDate, setStartDate] = useState(new Date());
  // console.log(startDate);

  const maindataa = route.state?.item;
  // console.log(maindataa);
  // console.log(maindataa.booking_id);
  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("booking_id", maindataa?.booking_id);
    // console.log(formdata, "sdghsvdg......");
    try {
      let cofe = {
        url: "/booking_detail",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token.data.token.token}`,
        },
      };

      axios(cofe)
        .then((res) => res.data)
        .then((data) => {
          // console.log(data.data);
          setloading(false);
          setDetail([data.data]);
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };
  useEffect(() => {
    onSubmit();
  }, []);

  const cancel = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("booking_id", maindataa?.booking_id);
    // console.log(formdata, "sdghsvdg......");
    try {
      let cofe = {
        url: "/cancel_booking",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token.data.token.token}`,
        },
      };

      axios(cofe)
        .then((res) => res.data)
        .then((response) => {
          setloading(false);
          // console.log(response);
          if (response.stutus === 0) {
            toast.error(response.message, {
              position: "top-right",
              theme: "colored",
            });
          } else if (response.stutus === 1) {
            toast.success(response.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          setloading(false);
          if (response.stutus === 1) {
            navigate("/MyProfile");
          }
        })
        .catch((error) => {
          setloading(false);
          toast.error(error, {
            position: "top-right",
            theme: "colored",
          });
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };
  const Popup = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Property-card-divv-cancel">
            <span className="Property-divv-cancel">Cancellation Policy</span>
            <div className="Property-ppx-cancel mt-2">
              <span className="spandivv-ppx-property-ook">
                {detail[0]?.cancellation_policy}
              </span>
            </div>

            <div className="Button-divv-property">
              <div className="btnn-divv-pro-popup">
                <button
                  onClick={() => cancel()}
                  className="btn-pro-later-popup"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };
  // console.log(detail);

  return (
    <>
      <LoginNavbar />
      <Popup show={show} onHide={() => setShow(false)} />
      <section className="BookingDetails-maim-section">
        <div className="booking-main-diav">
          {detail.map((currelem, index) => {
            return (
              <div key={index} className="muja-phchano-ma-hu-kon">
                <img
                  className="trip-img-div"
                  src={maindataa.property_images[0].picture_name}
                  alt=""
                />
                <div className="spaan-bhai-ap-ho">
                  <span className="span-dada-khud">{currelem.address}</span>
                  <span className="Accessible-span-dusra">
                    {currelem?.amenities[0]?.amenity}
                  </span>
                  <div className="mt-2">
                    <span className="trip-enjoy">${currelem.price}</span>
                    <span>/ night</span>
                  </div>
                </div>
                <div className="text-ke-dad">
                  <div className="yor-dets">
                    <span className="trip-enjoy">Your Trip</span>
                    <div className="mt-2 Date-pikey-q">
                      <div className="Date-piker-js">
                        <span className="dates-karage">Dates</span>
                        <span className="pacha-char">
                          {currelem.start_date} - {currelem.end_date}
                        </span>
                      </div>
                      <div className="Date-piker-js">
                        <span className="dates-karage">Guest</span>
                        <span className="pacha-char">{currelem.guest}</span>
                      </div>
                      <label htmlFor="icon" className="peaky-blainder"></label>
                    </div>
                  </div>
                  <div className="aajao-line-end-me mt-4">
                    <div className="koi-bhi-ho"></div>
                  </div>
                  <div className="Pay-with-do mt-4">
                    <span className="trip-enjoy">Pay with</span>
                    <div className="img-ko-sahi-kare mt-3">
                      <img className="visa-ko-chota-kare" src={Visa} alt="" />
                    </div>
                  </div>
                  <div className="aajao-line-end-me mt-4">
                    <div className="koi-bhi-ho"></div>
                  </div>
                  <div className="Pay-with-do mt-4">
                    <span className="trip-enjoy">Price Detail</span>
                    <div className="aju-baju-niklo mt-3">
                      <span className="dolar-ma-pya-karo">
                        {currelem.price} x {currelem.days} night
                      </span>
                      <span className="rupiya-pya ">
                        $ {currelem.price_into_days}
                      </span>
                    </div>
                    <div className="aju-baju-niklo mt-1">
                      <span className="dolar-ma-pya-karo">Discount</span>
                      <span className="rupiya-pya ">$ {currelem.discount}</span>
                    </div>
                    <div className="aju-baju-niklo mt-1">
                      <span className="dolar-ma-pya-karo">Cleaning Fee</span>
                      <span className="rupiya-pya ">
                        $ {currelem.cleaning_fees}
                      </span>
                    </div>
                    <div className="aju-baju-niklo mt-1">
                      <span className="dolar-ma-pya-karo">Booking Fee</span>
                      <span className="rupiya-pya">
                        $ {currelem.booking_fees}
                      </span>
                    </div>
                    <div className="aju-baju-niklo mt-3">
                      <span className="trip-enjoy">Total</span>
                      <span className="trip-enjoy">
                        $ {currelem.total_price}
                      </span>
                    </div>
                  </div>
                  <div className="aajao-line-end-me mt-4">
                    <div className="koi-bhi-ho"></div>
                  </div>
                  <div className="Pay-with-do mt-4">
                    <span className="trip-enjoy">Message to the Traveller</span>
                    <div className="khtam-hona-ko-he mt-2">
                      <span>
                        Let the host know why you’re travelling and when you’ll
                        check in.
                      </span>
                    </div>
                    <div className="img-ko-satekaru mt-3">
                      <img src={maindataa.rating.profile_pic} alt="" />
                      <span className="Jerome-span">
                        {maindataa.rating.fullname}
                      </span>
                    </div>
                    <div className="span-ke-mooj mt-3">
                      <span className="text-set">
                        {maindataa.rating.review}
                      </span>
                    </div>
                    {currelem.is_completed == true ? null : (
                      <div className="mt-3">
                        <button
                          className="Cancel-Reques-btn"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          Cancel Request
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <Footer />
      {!loding && <div></div>}
      {loding && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default BookingDetails;
