import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import LoginNavbar from "../Components/LoginNavbar";

const Recommendations = () => {
  const nav = localStorage.getItem("login");
  return (
    <>
      {!nav ? <Navbar /> : <LoginNavbar />}

      <div className="main-div-Thereviewproces">
        <div className="main-second-thord-thar">
          <div className="main-sec-Thereviewproces">
            <span className="time-span-text mt-5">
              Host rules, regulations and recommendations
            </span>
            <span className="mb-4  italic-taxt">
              In order to make sure that we are all on the same page, let's go
              through some basic ground rules and recommendations for hosts to
              ensure that we build smooth and lasting relationships.
            </span>
            <div className="charge-div">
              <span className="ek-ka-ek-bug-1">
                <p> 1.</p> We strongly recommend that you keep all communication
                within the platform. This will ensure that there is always a
                trail to refer back to should any issues arise. We cannot assist
                in dispute resolution if we do not have both sides of a story
                and all the facts.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 2.</p> As a host, it is important that you respond to your
                guests' communication as quickly as possible.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 3.</p> @home Vacation Rentals users must be over the age of
                18.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 4.</p> It is strictly against our policy for hosts to accept
                any payments whatsoever outside of the platforms payment method.
                Should a host accept any payment outside of the platforms
                accepted payment methods we reserve the right to block the
                host,the listing(s) as well as the guests' use of our platform.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 5.</p> It is expressly against our policy for hosts to
                accept booking extensions or re-bookings outside of our booking
                system. Should this take place, we reserve the right to block
                the property in question as well as the parties involved and any
                other properties these parties may have listed on our platform.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 6.</p> House rules must be reasonable and clear. House rules
                cannot be changed or edited after a particular booking has been
                accepted. Should the host wish to change or edit the house
                rules, such change will not be applicable to current guests. Any
                future guests with confirmed bookings at the time of the change
                in question must be notified via a platform message and must
                accept the change. Should such guests refuse to accept the
                change in question, the guest may choose to cancel the booking
                without penalties IF the rule in question constitutes a material
                and significant change to the essence of the original booking.
                (example: host removed a double bed from the listing and the
                guests had booked for a honeymoon or children are no longer
                allowed and the guest has booked a family holiday and mentioned
                the children in the booking).
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 7.</p> Cleaning fees are capped at 40€ per stay should you
                choose to charge cleaning fees. This calculation is based on a
                12€ per hour cleaning fee and the size of an average house.
                Leaving some leeway for cleaning products. Cleaning up after
                guests check out is part of the hospitality industry and we
                don't feel that it's ethical to overcharge guests by means of a
                cleaning fee. So in the interest of fairness to all our users we
                have implemented this rule.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 8.</p> We recommend that you take out an insurance policy
                that covers any possible significant damage which may
                occasionally be caused by the guests. Of course we will assist
                in dispute resolution and as far as possible vet our users for
                the safety of all parties but sometimes mishaps do occur and
                it's best to be prepared.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 9.</p> We recommend that as a host, you provide some
                essentials for your guests so as to make their stay that much
                more pleasant as well as to give you the chance of a better
                review. Examples of what we consider basic essentials are:
                bathroom - toilet paper, towels and soap. Kitchen- Dishwashing
                liquid/ tabs, dish sponge,dish cloth/ towel, surface cleaner.
                Bedroom: bedding (sheets and pillowcases).
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 10</p>. Please be clear in your listing regarding your
                access to the property during active bookings. This will avoid
                potential misunderstandings regarding invasion of privacy etc.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 11</p>. It is also our recommendation that you remove from
                your property any personal belongings of high value and /or of
                sentimental value as mishaps sometimes do occur despite the best
                efforts.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 12</p>. As a host, you are required to describe your
                property truthfully and accurately with photographs. Should
                there be significant differences between your listing and what
                is factually true, we reserve the right to suspend or
                permanently block your listing as this constitutes a material
                breach of these regulations.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 13</p>. For an easy check in and seamless stay, we advise
                that you generate a QR code for Wi-Fi access and place it
                somewhere clearly visible to your guests. This should put a
                smile on their faces and count in your favor during review time.
                It is also a good idea to print a page with essential emergency
                numbers (ambulance, police, fire department) and stick somewhere
                visible as guests are often from out of town and this simple
                gesture will go a long way when it matters most.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 14</p>. We recommend that amongst your house rules, you
                require that your guests report and photograph any damages upon
                check in. This will assist both sides in resolving eventual
                disputes that may arise. We also recommend that the host
                photograph any damage caused by the guest immediately upon
                checkout. A dispute arising from damages reported long after
                checkout may be difficult to resolve.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 15</p>. Any damages caused by the guest must be reported on
                the platform with evidence within 24 hours of the checkout of
                the guest in question. We do suggest that the host first
                communicate directly with the guest to resolve the issue. Any
                compensation payments agreed upon must be done via the platform.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 16</p>. It is expressly against our policy to attempt or
                agree to mutually submit false reviews. Such actions are viewed
                as hostile to our users as they intend to defraud and/or mislead
                users and will result in the suspension and/or blocking of the
                parties concerned from the platform.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p> 17</p>. Hosts should endeavor to never cancel confirmed
                bookings as this can cause extreme inconvenience and/or distress
                to guests. The reviews made on the hosts by the guests build the
                hosts review reputation (track record) to a point where the host
                becomes a “Grandhost”. To attain and keep this status, is a
                requirement that a host not cancel more than 1% of confirmed
                bookings.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p>18.</p> The installation of cameras in private areas of the
                listed property, including but not limited to bedrooms and
                bathrooms is expressly prohibited. Should you install cameras in
                or around the property, you must disclose this in your listing.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p>19.</p> Please verify laws, regulations, bylaws and taxes
                applicable in your area as we cannot be held accountable for any
                such obligations.
              </span>
              <span className="ek-ka-ek-bug-1">
                <p>20.</p> Please ensure that your property is equipped with
                either a fire extinguisher or a fire blanket.
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Recommendations;
