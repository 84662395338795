import React, { useRef, useEffect } from "react";
import HomeLogo from "../Assets/Images/Home logo.png";
import "../Components/Navbar.css";
import Hamburger from "hamburger-react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import hlogowhitebg from "../Assets/Images/hlogowhitebg.webp";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  let menuref = useRef();
  // console.log(menuref);
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
    };
    // document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  });

  return (
    <>
      <section className="Nav-bar-main-section">
        <div className="Nav-bar-main-div">
          <div className="div-span-img-main">
            <div className="divvvv">
              <div className="homefooterlogo2" onClick={() => navigate("/")}>
                <img className="Home-logo-img" src={hlogowhitebg} alt="" />
              </div>
            </div>
            <div className="ham-burgur-nav">
              <Hamburger toggle={() => setIsOpen(!isOpen)} toggled={isOpen} />
            </div>
          </div>
          <div ref={menuref} className="div-div-main-div">
            <ul className={isOpen ? "" : "nav-bar-2-span"}>
              <div className="ul-ke-bad-div">
                <NavLink
                  smooth
                  to={"/"}
                  activeClassName="active"
                  className="Home-contect-span-1"
                >
                  Home
                </NavLink>
                <NavLink
                  className="Home-contect-span-1"
                  smooth
                  to={"/Who-are-we"}
                  activeClassName="active"
                >
                  Who Are We
                </NavLink>
                <a
                  className="Home-contect-span-1"
                  href="mailto:info@homevr.online"
                >
                  Contact Us
                </a>
              </div>
              <div className="Buttan-main-div">
                <div>
                  <button
                    onClick={() => navigate("/Login")}
                    className="Login-fust-buttan"
                  >
                    Login
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => navigate("/CreateAnAccount")}
                    className="Become-a-Host-button"
                  >
                    Become a Host
                  </button>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
