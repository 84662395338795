import React, { useState, useEffect } from "react";
import "../Components/Chat.css";
import usar1 from "../Assets/Images/Chatgirl1.png";
import usar2 from "../Assets/Images/Chatgirl2.png";
import usar3 from "../Assets/Images/Chat5.png";
import usar4 from "../Assets/Images/Chat3.png";
import usar5 from "../Assets/Images/Chat5.png";
import usar6 from "../Assets/Images/Chat6.png";
import sand from "../Assets/Images/send12.png";

const Chatdeta = [
  {
    img: usar1,
    Name: "Kristin Watson",
    chat: "Lorem ipsum dolor sit amet",
    Time: "11:23 pm",
  },
  {
    img: usar2,
    Name: "Darrell Steward",
    chat: "Lorem ipsum dolor sit amet",
    Time: "02:30 pm",
  },
  {
    img: usar3,
    Name: "Albert Flores",
    chat: "Lorem ipsum dolor sit amet",
    Time: "02:30 pm",
  },
  {
    img: usar4,
    Name: "Jerome Bell",
    chat: "Lorem ipsum dolor sit amet",
    Time: "02:30 pm",
  },
  {
    img: usar5,
    Name: "Ronald Richards",
    chat: "Lorem ipsum dolor sit amet",
    Time: "02:30 pm",
  },
  {
    img: usar6,
    Name: "Marvin McKinney",
    chat: "Lorem ipsum dolor sit amet",
    Time: "02:30 pm",
  },
];

const Chat = () => {
  const [Showusar, setShowusar] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [name, setname] = useState("");

  useEffect(() => {
    // fetch messages from API or local data source
    const messagesData = [
      { id: 1, text: "Hello!", sender: "user", time: "10:00 AM" },
      { id: 2, text: "Hi there!", sender: "bot", time: "10:01 AM" },
    ];
    setMessages(messagesData);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const newMessage = {
      id: messages.length + 1,
      text: currentMessage,
      sender: "user",
      time: new Date().toLocaleTimeString(),
    };
    setMessages([...messages, newMessage]);
    setCurrentMessage("");
  };
  const handleSelectMessage = (messageId) => {
    setSelectedMessage(messageId);
  };
  return (
    <>
      <section className="chat-main-sedction">
        <div className="chat-main-div">
          <div>
            <span className="chat-span">Chat</span>
          </div>
          <div className="chat-ke-border">
            <div className="serach-chat-girl-div">
              <div className="seracha-svg-cha-vale">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_26_2793)">
                    <path
                      d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875Z"
                      fill="#211B24"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_26_2793">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <input
                  className="Chat-seracha"
                  type="text"
                  placeholder="Search.."
                  name="search"
                />
              </div>
              <div className="senter-div-line"></div>
              <div className="img-Darrell-div">
                <img className="CHat-girl-1" src={name.img} alt="" />
                <span className="Steward-span">{name.Name}</span>
              </div>
            </div>
            <div className="chat-extra"></div>
            <div className="user-flex">
              <div className="user-all-div">
                {Chatdeta.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setname(item);
                      }}
                      key={index}
                      className="img-user-div"
                    >
                      <div className="line-ka-liya-khan">
                        <img className="chat-2-girl" src={item.img} alt="" />

                        <div className="upar-nicha">
                          <span>{item.Name}</span>
                          <span>{item.chat}</span>
                        </div>
                        <span>{item.Time}</span>
                      </div>
                      <div className="line-img-user"></div>
                    </div>
                  );
                })}
              </div>

              <div>
                <div></div>
              </div>
              <div>
                <div>
                  <ul className="user-line">
                    {messages.map((message) => (
                      <li className="user-kaliya-msg" key={message.id}>
                        <div className="img-time-msg">
                          <img
                            className="Chat-ho-rhe-ha"
                            src={name.img}
                            alt=""
                          />
                        </div>
                        <div className="msg-div">
                          <span className="user-msg-1">{message.text}</span>
                        </div>
                        <span>{message.time}</span>
                      </li>
                    ))}
                  </ul>
                  <form className="input-chat-sand" onSubmit={handleSubmit}>
                    <input
                      type="text"
                      className="chat-kar-na-input"
                      value={currentMessage}
                      placeholder="Write here"
                      onChange={(event) =>
                        setCurrentMessage(event.target.value)
                      }
                    />
                    <button className="sand-btn" type="submit">
                      <img type="submit" src={sand} alt="" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chat;
