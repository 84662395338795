import React, { useEffect, useState } from "react";
import "../Components/DownloadApp.css";
import phone1 from "../Assets/Images/phone1.webp";
import phone2 from "../Assets/Images/phone2.webp";
import ppn1 from "../Assets/Images/ppn1.webp";
import ppn2 from "../Assets/Images/ppn2.webp";
import BecomeHost from "../Components/BecomeHost.js";
import { motion } from "framer-motion";
import Gplayimg from "../Assets/Images/Gplayimg.webp";
import Appstrimg from "../Assets/Images/appstrimg.webp";

const DownloadApp = () => {
  const [timeRemaining, setTimeRemaining] = useState(0);

  // useEffect(() => {
  //   // Replace this URL with the URL of your countdown API
  //   const apiUrl = "https://admin.homevr.online/countdown";

  //   const fetchTimeRemaining = async () => {
  //     const response = await fetch(apiUrl);
  //     const data = await response.json();
  //     // console.log(data)
  //     const timeRemaining = data.total_remaining_miliseconds;
  //     setTimeRemaining(timeRemaining);
  //   };

  //   fetchTimeRemaining();
  // }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setTimeRemaining((prevTime) => prevTime - 1000);
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);

  // Format the time remaining as minutes and seconds
  // const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  // const hours = Math.floor(
  //   (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  // );
  // const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  return (
    <>
      <BecomeHost />
      <section className="Download-our-App-section">
        <div className="Download-our-App-main-div">
          <motion.div
            initial={{ x: -25, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="Download-our-App-div"
          >
            {/* <div className="countdown">
              <p>{`${days}d: ${hours}hr: ${minutes}m: ${seconds}s`}</p>
            </div> */}
            <div className="Download-to-span-div">
              <span className="Download-our-App-span">Download </span>
              <span className="Download-our-App-span">our App</span>
            </div>
            <div className="lorem-span ">
              <span>Download for free, register for free.</span>
            </div>
            <div className="img-main-div-ply">
              <div>
                <a
                  href="https://apps.apple.com/us/app/homevr/id6451482969"
                  target="_Home"
                >
                  <img className="App-store-img" src={Appstrimg} alt="" />
                  {/* images changes by me  */}
                </a>
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.Home_App"
                  target="_Home"
                >
                  <img className="Google-play-img" src={Gplayimg} alt="" />
                  {/* images changes by me  */}
                </a>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: 25, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="iphone-img-main-div"
          >
            <img className="i-phone-img-1" src={ppn1} alt="" />
            <div>
              <img className="i-phone-img-2" src={ppn2} alt="" />
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default DownloadApp;
