import React, { useEffect, useState } from "react";
import "../Components/HomePage.css";
import { messaging } from "../Firebasa";
import slider1 from "../Assets/Images/Slider1_img.png";
import SerachaBar from "../Components/SerachaBar";
import Slidess from "../Assets/Images/Slidess.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="Home-page-main-section">
        <div className="Home-page-main-div">
          <motion.div
            initial={{ x: -25, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="Find-span-div-2"
          >
            <span className="the-span">
              {" "}
              Find the perfect home for your holiday accommodation
            </span>
            <div className="mt-3">
              <span className="doolor-span"></span>
            </div>
            <div className="mt-4">
              <button
                className="Know-More-btn-qaz"
                onClick={() => navigate("/Know-more")}
              >
                Know More
              </button>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: 25, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="swipar-bol-te-humko"
          >
            <Swiper
              navigation={true}
              modules={[Navigation, Pagination]}
              className="mySwiper"
              spaceBetween={30}
            >
              <SwiperSlide>
                <img className="wrap-ke-img" src={Slidess} alt="First slide" />
                {/* image changes  */}
              </SwiperSlide>
              <SwiperSlide>
                <img className="wrap-ke-img" src={Slidess} alt="First slide" />
                {/* image changes  */}
              </SwiperSlide>
              <SwiperSlide>
                <img className="wrap-ke-img" src={Slidess} alt="First slide" />
                {/* image changes  */}
              </SwiperSlide>
            </Swiper>
          </motion.div>
        </div>
      </section>
      <SerachaBar />
    </>
  );
};

export default HomePage;
