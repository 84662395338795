import React, { useState } from "react";
import "../Components/SelectDates.css";
import Reqast from "../Assets/Images/Reqast.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
const SelectDates = () => {
  const [count, setCount] = useState(0);

  const [pluss, setPluss] = useState(0);

  const [maines, setMaines] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      <section className="SelectDates-main-sec">
        <div className="SelectDates-main-div">
          <div className="ma-hu-selact-deta">
            <div className="ma-to-esa-he">
              <div className="Ap-kon-ho">
                <img className="trip-img-div" src={Reqast} alt="" />
                <div className="spaan-bhai-ap-ho">
                  <span className="span-dada-khud">Grandview, Texas, US</span>
                  <span className="Accessible-span-dusra">
                    Accessible parking spot and more
                  </span>
                  <div className="mt-2">
                    <span className="trip-enjoy">€202</span>
                    <span>/ night</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="selact-deta-div">
                  <span className="selt-span"> Select Dates</span>
                </div>
                <div className="mb-4">
                  <span>4 Dec - 9 Dec</span>
                </div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  inline
                />
                <div>
                  <button className="Save-Pay-btn">Save & Pay</button>
                </div>
              </div>
            </div>
            <div>
              <div>
                <span className="Add-People-span">Add People</span>
              </div>
              <div className="Main-Adults-div">
                <div>
                  <div className="mt-3">
                    <span className="Adults-span">Adults</span>
                  </div>
                  <div className="caunt-div mt-3">
                    <button
                      className="Mainas-btn"
                      onClick={() => setCount(count - 1)}
                    >
                      <svg
                        width="12"
                        height="2"
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 0V2H0V0H12Z" fill="#211B24" />
                      </svg>
                    </button>
                    {count}
                    <button
                      className="Mainas-btn"
                      onClick={() => setCount(count + 1)}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z"
                          fill="#211B24"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <div className="mt-3">
                    <span className="Adults-span">Children</span>
                  </div>
                  <div className="caunt-div mt-3">
                    <button
                      className="Mainas-btn"
                      onClick={() => setPluss(pluss - 1)}
                    >
                      <svg
                        width="12"
                        height="2"
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 0V2H0V0H12Z" fill="#211B24" />
                      </svg>
                    </button>
                    {pluss}
                    <button
                      className="Mainas-btn"
                      onClick={() => setPluss(pluss + 1)}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z"
                          fill="#211B24"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="man-age-div">
                <div className="mt-5 Child-Age-span">
                  <span className="cildran-t--span">Child 1 Age</span>
                  <input
                    placeholder="Age"
                    className="input-age"
                    type="number"
                  />
                </div>
                <div className="mt-5 Child-Age-span">
                  <span className="cildran-t--span">Child 2 Age</span>
                  <input
                    placeholder="Age"
                    className="input-age"
                    type="number"
                  />
                </div>
              </div>
              <div>
                <div className="mt-5">
                  <span className="Adults-span">Guest</span>
                </div>
                <div className="caunt-div mt-3">
                  <button
                    className="Mainas-btn"
                    onClick={() => setMaines(maines - 1)}
                  >
                    <svg
                      width="12"
                      height="2"
                      viewBox="0 0 12 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 0V2H0V0H12Z" fill="#211B24" />
                    </svg>
                  </button>
                  {maines}
                  <button
                    className="Mainas-btn"
                    onClick={() => setMaines(maines + 1)}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z"
                        fill="#211B24"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectDates;
