import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import "../Components/Otp.css";

const Otp = () => {
  // const [otp, setOtp] = useState("");

  const [OTP, setOTP] = useState("");
  function handleChange(OTP) {
    setOTP(OTP);
  }
  function handlesubmit(e) {
    e.preventDefault();
    // console.log(OTP);
  }

  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        // if (minutes === 0) {
        clearInterval(interval);
        // } else {
        //   setSeconds(59);
        //   setMinutes(minutes - 1);
        // }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    // setMinutes(1);
    setSeconds(30);
  };

  return (
    <div className="mt-5">
      <Form
        // onSubmit={handlesubmit}
        className="d-flex flex-column gap-1 justify-content-center align-items-center"
      >
        <OtpInput
          value={OTP}
          onChange={setOTP}
          numInputs={6}
          renderSeparator={<span></span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={"otpinput-field"}
          containerStyle={{
            gap: "2px",
            justifyContent: "center",
          }}
        />

        {/* <OTPInput
          isInputNum={true}
          onChange={handleChange}
          inputStyle={"otpinput-field"}
          value={OTP}
          containerStyle={{
            gap: "10px",
            justifyContent: "center",
          }}
          numInputs={4}
          separator={<span></span>}
        /> */}
        <div className="otp-timer-div mt-5">
          {/* <img src={img} alt="img" width={"20px"} /> */}
          {seconds > 0 ? (
            <p className="m-0">
              {/* {minutes < 10 ? `0${minutes}` : minutes}: */}
              {<>00</>}.{seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <>00.00</>
          )}
          {/* <img src={img} alt="img" width={"20px"} />
                    00.21 */}
        </div>
      </Form>
    </div>
  );
};

export default Otp;
