import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

import LoginNavbar from "../Components/LoginNavbar";

const BookingFees = () => {
  const nav = localStorage.getItem("login");
  return (
    <>
      {!nav ? <Navbar /> : <LoginNavbar />}

      <div className="main-div-Thereviewproces">
        <div className="main-second-thord-thar">
          <div className="main-sec-Thereviewproces">
            <div className="charge-div">
              <span className="time-span-text">
                What is the booking fee and how much does
                <span className="vection-span-rental ">
                  {" "}
                  @home Vacation Rentals
                </span>{" "}
                charge it's hosts and guests?
              </span>
            </div>
            <span className="ek-ka-ek-ching mt-4">
              {/* In the interest of transparency, we share with our users our
              booking fee structure to ensure that all parties involved have no
              doubts regarding their payments. This also allows our hosts to
              better set their rental price and make plans with their income. */}
              In the interest of transparency, we share with our users our
              booking fee structure to ensure that all parties involved have no
              doubts regarding their payments. This also allows our hosts to
              better set their rental price and make plans with their income.
            </span>
            <span className="time-itle-tex mt-4">
              <i> Long story short :</i>
            </span>
            <span className="ek-ka-ek-ching">
              {/* - Hosts pay a service fee of about 3% of the booking subtotal (in
              case of a cancellation and refund, this fee is not refunded) */}
              - Hosts utilize our platform free of charge.
            </span>
            <span className="ek-ka-ek-ching">
              {/* - Guests pay a booking fee of about 11% of the booking total. */}
              Guests pay a booking fee of about 12%. This we add on to the hosts
              nightly price subtotal to come up with the booking total.
            </span>

            <span className="ek-ka-ek-ching">
              {/* These fees we use to run our operation and ensure that both hosts
              and guests have a seamless experience. */}
              These fees we use to run our operation and ensure that both hosts
              and guests have a seamless experience.
            </span>
            <span className="ek-ka-ek-ching">
              {/* Please be aware that there may be charges related to currency
              exchange or currency fluctuation that are beyond our control. */}
              Please be aware that there may be charges related to currency
              exchange or currency fluctuation that are beyond our control.
            </span>
            <span className="time-itle-tex mt-4">
              <i> Example :</i>
            </span>
            <span className="ek-ka-ek-ching">
              {/* If the nightly fee is €150, the rental period is 3 nights, extra
              guest fee is €60, your booking subtotal is €510. The Host service
              fee of 3% of the booking subtotal (€15.30), is deducted from the
              host earnings, and a booking fee of 11% (€56.10) is charged to the
              guest and included in the total price the guest pays. */}
              If the nightly fee is €150, the rental period is 3 nights, extra
              guest fee is €60, your booking subtotal is €510. The booking fee
              of 12% (€61.20) is charged to the guest and included in the total
              price the guest pays.
            </span>
            {/* <span className="ek-ka-ek-ching mt-4 ">- Host earns €494.70</span> */}
            <span className="ek-ka-ek-ching mt-4 ">- Host earns €510</span>

            {/* <span className="ek-ka-ek-ching ">- Guest pays €566.10</span> */}
            <span className="ek-ka-ek-ching ">- Guest pays €571.20</span>

            <span className="ek-ka-ek-ching mt-4">
              {/* No need to worry about making the calculations yourself. Once a
              reservation is confirmed, you will receive a booking confirmation
              email with a price breakdown. */}
              No need to worry about making the calculations yourself. Once a
              reservation is confirmed, you will receive a booking confirmation
              email with a price breakdown.
            </span>
            <span className="time-span-text mt-4">
              {" "}
              Important terms to know :
            </span>
            <span className="ek-ka-ek-ching ">
              - <i>Booking subtotal:</i> is the nightly price plus any
              additional fees (cleaning fee, extra guest fee, pet fee) does not
              include any taxes.
            </span>
            {/* <span className="ek-ka-ek-ching">
              - <i>Service fee:</i> 3% payable by the host to @home Vacation
              Rentals. This is taken from the booking subtotal.
            </span> */}
            <span className="ek-ka-ek-ching mb-5">
              - <i> Booking fee:</i> 12% payable by the guest to @home Vacation
              Rentals. This is taken from the booking total.
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookingFees;
