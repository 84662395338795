import "./App.css";
// import Navbar from "../src/Components/Navbar";
// import {messaging} from "../src/Components/config";
import GlobalContextProvider, { GlobalContext } from "./GlobalContext";
import { getToken } from "firebase/messaging";
import { useContext, useEffect, useState } from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import Login from "../src/Components/Login";
import CreateAnAccount from "../src/Components/CreateAnAccount";
import ForgotPassword from "../src/Components/ForgotPassword";
import Verify from "./Components/Verify";
import Otp from "../src/Components/Otp";
import AfterLogin from "./Components/AfterLogin";
import MyProfile from "./Components/MyProfile";
import PersonalInformation from "./Components/PersonalInformation";
import EditProfile from "./Components/EditProfile";
import ChangePassword from "./Components/ChangePassword";
import Congratulations from "./Components/Congratulations";
import PaymentFailed from "./Components/PaymentFailed";
import PaymentPayouts from "./Components/PaymentPayouts";
import MyWallet from "./Components/MyWallet";
import RateThisProperty from "./Components/RateThisProperty";
import MyBookings from "./Components/MyBookings";
import AddProperty from "./Components/AddProperty";
import MyProperty from "./Components/MyProperty";
import Timeshare from "./Components/Timeshare";
import BookingDetails from "./Components/BookingDetails";
import BookingRequest from "./Components/BookingRequest";
import PropertyType from "./Components/PropertyType";
import Chat from "./Components/Chat";
import SelectDates from "./Components/SelectDates";
import BecomeHost from "./Components/BecomeHost";
import PropertyDetails from "./Components/PropertyDetails";
import SwiperPropertydetails from "./Components/SwiperPropertydetails";
import LoginNavbar from "./Components/LoginNavbar";
import EditProperty from "./Components/EditProperty";
import Thereviewprocess from "./Components/Thereviewprocess";
import Timesharepoolstarranking from "./Components/Timesharepoolstarranking";
import Whoarewe from "./Components/Whoarewe";
import Timesharerules from "./Components/Timesharerules";
import BookingFees from "./Components/BookingFees";
import Recommendations from "./Components/Recommendations";
import Guestrules from "./Components/Guestrules";
import Improtant from "./Components/Improtant";
import LandingPage from "./Components/LandingPage";
import ScrollTotop from "./ScrollTotop";
import Choose from "./Components/Choose";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Knowmore from "./Components/Knowmore";
// import StripeContainer from "./Components/StripeContainer";
import GovernmentId from "./Components/GovernmentId";
import Wishlist from "./Components/Wishlist";
import ForgetPassword from "./Components/ForgetPassword";
import { messaging } from "./Firebasa";
import Popup from "./Components/Popup";
import { useLayoutEffect } from "react";
import ApplyforTimeshare from "./Components/ApplyforTimeshare";
import { Helmet } from "react-helmet";
import Payment from "./Components/Payment";
import Stripecontainor from "./Components/Stripecontainor";
import GoogleMaps from "./Components/GoogleMaps";
import Continueproperty from "./Components/Continueproperty";
import Aboutpayment from "./Components/Aboutpayment";
import Yourpayouts from "./Components/Yourpayouts";
import Hostpayment from "./Components/Hostpayment";
import TransactionHistory from "./Components/TransactionHistory";
import Search from "./Components/Search";
import SearchProparty from "./Components/SearchProparty";
import Filter from "./Components/Filter";
import Jointimeshare from "./Components/Jointimeshare";
import AddPaymentmethods from "./Components/AddPaymentmethods";
import TwoPropertyDetails from "./Components/TwoPropertyDetails";
import Reporting from "./Components/Reporting";
import Invoicehistory from "./Components/Invoicehistory";
import Requestfortimeshare from "./Components/Requestfortimeshare";
import Refundsent from "./Components/Refundsent";
import Twomybooking from "./Components/Twomybooking";
import TwoBookingDetails from "./Components/TwoBookingDetails";
import Paymentrefund from "./Components/Paymentrefund";
import Paymenthistory from "./Components/Paymenthistory";
import Refundreceived from "./Components/Refundreceived";
import Paymentmethods from "./Components/PaymentMethods";
import Cohost from "./Components/Cohost";
import BankDetails from "./Components/BankDetails";
import MobileModal from "./Components/MoblieModal";
import CohostPopup from "./Components/CohostPopup";
import GuestPopup from "./Components/GuestPopup";
import PauseAddProperty from "./Components/PauseAddProperty";

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(null);
    // console.log(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollPosition(null);
    // console.log(pathname);
    // console.log(window.scrollTo(0, 0));
  }, [pathname]);

  // useEffect(() => {
  //   console.log(pathname);
  // }, [pathname]);

  // console.log(scrollPosition);

  async function reqastPermition() {
    const permition = await Notification.requestPermission();
    if (permition === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BA8KYeh2NTyCpwIF0bH1ltT-1QxmXX5kuXjqUGUfS6wMq6EzIQUP0tkJ0A6bG8AJ5l8qu9wVeaVjUVSZUD1Iu04",
      });
      localStorage.setItem("FCM", token);
    } else if (permition === "denied") {
      // alert("You denier for tha notification");
    }
  }

  useEffect(() => {
    reqastPermition();
  }, []);

  return (
    <>
      <GlobalContextProvider>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>Discover Your Dream Vacation with HomeVR.Online</title>
                  <link
                    rel="canonical"
                    href="https://homevr.online/"
                    description="Explore a world of luxury timeshares and vacation rentals at HomeVR.Online. Your perfect getaway awaits"
                  />
                </Helmet>
                <LandingPage />
              </>
            }
          />
          {/* // <Route path="/Navbar" element={<Navbar />} /> */}
          <Route path="/Login" element={<Login />} />
          <Route path="/CreateAnAccount" element={<CreateAnAccount />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/GuestPopup" element={<GuestPopup />} />

          {/* <Route path="/Addcard" element={<StripeContainer />} /> */}
          <Route path="/Payment" element={<Stripecontainor />} />
          <Route path="/Verify" element={<Verify />} />
          <Route path="/Otp" element={<Otp />} />
          <Route
            path="/Home"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content="Journey into the world of exceptional timeshares and vacation rentals. Your immersive escape begins here."
                  />
                  <title>Embark on Virtual Voyages with HomeVR.Online</title>
                  <link
                    rel="canonical"
                    href="https://homevr.online/"
                    description="Explore a world of luxury timeshares and Home vacation rentals at HomeVR.Online. Your perfect getaway awaits."
                  />
                </Helmet>
                <AfterLogin />
              </>
            }
          />
          {/* <Route path="/MyProfile" element={<MyProfile />} />
        <Route path="/PersonalInformation" element={<PersonalInformation />} /> */}
          <Route path="/EditProfile" element={<EditProfile />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/Congratulations" element={<Congratulations />} />
          <Route path="/PaymentFailed" element={<PaymentFailed />} />
          <Route path="/PaymentPayouts" element={<PaymentPayouts />} />
          <Route path="/MyWallet" element={<MyWallet />} />
          <Route path="/RateThisProperty" element={<RateThisProperty />} />
          <Route path="/MyBookings" element={<MyBookings />} />
          <Route path="/AddProperty" element={<AddProperty />} />
          <Route path="/PauseAddProperty" element={<PauseAddProperty />} />
          <Route path="/EditProperty" element={<EditProperty />} />
          <Route path="/MyProperty" element={<MyProperty />} />
          <Route path="/Timeshare" element={<Timeshare />} />
          <Route path="/BookingDetails" element={<BookingDetails />} />
          <Route path="/BookingRequest" element={<BookingRequest />} />
          <Route path="/PropertyType" element={<PropertyType />} />
          <Route path="/Chat" element={<Chat />} />
          <Route path="/SelectDates" element={<SelectDates />} />
          <Route path="/BecomeHost" element={<BecomeHost />} />
          <Route path="/PropertyDetails" element={<PropertyDetails />} />
          <Route path="/LoginNavbar" element={<LoginNavbar />} />
          <Route path="/CohostPopup" element={<CohostPopup />} />

          {/* <Route path="/MobileModal" element={<MobileModal />} /> */}

          <Route
            path="/The-review-process"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content="Discover how we ensure the highest quality vacation experiences through our comprehensive review process at HomeVR.Online."
                  />
                  <title>Our Thorough Review Process | HomeVR.Online</title>
                  <link
                    rel="canonical"
                    href="https://homevr.online/The-review-process"
                    description="Discover how we ensure the highest quality vacation experiences through our comprehensive review process at HomeVR.Online."
                  />
                </Helmet>
                <Thereviewprocess />{" "}
              </>
            }
          />
          <Route
            path="/Who-are-we"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content="Learn about the passionate team behind HomeVR.Online. We're dedicated to creating unforgettable vacation experiences."
                  />
                  <title>Unveiling Our Story at HomeVR.Online</title>
                  <link
                    rel="canonical"
                    href=" https://homevr.online/Who-are-we"
                    description=" Get acquainted with the heart and soul behind HomeVR.Online. Meet the visionaries crafting unforgettable vacation experiences."
                  />
                </Helmet>
                <Whoarewe />
              </>
            }
          />
          <Route
            path="/Booking-Fees"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content="Learn about our fair and transparent booking fees at HomeVR.Online. No hidden costs—just incredible vacations."
                  />
                  <title>
                    Booking Fees | HomeVR Fair and Transparent Fee Structure{" "}
                  </title>
                  <link
                    rel="canonical"
                    href="https://homevr.online/Booking-Fees"
                    description="Demystify our equitable booking fees at HomeVR.Online. No veiled costs—only captivating vacations to remember."
                  />
                </Helmet>
                <BookingFees />
              </>
            }
          />
          <Route
            path="/Recommendations"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content=" Explore our curated recommendations to make the most of your vacation. Trust HomeVR.Online for unforgettable experiences."
                  />
                  <title>
                    Expert Vacation Recommendations | HomeVR.Online{" "}
                  </title>
                  <link
                    rel="canonical"
                    href="https://homevr.online/Recommendations"
                    description=" Explore our curated recommendations to make the most of your vacation. Trust HomeVR.Online for unforgettable experiences."
                  />
                </Helmet>
                <Recommendations />
              </>
            }
          />
          <Route
            path="/Know-more"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content=" Safely register your property with ease on HomeVR.online – your trusted platform for hassle-free and safe property registrations."
                  />
                  <title>Safely Register your property on HomeVR.online</title>
                  <link
                    rel="canonical"
                    href="https://homevr.online/Know-more"
                    description=" Safely register your property with ease on HomeVR.online – your trusted platform for hassle-free and safe property registrations."
                  />
                </Helmet>
                <Knowmore />
              </>
            }
          />
          {/* <Route path="/Knowmore" element={<Knowmore />} /> */}
          <Route path="/Improtant" element={<Improtant />} />
          <Route path="/Choose" element={<Choose />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/TwoPropertyDetails" element={<TwoPropertyDetails />} />
          <Route path="/GovernmentId" element={<GovernmentId />} />
          <Route path="/Popup" element={<Popup />} />
          <Route path="/ApplyforTimeshare" element={<ApplyforTimeshare />} />
          <Route path="/Payment" element={<Payment />} />
          <Route path="/Search" element={<Search />} />
          <Route path="/SearchProparty" element={<SearchProparty />} />
          <Route path="/Filter" element={<Filter />} />
          <Route path="/Paymenthistory" element={<Paymenthistory />} />
          <Route path="/AddPaymentmethods" element={<AddPaymentmethods />} />
          <Route path="/Refundsent" element={<Refundsent />} />
          <Route path="/Refundreceived" element={<Refundreceived />} />
          <Route path="/Reporting" element={<Reporting />} />
          <Route
            path="/Requestfortimeshare"
            element={<Requestfortimeshare />}
          />
          <Route path="/TwoBookingDetails" element={<TwoBookingDetails />} />
          <Route path="/PaymentMethods" element={<Paymentmethods />} />
          <Route path="/Invoicehistory" element={<Invoicehistory />} />
          <Route path="/Cohost" element={<Cohost />} />
          <Route path="/BankDetails" element={<BankDetails />} />

          <Route
            path="/Timeshare-poolstar-ranking"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content=" Discover the top timeshare resorts with our star ranking system on HomeVR.Online. Plan your ideal vacation today."
                  />
                  <title>
                    Explore Timeshare Pool Star Rankings | HomeVR.Online
                  </title>
                  <link
                    rel="canonical"
                    href="https://homevr.online/Timeshare-poolstar-ranking"
                    description=" Discover the top timeshare resorts with our star ranking system on HomeVR.Online. Plan your ideal vacation today."
                  />
                </Helmet>
                <Timesharepoolstarranking />
              </>
            }
          />
          <Route
            path="/Timeshare-rules"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content="Familiarize yourself with essential timeshare rules and regulations on HomeVR.Online. Enjoy your vacations to the fullest."
                  />
                  <title>Understanding Timeshare Rules | HomeVR.Online</title>
                  <link
                    rel="canonical"
                    href="https://homevr.online/Timeshare-rules"
                    description="Familiarize yourself with essential timeshare rules and regulations on HomeVR.Online. Enjoy your vacations to the fullest."
                  />
                </Helmet>
                <Timesharerules />
              </>
            }
          />
          <Route
            path="/Guest-rules"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content=" Find out what it takes to be a courteous and responsible guest at our timeshare properties. Your comfort is our priority."
                  />
                  <title>Guest Rules & Etiquette - HomeVR.Online</title>
                  <link
                    rel="canonical"
                    href="https://homevr.online/Guest-rules"
                    description=" Find out what it takes to be a courteous and responsible guest at our timeshare properties. Your comfort is our priority."
                  />
                </Helmet>
                <Guestrules />
              </>
            }
          />
          <Route
            path="/SwiperPropertydetails"
            element={<SwiperPropertydetails />}
          />
          {/* profilr */}
          <Route path="/MyProfile/" element={<MyProfile />}>
            <Route
              path="/MyProfile/"
              element={
                <Navigate replace to={"/MyProfile/PersonalInformation"} />
              }
            />
            <Route
              path="/MyProfile/PersonalInformation"
              element={<PersonalInformation />}
            />

            <Route path="/MyProfile/Twomybooking" element={<Twomybooking />} />
            <Route
              path="/MyProfile/Continueproperty"
              element={<Continueproperty />}
            />
            <Route
              path="/MyProfile/PaymentPayouts"
              element={<PaymentPayouts />}
            />
            <Route
              path="/MyProfile/ChangePassword"
              element={<ChangePassword />}
            />
            <Route path="/MyProfile/Aboutpayment" element={<Aboutpayment />} />
            <Route path="/MyProfile/Yourpayouts" element={<Yourpayouts />} />
            <Route path="/MyProfile/Hostpayment" element={<Hostpayment />} />
            <Route
              path="/MyProfile/TransactionHistory"
              element={<TransactionHistory />}
            />
            <Route
              path="/MyProfile/Paymentrefund"
              element={<Paymentrefund />}
            />
            <Route path="/MyProfile/MyProperty" element={<MyProperty />} />
            <Route path="/MyProfile/MyBookings" element={<MyBookings />} />
            <Route path="/MyProfile/Timeshare" element={<Timeshare />} />
            <Route path="/MyProfile/Wishlist" element={<Wishlist />} />
            <Route
              path="/MyProfile/Jointimeshare"
              element={<Jointimeshare />}
            />
          </Route>
        </Routes>
        <ToastContainer />
        <Wrapper />
      </GlobalContextProvider>
    </>
  );
}

export default App;

const Wrapper = () => {
  const { Okshow, setOkShow } = useContext(GlobalContext);
  return <GuestPopup show={Okshow} hide={() => setOkShow(false)} />;
};
