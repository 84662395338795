export const BedroomEssentials = [
  {
    id: 1,
    txt: "Bedsheets",
    backEndName: "bedsheets",
    isSelected: false,
  },
  {
    id: 2,
    txt: "Pillows",
    backEndName: "pillows",
    isSelected: false,
  },
  {
    id: 3,
    txt: "Extra pillows",
    backEndName: "extra_pillows",
    isSelected: false,
  },
  {
    id: 5,
    txt: "Extra blankets",
    backEndName: "extra_blankets",
    isSelected: false,
  },
  {
    id: 6,
    txt: "Hangers",
    isSelected: false,
    backEndName: "hangers",
  },

  {
    id: 7,
    txt: "Blackout curtains",
    isSelected: false,
    backEndName: "blackout_curtains",
  },
  {
    id: 8,
    txt: "Safe",
    isSelected: false,
    backEndName: "safe",
  },
  {
    id: 9,
    txt: "Other (Specify)",
    isSelected: false,
    backEndName: "Other (Specify)",
  },
];
export const TimeShareChekBox = [
  {
    id: 1,
    txt: "Safety on area",
    backEndName: "safety_on_area",
    isSelected: false,
  },
  {
    id: 2,
    txt: "Proxemity to local attraction",
    backEndName: "proxemity_to_local_attraction",
    isSelected: false,
  },
  {
    id: 3,
    txt: "Comfort",
    backEndName: "comfort",
    isSelected: false,
  },
  {
    id: 5,
    txt: "Decor",
    backEndName: "decor",
    isSelected: false,
  },
  {
    id: 6,
    txt: "Wifi",
    isSelected: false,
    backEndName: "wifi",
  },

  {
    id: 7,
    txt: "Parking",
    isSelected: false,
    backEndName: "parking",
  },
  {
    id: 8,
    txt: "Daily cleaning",
    isSelected: false,
    backEndName: "daily_cleaning",
  },
  {
    id: 8,
    txt: "Other (Specify)    ",
    isSelected: false,
    backEndName: "extra_blankets",
  },
];

export const BathoomEssentials = [
  {
    id: 1,
    txt: "Towels",
    isSelected: false,
    backEndName: "towels",
  },
  {
    id: 2,
    txt: "Soap",
    isSelected: false,
    backEndName: "soap",
  },
  {
    id: 3,
    txt: "Shampoo",
    isSelected: false,
    backEndName: "shampoo",
  },
  {
    id: 5,
    txt: "Toilet paper",
    isSelected: false,
    backEndName: "toilet_paper",
  },
  {
    id: 6,
    txt: "Hairdryer",
    isSelected: false,
    backEndName: "hairdryer",
  },
  {
    id: 7,
    txt: "Pool/beach towels  ",
    isSelected: false,
    backEndName: "pool_towels",
  },
  {
    id: 444,
    txt: "Other (Specify)",
    isSelected: false,
    backEndName: "Other (Specify)",
  },
];

export const Airconditioning = [
  {
    id: 100,
    txt: "Air conditioning ",
    isSelected: false,
    backEndName: "air_conditioning",
  },
  {
    id: 2,
    txt: "Portable fans",
    isSelected: false,
    backEndName: "portable_fans",
  },
  {
    id: 3,
    txt: "Heating",
    isSelected: false,
    backEndName: "heating",
  },
  {
    id: 5,
    txt: "Portable heaters ",
    isSelected: false,
    backEndName: "portable_heaters",
  },
  {
    id: 6,
    txt: "Fire place",
    isSelected: false,
    backEndName: "fire_place",
  },
  {
    id: 7,
    txt: "Underfloor heating  ",
    isSelected: false,
    backEndName: "underfloor_heating",
  },

  {
    id: 223,
    txt: "Ceiling fan    ",
    isSelected: false,
    backEndName: "ceiling_fan",
  },
  {
    id: 7869,
    txt: "Other (Specify)    ",
    isSelected: false,
    backEndName: "extra_blankets",
  },
];

export const Kitchen = [
  {
    id: 2,
    txt: "Pots&pans",
    isSelected: false,
    backEndName: "pots_pans",
  },
  {
    id: 3,
    txt: "Salt&pepper    ",
    isSelected: false,
    backEndName: "salt_pepper",
  },
  {
    id: 5,
    txt: "Cleaning Products",
    isSelected: false,
    backEndName: "cleaning_products",
  },

  {
    id: 5,
    txt: "Oil",
    isSelected: false,
    backEndName: "oil",
  },
  {
    id: 6,
    txt: "Dishwashing liquid    ",
    isSelected: false,
    backEndName: "extra_blankets",
  },
  {
    id: 7,
    txt: "Dishwasher tabs    ",
    isSelected: false,
    backEndName: "extra_blankets",
  },
  {
    id: 8,
    txt: "Cutlery&crockery     ",
    isSelected: false,
    backEndName: "cutlery_crockery",
  },
  {
    id: 9,
    txt: "Blender",
    isSelected: false,
    backEndName: "blender",
  },
  {
    id: 200,
    txt: "BBQ",
    isSelected: false,
    backEndName: "bbq",
  },
  {
    id: 10,
    txt: "BBQ utensils",
    isSelected: false,
    backEndName: "bbq_utensils",
  },
  {
    id: 11,
    txt: "Fridge ",
    isSelected: false,
    backEndName: "fridge",
  },
  {
    id: 190,
    txt: "Coffee machine ",
    isSelected: false,
    backEndName: "coffee_machine",
  },
  {
    id: 12,
    txt: "Freezer",
    isSelected: false,
    backEndName: "freezer",
  },
  {
    id: 13,
    txt: "Kettle    ",
    isSelected: false,
    backEndName: "kettle",
  },
  {
    id: 15,
    txt: "Microwave",
    isSelected: false,
    backEndName: "microwave",
  },
  {
    id: 16,
    txt: "Oven    ",
    isSelected: false,
    backEndName: "oven",
  },
  {
    id: 17,
    txt: "Stove ",
    isSelected: false,
    backEndName: "stove",
  },
  {
    id: 18,
    txt: "Mini fridge    ",
    isSelected: false,
    backEndName: "mini_fridge",
  },
  {
    id: 19,
    txt: "Toaster",
    isSelected: false,
    backEndName: "toaster",
  },
  {
    id: 10119,
    txt: "Other(Specify)",
    isSelected: false,
    backEndName: "other_kitchen_essentials",
  },
];

export const Sharespace = [
  {
    id: 35,
    txt: "Share with host",
    isSelected: false,
    backEndName: "share_with_host",
  },
  {
    id: 36,
    txt: "Share with other guests",
    isSelected: false,
    backEndName: "share_with_other_guests",
  },
  {
    id: 37,
    txt: " Share only with your family",
    isSelected: false,
    backEndName: "share_only_with_your_family",
  },
];

export const Kitchenette = [
  {
    id: 1,
    txt: "Dedicated workspace    ",
    isSelected: false,
    backEndName: "deediated_workspace",
  },
  {
    id: 192,
    txt: "Kitchenette   ",
    isSelected: false,
    backEndName: "kitchenette",
  },
  {
    id: 2,
    txt: "Dryer",

    isSelected: false,
    backEndName: "dryer",
  },

  {
    id: 3,
    txt: "Washing machine     ",
    isSelected: false,
    backEndName: "washing_machine",
  },
  {
    id: 5,
    txt: "Dishwasher ",
    isSelected: false,
    backEndName: "dishwasher",
  },
  {
    id: 6,
    txt: "Iron    ",
    isSelected: false,
    backEndName: "iron",
  },
  {
    id: 7,
    txt: "Clothes drying rack ",
    isSelected: false,
    backEndName: "clothes_drying_rack",
  },
  {
    id: 8,
    txt: "Hot tub    ",
    isSelected: false,
    backEndName: "hot_tub",
  },
  {
    id: 9,
    txt: "Swimming pool    ",
    isSelected: false,
    backEndName: "swimming_pool",
  },
  {
    id: 129,
    txt: "Outdoor shower    ",
    isSelected: false,
    backEndName: "outdoor_shower",
  },
  {
    id: 10,
    txt: "Jacuzzi  ",
    isSelected: false,
    backEndName: "jacuzzi",
  },

  {
    id: 11,
    txt: "Sauna  ",
    isSelected: false,
    backEndName: "sauna",
  },
  {
    id: 12,
    txt: "Gymnasium / exercise equipment  ",
    isSelected: false,
    backEndName: "gymnasium",
  },
  {
    id: 13,
    txt: "Childrens play area  ",
    isSelected: false,
    backEndName: "playground",
  },
  {
    id: 132,
    txt: "Mosquito nets",
    isSelected: false,
    backEndName: "mosquito_nets",
  },
  {
    id: 14,
    txt: "TV    ",
    isSelected: false,
    backEndName: "tv",
  },

  {
    id: 16,
    txt: "Wi-Fi     ",
    isSelected: false,
    backEndName: "wifi",
  },
  {
    id: 17,
    txt: "Ethernet connection     ",
    isSelected: false,
    backEndName: "ethernet",
  },
  {
    id: 19,
    txt: "Books and reading materials",
    isSelected: false,
    backEndName: "books",
  },
  {
    id: 20,
    txt: "Bowling alley    ",
    isSelected: false,
    backEndName: "bowling_alley",
  },
  {
    id: 21,
    txt: "Climbing wall    ",
    isSelected: false,
    backEndName: "climbing_wall",
  },
  {
    id: 22,
    txt: "Games console    ",
    isSelected: false,
    backEndName: "games_console",
  },
  {
    id: 230,
    txt: "Laser tag  ",
    isSelected: false,
    backEndName: "laser_tag",
  },
  {
    id: 23,
    txt: "Mini golf    ",
    isSelected: false,
    backEndName: "mini_golf",
  },
  {
    id: 24,
    txt: "Cinema     ",
    isSelected: false,
    backEndName: "cinema",
  },
  {
    id: 25,
    txt: "Piano     ",
    isSelected: false,
    backEndName: "piano",
  },
  {
    id: 26,
    txt: "Sound system     ",
    isSelected: false,
    backEndName: "sound_system",
  },
  {
    id: 27,
    txt: "Pool table    ",
    isSelected: false,
    backEndName: "pool_table",
  },
  {
    id: 28,
    txt: "Ping pong table    ",
    isSelected: false,
    backEndName: "ping_pong_table",
  },

  {
    id: 42,
    txt: "Beach access    ",
    isSelected: false,
    backEndName: "beach_access",
  },
  {
    id: 43,
    txt: "Lake access",
    isSelected: false,
    backEndName: "lake_access",
  },
  {
    id: 44,
    txt: "Private entrance",
    isSelected: false,
    backEndName: "private_entrance",
  },
  {
    id: 45,
    txt: "Ski in / Ski out    ",
    isSelected: false,
    backEndName: "ski_in",
  },

  {
    id: 46,
    txt: "Garden    ",
    isSelected: false,
    backEndName: "garden",
  },
  {
    id: 47,
    txt: "Patio",
    isSelected: false,
    backEndName: "patio",
  },
  {
    id: 48,
    txt: "Balcony",
    isSelected: false,
    backEndName: "balcony",
  },
  {
    id: 49,
    txt: "Fire pit",
    isSelected: false,
    backEndName: "fire_pit",
  },
  {
    id: 50,
    txt: "Kayak  ",
    isSelected: false,
    backEndName: "Kayak",
  },
  {
    id: 51,
    txt: "hammock  ",
    isSelected: false,
    backEndName: "hammock",
  },
  {
    id: 52,
    txt: "Outdoor furniture  ",
    isSelected: false,
    backEndName: "outdoor_furniture",
  },
  {
    id: 53,
    txt: "Outdoor dining area  ",
    isSelected: false,
    backEndName: "outdoor_dining_area",
  },
  {
    id: 54,
    txt: "Outdoor kitchen ",
    isSelected: false,
    backEndName: "outdoor_kitchen",
  },
  {
    id: 56,
    txt: "Sun loungers  ",
    isSelected: false,
    backEndName: "sun_loungers",
  },
  {
    id: 57,
    txt: "Free parking    ",
    isSelected: false,
    backEndName: "free_parking",
  },
  {
    id: 58,
    txt: "Paid parking    ",
    isSelected: false,
    backEndName: "paid_parking",
  },
  {
    id: 59,
    txt: "On street paid parking    ",
    isSelected: false,
    backEndName: "on_street_paid_parking",
  },
  {
    id: 60,
    txt: "On street free parking    ",
    isSelected: false,
    backEndName: "on_street_free_parking",
  },
  {
    id: 61,
    txt: "EV charger     ",
    isSelected: false,
    backEndName: "ev_charger",
  },
  {
    id: 62,
    txt: "Lift",
    isSelected: false,
    backEndName: "lift",
  },
  {
    id: 63,
    txt: "Breakfast available    ",
    isSelected: false,
    backEndName: "breakfast_available",
  },

  {
    id: 65,
    txt: "Luggage storage    ",
    isSelected: false,
    backEndName: "luggage_storage",
  },
];
export const Children = [
  {
    id: 33,
    txt: "Baby bath    ",
    isSelected: false,
    backEndName: "baby_bath",
  },
  {
    id: 333,
    txt: "Baby monitor    ",
    isSelected: false,
    backEndName: "baby_monitor",
  },
  {
    id: 34,
    txt: "Safety gates    ",
    isSelected: false,
    backEndName: "safety_gates",
  },
  {
    id: 35,
    txt: "Bicycle   ",
    isSelected: false,
    backEndName: "bicycle",
  },
  {
    id: 36,
    txt: "cot   ",
    isSelected: false,
    backEndName: "cot",
  },
  {
    id: 37,
    txt: "Changing table    ",
    isSelected: false,
    backEndName: "changing_table",
  },

  {
    id: 38,
    txt: "High chair",
    isSelected: false,
    backEndName: "high_chair",
  },
  {
    id: 39,
    txt: "Plug socket covers",
    isSelected: false,
    backEndName: "plug_socket_cover",
  },
  {
    id: 40,
    txt: "Window guards",
    isSelected: false,
    backEndName: "windows_guard",
  },
  {
    id: 41,
    txt: "Table corner guards",
    isSelected: false,
    backEndName: "table_corner",
  },
  {
    id: 42,
    txt: "Arcade games",
    isSelected: false,
    backEndName: "arcade_games",
  },
  {
    id: 439,
    txt: "Board games",
    isSelected: false,
    backEndName: "board_games",
  },
  {
    id: 430,
    txt: "Tennis court",
    isSelected: false,
    backEndName: "tennis_court",
  },
  {
    id: 455,
    txt: "Other (Specify)",
    isSelected: false,
    backEndName: "table_corner",
  },
];

export const StepFree = [
  {
    id: 212,
    txt: "Step free ",
    isSelected: false,
    backEndName: "step_free_proeprty",
  },
  {
    id: 67,
    txt: "Wheelchair accessibility",
    isSelected: false,
    backEndName: "wheel_chair_accessibility",
  },
  {
    id: 68,
    txt: "Stairs to access the property    ",
    isSelected: false,
    backEndName: "stairs",
  },
  {
    id: 69,
    txt: "Pets on property     ",
    isSelected: false,
    backEndName: "pets_on_property",
  },
];

export const CheckinMethod = [
  {
    id: 72,
    txt: "Host/staff will welcome you",
    isSelected: false,
    backEndName: "host_welcome",
  },
  {
    id: 73,
    txt: "Keypad",
    isSelected: false,
    backEndName: "keypad",
  },
  {
    id: 74,
    txt: "Smart lock",
    isSelected: false,
    backEndName: "smart_lock",
  },
  {
    id: 75,
    txt: "Instructions will be given 24 hours before check in",
    isSelected: false,
    backEndName: "instruction_given",
  },
  {
    id: 444,
    txt: "Other (Specify)",
    isSelected: false,
    backEndName: "Other (Specify)",
  },
];

export const Gardenview = [
  {
    id: 1,
    txt: "Garden view    ",
    isSelected: false,
    backEndName: "garden_view",
  },
  {
    id: 2,
    txt: "Desert view    ",
    isSelected: false,
    backEndName: "desert_view",
  },
  {
    id: 3,
    txt: "Beach view    ",
    isSelected: false,
    backEndName: "beach_view",
  },
  {
    id: 5,
    txt: "Courtyard view    ",
    isSelected: false,
    backEndName: "courtyard_view",
  },
  {
    id: 6,
    txt: "Skyline view    ",
    isSelected: false,
    backEndName: "skyline_view",
  },
  {
    id: 7,
    txt: "Resort view    ",
    isSelected: false,
    backEndName: "resort_view",
  },
  {
    id: 8,
    txt: "Golf course view    ",
    isSelected: false,
    backEndName: "golf_course_view",
  },
  {
    id: 9,
    txt: "Lake view    ",
    isSelected: false,
    backEndName: "lake_view",
  },
  {
    id: 10,
    txt: "Pool view    ",
    isSelected: false,
    backEndName: "pool_view",
  },
  {
    id: 11,
    txt: "Mountain  view    ",
    isSelected: false,
    backEndName: "mountain_view",
  },
  {
    id: 12,
    txt: "Park  view    ",
    isSelected: false,
    backEndName: "park_view",
  },
  {
    id: 139,
    txt: "Vineyard   view    ",
    isSelected: false,
    backEndName: "vineyard_view",
  },
  {
    id: 14,
    txt: "Other (Specify)",
    isSelected: false,
    backEndName: "Other (Specify)",
  },
];

export const Security = [
  {
    id: 14,
    txt: " Security cameras ",
    isSelected: false,
    backEndName: "security_camera",
  },
  {
    id: 10505,
    txt: "Fire extinguisher ",
    isSelected: false,
    backEndName: "fire_extinguisher",
  },
  {
    id: 10606,
    txt: "Fire blanket",
    isSelected: false,
    backEndName: "fire_blanket",
  },

  {
    id: 15,
    txt: "Recording devices     ",
    isSelected: false,
    backEndName: "recording_devices",
  },
  {
    id: 16,
    txt: "Carbon monoxide alarm    ",
    isSelected: false,
    backEndName: "carbon_monoxide_alarm",
  },

  {
    id: 10707,
    txt: "Noisy neighborhood",
    isSelected: false,
    backEndName: "noisy_neighbour",
  },

  {
    id: 10707,
    txt: "Weapons on property",
    isSelected: false,
    backEndName: "weapons_on_property",
  },

  {
    id: 17,
    txt: "Smoke alarm     ",
    isSelected: false,
    backEndName: "smoke_alarm",
  },
  {
    id: 18,
    txt: "Window safety bars     ",
    isSelected: false,
    backEndName: "window_safety_bars",
  },
  {
    id: 19,
    txt: "Exterior security doors    ",
    isSelected: false,
    backEndName: "exterior_alarm",
  },
  {
    id: 20,
    txt: "First aid kit    ",
    isSelected: false,
    backEndName: "first_aid_kit",
  },
];

export const Families = [
  {
    id: 103,
    txt: "Families welcome",
    isSelected: false,
    backEndName: "families_welcome",
  },
  {
    id: 1035,
    txt: "Pets welcome",
    isSelected: false,
    backEndName: "pets_welcome",
  },
];

export const HouseRules = [
  {
    id: 5003,
    txt: "Smoking allowed",
    isSelected: false,
    backEndName: "smoking_allowed",
  },
  {
    id: 5004,
    txt: "No smoking",
    isSelected: false,
    backEndName: "no_smoking",
  },
  {
    id: 5005,
    txt: "No pets allowed",
    isSelected: false,
    backEndName: "no_pet_allowed",
  },

  {
    id: 5007,
    txt: "No commercial photography",
    isSelected: false,
    backEndName: "no_commercial_photography",
  },
  {
    id: 5008,
    txt: "No parties or events",
    isSelected: false,
    backEndName: "no_parties_and_events",
  },
  {
    id: 5009,
    txt: "Inform host of any guests or visitors",
    isSelected: false,
    backEndName: "inform_about_guest",
  },
  {
    id: 5010,
    txt: "Use only designated parking area",
    isSelected: false,
    backEndName: "use_only_designated_parking_area",
  },
  {
    id: 5011,
    txt: "No shoes inside the house",
    isSelected: false,
    backEndName: "no_shoes_inside_house",
  },

  {
    id: 5013,
    txt: "No food in bedrooms",
    isSelected: false,
    backEndName: "no_food_in_bedrooms",
  },
  {
    id: 5014,
    txt: "Dispose of garbage",
    isSelected: false,
    backEndName: "dispose_of_garbage",
  },

  {
    id: 5017,
    txt: "Please ensure that you turn off all lights before leaving",
    isSelected: false,
    backEndName: "turn_off_light",
  },
  {
    id: 5018,
    txt: "Please ensure that you turn off the air conditioning before leaving",
    isSelected: false,
    backEndName: "turn_off_air_conditioner",
  },
  {
    id: 5019,
    txt: "Please ensure that you close all windows before you leave the house",
    isSelected: false,
    backEndName: "close_all_window",
  },
  {
    id: 5020,
    txt: "Report any damages with photographs immediately",
    isSelected: false,
    backEndName: "report_damages",
  },
  {
    id: 5021,
    txt: "Please clean the house before check out ",
    isSelected: false,
    backEndName: "clean_house",
  },
  {
    id: 5021,
    txt: "Dispose of garbage instruction ",
    isSelected: false,
    backEndName: "dispose_of_garbage_instruction",
  },

  {
    id: 5023,
    txt: "Other house rules ",
    isSelected: false,
    backEndName: "other_house_rules",
  },
];
