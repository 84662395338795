import React, { useState, useEffect } from "react";
import "../Components/Twomybooking.css";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import kalustar from "../Assets/Images/KaluStar.png";

const Twomybooking = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  const propid = JSON.parse(localStorage.getItem("Propid"));
  const [Booking, setBooking] = useState([]);
  const [loding, setLoding] = useState(false);
  const [Loading, setLoading] = useState(false);

  // console.log(Booking, "bbhb");
  const [review, setReview] = useState([]);

  const key = JSON.parse(localStorage.getItem("rate"));
  const Navigat = useNavigate();

  // console.log(Booking.property_images, "bokinf");
  const Getreview = async () => {
    setLoding(true);
    const formdata = new FormData();
    formdata.append("property_id", propid);
    // console.log(propid, "dsds....");
    try {
      let cofe = {
        url: "https://admin.homevr.online/get_review",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `${token.data.token.token}`,
        },
      };
      axios(cofe)
        .then((res) => res.data)

        .then((respons) => {
          // console.log(respons, "dhdyhydshf");
          setReview(respons.message, "dufhsduhf.....");
          setLoding(false);
        });
    } catch (error) {
      // console.log(error);
      setLoding(false);
    }
  };
  useEffect(() => {
    Getreview();
  }, []);
  const MyBookings = async () => {
    setLoding(true);
    const formdata = new FormData();
    try {
      let cofe = {
        url: "/bookings_on_my_properties?page=1",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons.data);
          setBooking(respons.data);
          setLoding(false);
        });
    } catch (error) {
      // console.log(error);
      setLoding(false);
    }
  };
  useEffect(() => {
    MyBookings();
  }, []);

  const handleset = async (item) => {
    // console.log(item);
    Navigat("/TwoBookingDetails", {
      state: {
        item,
      },
    });
  };
  return (
    <>
      <section className="MyBookings-main-section">
        <div className="My-book-divv-rated">
          <span className="My-prop">Booking My Property</span>
        </div>
        {!Loading && Booking?.length >= 1 ? (
          <div className="Mybooking-main-div">
            {Booking?.map((item, index) => {
              return (
                <>
                  <div key={index} className=" my-Completed-booking">
                    <div className="bokin-m-2" onClick={() => handleset(item)}>
                      <div className="My-booking-imgg-div">
                        <img
                          className="My-boking-div"
                          src={item.property_images[0].picture_name}
                          alt=""
                        />
                      </div>

                      <div>
                        <div>
                          <button
                            className={
                              item.status == "completed"
                                ? "Completed-btn"
                                : "Upcoming-btn"
                            }
                          >
                            {item.status}
                          </button>
                        </div>
                        <div className="mt-2">
                          <span className="Grandview-texas-US-span">
                            {item.address}
                          </span>
                        </div>
                        <div>
                          <span className="Accessible-park-span">
                            {item.amenities[0]?.amenity} and more
                          </span>
                        </div>
                        <div className="night-span-price">
                          <span className="ma-span">€{item.price}</span>
                          <span className="nm-span">/ night</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <>
            <div className="Not-found">No properties found.</div>{" "}
          </>
        )}
      </section>
      {!loding && <div></div>}
      {loding && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Twomybooking;
