import React from "react";
import "../Components/Thereviewprocess.css";
import Footer from "./Footer";
import Navbar from "./Navbar";

import LoginNavbar from "../Components/LoginNavbar";

const Thereviewprocess = () => {
  const nav = localStorage.getItem("login");
  return (
    <>
      {!nav ? <Navbar /> : <LoginNavbar />}

      <div className="main-div-Thereviewproces">
        <div className="main-second-thord-thar">
          <div className="main-sec-Thereviewproces">
            <span className="time-span-text">
              How does the review process work?
            </span>
            <span className="ek-ka-ek-ching mt-4">
              The review process is one of the main organs of @home Vacation
              Rentals, as it provides guests with important information when
              deciding where to stay as well as protecting hosts from possible
              destructive, unpleasant or abusive guests and vice versa. The
              review process also provides a track record for both hosts and
              their guests creating a community and allowing our users to be
              better informed about who is coming into their property and who is
              hosting them. Establishing a track record also allows us to
              distinguish between genuine platform users and those who attempt
              to abuse our platform. The review process is a simple, efficient
              and quick way whereby guests and hosts can rate each other based
              on their experiences during their booked stays. The review process
              which rates both hosts and guests alike attributes to each user a
              star rating from 1(worst) to 5(best) allowing our users to build
              their reputations based on their track record on the platform.
            </span>
            <span>
              With this in mind, we have tried to make the review process fair
              and a true reflection of the experience of both the host and the
              guest. Our review process aims to ensure that both parties feel at
              ease with providing a fair review without the fear of malicious or
              unfair "revenge" reviews.
            </span>

            <span className="mt-4 time-span-text">How have we done this?</span>

            <span className="ek-ka-ek-ching mt-4">
              It's simple. From the listed checkout time, both the host and the
              guest have a window of 72 hours to submit a review. Once received
              by our system (within the prescribed 72 hour period), the
              review(s) will be retained unreleased and unseen by the other
              party, until the end of the 72 hour period. During this time it is
              possible to edit the review(s) but remember that no-one will have
              seen the submitted review(s) at this point. At the 72 hour mark,
              the received review(s) will be simultaneously released without the
              option of any further editing. At the end of the 72 hour period
              whomever has not submitted a review, forfeits the right to review
              that particular booking.
            </span>
            <span className="ek-ka-ek-ching mt-4">
              <i>This ensures that:</i>
            </span>

            <span className="ek-ka-ek-ching  mt-4">
              {" "}
              a) the parties concerned submit their reviews while everything
              about the stay is still fresh in their mind,
            </span>
            <span className="ek-ka-ek-ching ">
              b) both parties submit truthful and fair reviews which will
              benefit all of our users,
            </span>
            <span className="ek-ka-ek-ching ">
              c) users are proactive regarding their reviewing as a good star
              rating is to the benefit of all parties involved and
            </span>
            <span className="organs-span">
              d) both guests and hosts do their best to be pleasant to each
              other as all parties want to achieve and retain a high star
              rating.
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Thereviewprocess;
