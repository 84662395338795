import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Components/SwiperPropertydetails.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Pagination } from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper";
import hutwithman from "../Assets/Images/Hutwithman.png";
import flatwindow from "../Assets/Images/flatwindow.png";
import gardenhouse from "../Assets/Images/gardenhouse.png";
import whitehouse from "../Assets/Images/whitehouse.png";
import poolhouse from "../Assets/Images/Poolsidehouse.png";
import blacklarrow from "../Assets/Images/blacklarrow.png";
import leftblackarrow from "../Assets/Images/leftblackarrow.png";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import { right } from "@popperjs/core";

export default function App() {
  let route = useLocation();
  const maindata = route.state.newdata;

  const [image, setimage] = useState([]);

  const [data, setdata] = useState(image[0]);

  const [current, setcurrent] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const totalSlides = 15;
  const btnref = useRef(null);
  const handleslide = (i) => {
    setcurrent(i);
  };
  useEffect(() => {
    setimage(maindata.id.images);
  }, []);
  useEffect(() => {
    const slider = image[current];
    setdata(slider);
  }, [current]);
  const handleNextSlide = () => {
    const nextSlide = currentSlide + 1;

    if (nextSlide < totalSlides) {
      setCurrentSlide(nextSlide);
    } else {
      setIsDisabled(true);
    }
  };
  const length = image.length;
  const nextslide = () => {
    btnref.current.swiper.slideNext();
    handleNextSlide();
    setcurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevslide = () => {
    btnref.current.swiper.slidePrev();
    setcurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <>
      <div className="leftproductdetail">
        <div className="previmg">
          <img
            src={
              data === undefined ? image[0]?.picture_name : data?.picture_name
            }
            alt="images"
          />
        </div>
        <div className="sliderdiv">
          <button className="left-swiper-btn" onClick={prevslide}>
            <img src={blacklarrow} alt="" />
          </button>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            ref={btnref}
            loop={true}
            navigation={true}
            watchSlidesProgress={true}
            modules={[Navigation, Thumbs]}
          >
            <div className="overflowdiv">
              {image.map((curelem, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img
                      className={current === index ? "slideeeimg" : "sipleimg"}
                      key={index}
                      src={curelem.picture_name}
                      alt=""
                      onClick={() => handleslide(index)}
                    />
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>

          <button className="left-swiper-btn" onClick={nextslide}>
            <img src={leftblackarrow} alt="" />
          </button>
        </div>
      </div>
    </>
  );
}
