import React from "react";
import "../Components/PaymentFailed.css";
import Wrong from "../Assets/Images/Wrong.png";
const PaymentFailed = () => {
  return (
    <>
      <section className="Congratulations-main-section">
        <div className="Congratulations-main-div">
          <div className="thard-con-div">
            <div>
              <img className="con-img-w" src={Wrong} alt="" />
            </div>
            <div className="mt-4">
              <span className="failed-span">Payment Failed!</span>
            </div>
            <div className="successfully-div mt-3">
              <span className="like-span">
                It seems like there is something wrong with your payment.
              </span>
            </div>
            <div className="mt-4">
              <button className="wrong-btn">Use another payment method</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentFailed;
