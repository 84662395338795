import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import LoginNavbar from "../Components/LoginNavbar";
import { FaStarHalfAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
const Timesharepoolstarranking = () => {
  const nav = localStorage.getItem("login");
  return (
    <>
      {!nav ? <Navbar /> : <LoginNavbar />}
      <div className="main-div-Thereviewproces">
        <div className="main-second-thord-thar">
          <div className="main-sec-Thereviewproces">
            <span className="time-span-text">
              {/* Timeshare Star Grading explained */}
              Homeswap Star Grading explained
            </span>
            <span className="ek-ka-ek-ching mt-3">
              {/* How are properties awarded a ranking for the purposes of the
              timeshare pool? Please note that this star grading is not in any
              way related to the star grading for the purposes of the regular
              property rental on the App. Timeshare pool star grading is{" "} */}
              How are properties awarded a ranking for the purposes of the
              Homeswap Club? Please note that this star grading is not in any
              way related to the star grading for the purposes of the regular
              property rental on the App. Homeswap star grading is
              <b className="only-span"> only</b> for the purposes of achieving a
              fair Homeswap plan. The difference between the star grading of the
              Homeswap and the star grading of the rentals is that whereas the
              Homeswap star grading refers to the house itself, the rental star
              grading refers to the entire stay of the renting guest.
            </span>
            <span className="time-span-text mt-3">
              {/* Timeshare star grading criteria */}
              Homeswap star grading criteria
            </span>
            <span className="ek-ka-ek-ching mt-3">
              {/* In order to ensure that the timeshare property exchange is fair
              and not misused, each property is awarded a star grading. This is
              based on attributes of each property such as (but not limited to)
              number of bedrooms, number of bathrooms, cleanliness, availability
              of daily cleaning, comfort, luxury, decor, parking, Wi-Fi,
              proximity of the property to local attractions. */}
              In order to ensure that the Homeswap property exchange is fair and
              not misused, each property is awarded a star grading. This is
              based on attributes of each property such as (but not limited to)
              number of bedrooms, number of bathrooms, cleanliness, availability
              of daily cleaning, comfort, luxury, decor, parking, Wi-Fi,
              proximity of the property to local attractions.
            </span>
            <span className="ek-ka-ek-ching">
              {/* When registering a property in the hosts timeshare program, the
              property owner/host must as part of the registration process
              truthfully indicate which criteria the property meets and based on
              this the property is awarded a star grading within the timeshare
              pool. */}
              When registering a property in the hosts Homeswap program, the
              property owner/host must as part of the registration process
              truthfully indicate which criteria the property meets and based on
              this the property is awarded a star grading within the Homeswap
              club.
            </span>
            <span className="time-span-text mt-3">
              How do we keep it fair and reliable?
            </span>
            <span className="ek-ka-ek-ching mt-3">
              {/* After a timeshare stay, the departing host is encouraged to review
              the timeshare property he/she used so as to keep the timeshare
              pool star grading reliable. In this way, the usage of the
              timeshare pool by hosts is as fair as possible ensuring that the
              house/ property placed in the timeshare pool by a host/owner is as
              similar as possible to the house/property chosen by that same
              host/owner for the free exchange. In order to effectively and
              fairly grade the properties in the hosts timeshare pool, each host
              is required to photograph each room (compartment) of the listed
              property upon registration and update such photographs should any
              changes be made. */}
              After a Homeswap stay, the departing host is encouraged to review
              the Homeswap property he/she used so as to keep the Homeswap club
              star grading reliable. In this way, the usage of the Homeswap by
              hosts is as fair as possible ensuring that the house/ property
              placed in the Homeswap by a host/owner is as similar as possible
              to the house/property chosen by that same host/owner for the free
              exchange. In order to effectively and fairly grade the properties
              in the hosts Homeswap, each host is required to photograph each
              room (compartment) of the listed property upon registration and
              update such photographs should any changes be made.
            </span>
            <span className="ek-ka-ek-ching mt-3">
              {/* Please be aware that should a host/owner be untruthful/dishonest
              in their description of the property for the timeshare pool, we
              reserve the right to remove that property from the timeshare pool
              as well as the platform for a limited time or indefinitely (
              depending on the severity of the transgression). */}
              Please be aware that should a host/owner be untruthful/dishonest
              in their description of the property for the Homeswap, we reserve
              the right to remove that property from the Homeswap club as well
              as the platform for a limited time or indefinitely ( depending on
              the severity of the transgression).
            </span>
            <span className="time-span-text mt-3">
              {/* The star grading for the hosts timeshare pool is awarded as
              follows: */}
              The star grading for the hosts Homeswap is awarded as follows:
            </span>
            <span className="ek-ka-ek-ching mt-3">
              1. Location of the property:
            </span>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="half-star-div">
                <span className="ek-ka-ek-ching ">
                  - safety of the area
                </span>
                <span>
                  <FaStarHalfAlt className="pila-star" />
                </span>
              </div>
            </div>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">
                - Proximity to local attractions
              </span>
              {/* <FaStarHalfAlt className="Fa-span" /> */}
              {/* <span style={{ marginLeft: "0.3rem" }}>half</span>{" "} */}
              <span>
                <FaStarHalfAlt className="pila-star" />
              </span>
            </div>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">2. Comfort</span>
              <span>
                {/* <span className="ek-ka-ek-ching "> */}
                {/* <FaStarHalfAlt className="Fa-span" /> */}
                <FaStarHalfAlt className="pila-star" />
                {/* </span> */}
              </span>
            </div>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">3. Decor</span>
              <span>
                <span className="ek-ka-ek-ching ">
                  {/* <FaStarHalfAlt className="Fa-span" /> */}
                  <FaStarHalfAlt className="pila-star" />
                </span>
              </span>
            </div>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">4. WiFi</span>
              <span>
                <span className="ek-ka-ek-ching ">
                  {" "}
                  {/* <FaStarHalfAlt className="Fa-span" /> */}
                  <FaStarHalfAlt className="pila-star" />
                </span>
              </span>
            </div>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">5. Parking</span>
              <span>
                <span className="ek-ka-ek-ching">
                  {" "}
                  {/* <FaStarHalfAlt className="Fa-span" /> */}
                  <FaStarHalfAlt className="pila-star" />
                </span>
              </span>
            </div>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">6. Daily cleaning</span>
              <span>
                <span className="ek-ka-ek-ching ">
                  <span>
                    <FaStar className="pila-star" />
                  </span>
                </span>
              </span>
            </div>
            <span className="mt-3 ek-ka-ek-ching">7. Number of bedrooms</span>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">- 1 or 2 </span>
              <span>
                <span className="ek-ka-ek-ching ">
                  {/* <FaStarHalfAlt className="Fa-span" /> */}
                  <FaStarHalfAlt className="pila-star" />
                </span>
              </span>
            </div>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">- 3+ </span>
              <span>
                <span className="ek-ka-ek-ching ">
                  <span>
                    <FaStar className="pila-star" />
                  </span>
                </span>
              </span>
            </div>
            <span className="ek-ka-ek-ching ">8. Number of bathrooms</span>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">- 1 or 2 </span>
              <span>
                <span className="ek-ka-ek-ching ">
                  {/* <FaStarHalfAlt className="Fa-span" /> */}
                  <FaStarHalfAlt className="pila-star" />
                </span>
              </span>
            </div>
            <div className="half-star-div">
              <span className="ek-ka-ek-ching ">- 3+ </span>
              <span>
                <span className="ek-ka-ek-ching ">
                  <span>
                    <FaStar className="pila-star" />
                  </span>
                </span>
              </span>
            </div>
            <span className="ek-ka-ek-ching">
              9. Nightly price (please specify the nightly price of your
              property on the rental side of the platform)
            </span>
            <span className="mt-3 ek-ka-ek-ching">
              10. Please specify any other attributes of your property which you
              consider should be awarded a star grading.
            </span>
            <span className="mt-5 time-span-text">
              {/* What cannot be considered for the purposes of the timeshare */}
              What cannot be considered for the purposes of the Homeswap
            </span>
            <span className="mt-4 ek-ka-ek-ching mb-5">
              {/* Please be aware that for the purposes of the hosts timeshare pool
              a sleeper couch in a common area (such as a lounge or TV room)
              does not count as a bedroom. Number of bathrooms refers to indoor
              bathrooms and not service bathrooms such as outdoor swimming pool
              service bathrooms. This is to avoid misunderstandings and to keep
              the property descriptions as accurate as possible. */}
              Please be aware that for the purposes of the hosts Homeswap a
              sleeper couch in a common area (such as a lounge or TV room) does
              not count as a bedroom. Number of bathrooms refers to indoor
              bathrooms and not service bathrooms such as outdoor swimming pool
              service bathrooms. This is to avoid misunderstandings and to keep
              the property descriptions as accurate as possible.
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Timesharepoolstarranking;
