import React, {useState, useEffect} from "react";
import "../Components/Reporting.css";
import aryy from "../Assets/Images/backarry.png";
import {useNavigate} from "react-router-dom";
import axios from "../Schemas/Api";
import {useFormik} from "formik";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import {Backdrop, CircularProgress} from "@mui/material";
import back from "../Assets/Images/Backicon.png";

const Reporting = () => {
  let route = useLocation();
  const budy = route.state;

  const [Pid, setPid] = useState(budy.id.beBhai);
  const token = JSON.parse(localStorage.getItem("login"));
  const navigate = useNavigate();

  const initialValues = {
    policy: "",
    Oth: "",
  };

  const {
    handleBlur,
    handleChange: handle,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: initialValues,
  });

  const [loading, setLoading] = useState(false);

  const [policyid, setpolicyid] = useState("");

  const [Policies, setPolicies] = useState([]);

  const [valuese, setValues] = useState({
    policy: "", // Assuming this is the state to store the selected radio button value
  });
  const [Showadd, setShowadd] = useState(false);

  const Policy = async () => {
    setLoading(true);
    const formdata = new FormData();
    try {
      let poly = {
        url: "/get_report_types",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons);
          setPolicies(respons.data);
          setLoading(false);
          respons.data.push({
            id: 4088,
            report_type: "other",
          });
        });
    } catch (error) {
      // console.log("userap...", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Policy();
  }, []);
  const [othValue, setOthValue] = useState("");

  const handleAdd = () => {
    setShowadd(!Showadd);
    setOthValue("");
  };
  const [Reportid, setReportid] = useState([]);
  // console.log(Pid, Reportid);

  const [isloding, setisloding] = useState(false);
  const PosttReportapis = async () => {
    // console.log("Selected ID:", id);
    setLoading(true);

    axios({
      method: "post",
      url: "/report_a_property",
      data: {
        other_reason: othValue,
        report_id: Reportid == 4088 ? null : Reportid,
        property_id: Pid,
      },
      headers: {
        "Content-Type": "application/json",
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);

        if (data.status === 1) {
          navigate(-1);
        }
        if (data.status === 1) {
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
        } else if (data.status === 0) {
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        // console.log("err", err);
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    const {value} = event.target;
    setValues({...values, policy: value});
    setShowadd(false);
  };

  return (
    <>
      <section className="Reporting-main-section">
        <div className="Reporting-main-div">
          <div className="">
            <span className="backbtn" onClick={() => navigate(-1)}>
              <img src={back} alt="" />
            </span>
          </div>
          <div className="Reporting-Listing-div">
            <span className="perfect-place-span">
              {" "}
              Why Are You Reporting This Listing?
            </span>

            <div className="span-selact-rtag mt-4">
              {loading ? (
                <CircularProgress />
              ) : (
                <div className="report-radio">
                  {Policies?.map((itm, index) => {
                    return (
                      <div
                        className="redio-input"
                        key={index}
                        style={{
                          width: "max-content",
                        }}
                      >
                        <input
                          style={{cursor: "pointer"}}
                          type="radio"
                          id={`radio-${itm.id}`}
                          name="policy"
                          value={itm.report_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onClick={() => setReportid(itm.id)}
                        />
                        <label
                          className="ht-ml-radio"
                          style={{cursor: "pointer"}}
                          htmlFor={`radio-${itm.id}`}
                        >
                          {itm.report_type}
                        </label>
                      </div>
                    );
                  })}
                  {Reportid == 4088 && (
                    <input
                      name="oth"
                      value={othValue}
                      onChange={(e) => setOthValue(e.target.value)}
                      className="othars-input-txt"
                      type="text"
                    />
                  )}
                </div>
              )}

              <div>
                <button className="Save-btn-aya" onClick={PosttReportapis}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{color: "#9c04f5", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Reporting;
