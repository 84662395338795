import React, { useState, useEffect } from "react";
import "../Components/MyBookings.css";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import kalustar from "../Assets/Images/KaluStar.png";

const MyBookings = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  const propid = JSON.parse(localStorage.getItem("Propid"));
  const [Booking, setBooking] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [loding, setLoding] = useState(false);
  // console.log(Booking, "merihui");
  const [review, setReview] = useState([]);

  const key = JSON.parse(localStorage.getItem("rate"));
  const Navigat = useNavigate();
  const handledetail = async (item) => {
    // console.log(item);
    Navigat("/RateThisProperty", {
      state: {
        item,
      },
    });
  };
  // console.log(Booking.property_images, "bokinf");
  const Getreview = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("property_id", propid);
    // console.log(propid, "dsds....");
    try {
      let cofe = {
        url: "/get_review",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `${token.data.token.token}`,
        },
      };
      axios(cofe)
        .then((res) => res.data)

        .then((respons) => {
          // console.log(respons, "dhdyhydshf");
          setReview(respons.message, "dufhsduhf.....");
          setLoading(false);
        });
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    Getreview();
  }, []);
  const MyBookings = async () => {
    setLoading(true);
    const formdata = new FormData();
    try {
      let cofe = {
        url: "/my_bookings",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          setLoading(false);
          // console.log(respons.data);
          setBooking(respons.data);
        });
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    MyBookings();
  }, []);

  const handleset = async (item) => {
    // console.log(item);
    Navigat("/BookingDetails", {
      state: {
        item,
      },
    });
  };

  return (
    <>
      <section className="MyBookings-main-section">
        <div className="My-book-divv-rated">
          <span className="My-prop">My Traveling</span>
        </div>
        {!Loading && Booking?.length >= 1 ? (
          <div className="Mybooking-main-div">
            {Booking?.map((item, index) => {
              return (
                <>
                  <div key={index} className=" my-Completed-booking">
                    <div className="bokin-m-2" onClick={() => handleset(item)}>
                      <div className="My-booking-imgg-div">
                        <img
                          className="My-boking-div"
                          src={item.property_images[0].picture_name}
                          alt=""
                        />
                      </div>

                      <div>
                        <div>
                          {/* <button className="Completed-btn">{Completed}</button> */}
                          <button
                            className={
                              item.status == "completed"
                                ? "Completed-btn"
                                : "Upcoming-btn"
                            }
                          >
                            {item.status}
                          </button>
                        </div>
                        <div className="mt-2">
                          <span className="Grandview-texas-US-span">
                            {item.address}
                          </span>
                        </div>
                        <div>
                          <span className="Accessible-park-span">
                            {item.amenities[0]?.amenity} and more
                          </span>
                        </div>
                        <div className="night-span-price">
                          <span>€</span>
                          <span className="ma-span">{item.price}</span>
                          <span className="nm-span">/ night</span>
                        </div>
                      </div>
                    </div>
                    <div className="night-line"></div>
                    {item.rating === "No Review" ? (
                      <div className="Rate-divv-rated">
                        {item.is_rating_available === true ? null : (
                          <div className="middle-khan-diiv-book">
                            <span
                              className="Rate-this-span"
                              onClick={() => {
                                handledetail(item);
                              }}
                            >
                              Rate This Property
                            </span>

                            <span className="Rate-this-no-review">
                              No Review
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="Rate-divv-rated">
                        <span
                          className="Rated-spannn-taggg"
                          style={{ cursor: "not-allowed" }}
                        >
                          Rated
                        </span>
                        <div className="Star-Rating-combined-divv">
                          <img
                            className="Kalu-star-imgg-black"
                            src={kalustar}
                          />
                          <span className="Rated-dot-zero-spann">
                            <b>{item?.rating}</b>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <>
            <div className="Not-found">No properties found.</div>{" "}
          </>
        )}
      </section>

      {Loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default MyBookings;
