import React, { useState, useEffect, useContext } from "react";
import "../Components/Continueproperty.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import love from "../Assets/Images/love.png";
import love2 from "../Assets/Images/love2.png";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import LoginNavbar from "./LoginNavbar";
import Footer from "./Footer";
import back from "../Assets/Images/Backicon.png";
import { GlobalContext } from "../GlobalContext";
import PropertyType from "../Components/PropertyType";

const Filter = () => {
  const { setOkShow, show, setShow, filterdata } = useContext(GlobalContext);

  // console.log(filterdata);
  // const [show, setShow] = useState(false);
  // const { filterdata, setFilterdata } = useContext(GlobalContext);

  const [showMore, setShowMore] = useState(false);

  const [propertyadd, setPropertyadd] = useState([]);
  const [loading, setloading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("login"));
  const route = useLocation();
  const stateData = route.state;
  // console.log(stateData, "route data");
  const [filterproperty, setFilterproperty] = useState(
    stateData.searchResults.mapKaData.Datares.property_data
  );
  // if (stateData?.searchResults.Datares.properties.length === 0) {
  //   return <p>No properties found.</p>;
  // }
  const [data, setData] = useState(stateData.searchResults.formKadata);
  // console.log("ffffffff", data);
  // console.log(stateData, "stateData mil rha he");

  const [opnes, setOpens] = useState(false);

  const AnedoData = async (id) => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("min_price", data.min_price);
    formdata.append("max_price", data.max_price);
    formdata.append("bedrooms", data.bedrooms);
    formdata.append("beds", data.beds);
    formdata.append("bathrooms", data.bathrooms);
    formdata.append("category_id", data.category_id);

    try {
      let cofe = {
        url: "/filter",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token.data.token.token}`,
        },
      };
      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          setloading(false);
          setFilterproperty(respons.property_data);
          // console.log(respons);
          // console.log(stateData, "chak");
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };
  const wishlist = async (id) => {
    setLoading(true);
    const data = {
      property_id: filterproperty[0].property_data.id,
    };
    // console.log("ggggggggggg", data);
    // console.log(data, "oooooooo");
    try {
      const res = await axios.post(
        "/wishlist",
        {
          property_id: data.property_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token.data.token.token}`,
          },
        }
      );
      // console.log(res);
      if (res.data.status === 1) {
        setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        // AnedoData();
      } else if (res.data.status === 0) {
        setLoading(false);
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        AnedoData();
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };
  const handledetail = async (id) => {
    const newdata = {
      index: "1",
      id: { ...id, beBhai: id.property_data?.id },
      PuraData: { milja: stateData },
    };
    navigate("/PropertyDetails", {
      state: {
        newdata,
      },
    });
  };

  const handleShow = () => {
    if (token.data.is_guest === true) {
      setOkShow(true);
    } else {
      setShow(true);
    }
  };

  const ico2 = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2238)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2238">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const ico = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2257)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z"
          fill="#9C04F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2257">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  return (
    <>
      <LoginNavbar />
      <PropertyType show={show} onHide={() => setShow(false)} />

      <div>
        <span className="backbtn" onClick={() => navigate("/home")}>
          <img src={back} alt="" />
        </span>
      </div>
      <section className="Searchedpr-main-section">
        <div className="MyProperty-main-div-90">
          <div className="width-filter-div mb-3">
            <button onClick={handleShow} className="drop-down-div">
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z"
                  fill="white"
                />
              </svg>
              <span className="Read-less-span">Filter</span>
            </button>
          </div>
          {showMore && <PropertyType />}

          {!Loading && filterdata?.property_data?.length >= 1 ? (
            <div className="map-upar-div-serach-filter">
              {filterdata?.property_data?.map((curelem, index) => {
                return (
                  <div
                    key={index}
                    className="mainnn-divvv-dusri-bar"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="api-imgis">
                      <img
                        className="Prop-home-sera"
                        onClick={() => handledetail(curelem)}
                        src={
                          curelem.images[0]
                            ? curelem.images[0].picture_name
                            : null
                        }
                        alt="images"
                      />
                    </div>

                    <div className="d-flex">
                      <span className="Grandview-span-1">
                        {curelem.property_data.address}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="Accessible-span">{curelem.Detail}</span>
                    </div>
                    <div className="mt-3 span-naghit-div-main">
                      <div>
                        <span className="night-span">
                          ${curelem.property_data.price_per_night}
                        </span>
                        <span className="night-span-2"> / night</span>
                      </div>
                      {/* <div
                        onClick={() =>
                          wishlist(curelem.property_data.wishlist.id)
                        }
                      >
                        {curelem.property_data.wishlist.wishlist === true ? (
                          <img src={love2} />
                        ) : curelem.property_data.wishlist.wishlist ===
                          false ? (
                          <img src={love} />
                        ) : (
                          <img src={love} />
                        )}
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No properties found.</div>
          )}
        </div>
      </section>
      {Loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Filter;
