import React from "react";
import "../Components/Timesharerules.css";
import Footer from "./Footer";
import Navbar from "./Navbar";
import LoginNavbar from "../Components/LoginNavbar";

const Timesharerules = () => {
  const nav = localStorage.getItem("login");
  return (
    <>
      {!nav ? <Navbar /> : <LoginNavbar />}
      <div className="Timeshare-main-section-div">
        <div className="time-share-middle">
          <div className="timeshare-span-div mb-4">
            <span className="time-span-text">
              {/* What is the Hosts Timeshare Pool and what are the rules? */}
              What is the Hosts Homeswap and what are the rules?
            </span>
          </div>
          <div className="extttt-div">
            {/* <span className="blaze-span">
              Welcome to our innovative{" "}
              <i>
                {" "}
                <b>hosts homeswap program</b>{" "}
              </i>{" "}
              and thank you for choosing to blaze this trail with us!
            </span> */}
            <span className="blaze-span">
              Welcome to our innovative{" "}
              <i>
                {" "}
                <b>hosts homeswap program</b>{" "}
              </i>{" "}
              and thank you for choosing to blaze this trail with us!
            </span>
            <span className="blaze-span">
              {/* This program is our way of showing appreciation to our hosts for
              trusting us with their properties. When you feel you need a break,
              you get one- free of charge! */}
              {/* This program is our way of showing appreciation to our hosts for
              trusting us with their properties. When you feel you need a break,
              you get one- free of charge! */}
              This program is our way of showing appreciation to our hosts for
              trusting us with their properties. When you feel you need a break,
              you get one- free of charge!
            </span>
          </div>
          <div className="interest-div mt-4">
            <span className="blaze-span-1">
              {/* As a host, you have the option of entering our <b>FREE</b> home
              exchange program which gives you <b>FREE</b> accommodation for the
              same amount of time that you make your house available to other
              hosts for the same purpose. In short, you give your listed
              property to be used by other hosts for <b>FREE</b> for 1 week and
              you are similarly entitled to use another hosts property for{" "}
              <i></i>
              <b>FREE</b> for 1 week. So, instead of wasting the time that your
              listed property is empty, you put one week of that time to good
              use, earning you one week of <b>FREE</b> accommodation. The
              property that you choose to go to does not have to be the property
              of the host that chose your property. Also the days that you
              choose to go do not have to be the same days that your property is
              chosen (calendar days). As long as the chosen property is
              available on the calendar, it may be requested by a homeswap host. */}
              As a host, you have the option of entering our <b>FREE</b> home
              exchange program which gives you <b>FREE</b> accommodation for the
              same amount of time that you make your house available to other
              hosts for the same purpose. In short, you give your listed
              property to be used by other hosts for <b>FREE</b> for a certain
              amount of time and you are similarly entitled to use another hosts
              property for <b>FREE</b> for the same amount of time. So, instead
              of wasting the time that your listed property is empty, you put
              that time to good use, earning you <b>FREE</b> accommodation. The
              property that you choose to go to does not have to be the property
              of the host that chose your property. Also the days that you
              choose to go do not have to be the same days that your property is
              chosen. In other words, the homeswap does not need to be a
              simultaneous swap. As long as the chosen property is available on
              the calendar, it may be requested by a homeswap host.
            </span>

            <span className="time-span-text mt-4">
              {/* Of course that in the best interest of all of us, there are a few
              simple conditions attached to this: */}
              Of course that in the best interest of all of us, there are a few
              simple conditions attached to this:
            </span>
          </div>
          <div className="first-point-divv mt-4 d-flex">
            <span className="first-span-tagg-ppz">1.</span>
            <span className="ek-ka-ek-ching">
              {/* Your house will be star graded (similar concept to the star
              grading of hotels) from 1 to 7. This grading is for the purposes
              of the timeshare pool only. You are free to choose any property
              (calendar permitting) with the same star grading as your own
              within the available properties in the timeshare pool. The purpose
              of the timeshare pool star rating system is to ensure fairness and
              as much as possible, equality between the properties being
              exchanged. */}
              {/* Your house will be star graded (similar concept to the star
              grading of hotels) from 1 to 7. This grading is for the purposes
              of the homeswap only. You are free to choose any property
              (calendar permitting) with the same star grading as your own
              within the available properties in the homeswap club. The purpose
              of the hosts Homeswap star rating system is to ensure fairness and
              as much as possible, equality between the properties being
              exchanged. */}
              Your house will be star graded (similar concept to the star
              grading of hotels) from 1 to 7. This grading is for the purposes
              of the homeswap only. You are free to choose any property
              (calendar permitting) with the same star grading as your own
              within the available properties in the homeswap club. The purpose
              of the hosts Homeswap star rating system is to ensure fairness and
              as much as possible, equality between the properties being
              exchanged.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">2.</span>
            <span className="ek-ka-ek-ching">
              {/* You can register your property for the timeshare pool for 1 week
              per year (12 months). */}
              {/* You can register your property for the homeswap for 1 week per
              year (12 months). */}
              You can register your property for the homeswap for 4 weeks per
              year (12 months). These 4 weeks may be taken all at once or split;
              depending on if the homeswap is a simultaneous swap or not.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">3.</span>
            <span className="ek-ka-ek-ching">
              {/* You may apply to join the hosts timeshare pool at any time but you
              will only be considered for such once your listed property has had
              3 completed bookings with reviews (this refers to rental bookings
              and not timeshare pool bookings) AND if your property is available
              for bookings at least 90 days per year (12 month period). In other
              words, you cannot block off your calendar for more than 9 months
              of the year. */}
              {/* You may apply to join the hosts Homeswap club at any time but you
              will only be considered for such once your listed property has had
              3 completed bookings with reviews (this refers to rental bookings
              and not homeswap club bookings) AND if your property is available
              for bookings at least 90 days per year (12 month period). In other
              words, you cannot block off your calendar for more than 9 months
              of the year. */}
              You may apply to join the hosts Homeswap club at any time but you
              will only be considered for such once your listed property has had
              3 completed bookings with reviews (this refers to rental bookings
              and not homeswap bookings) AND if your property is available for
              bookings at least 90 days per year (12 month period). In other
              words, you cannot block off your calendar for more than 9 months
              of the year.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">4.</span>
            <span className="ek-ka-ek-ching">
              {" "}
              {/* Once you have used timeshare pool days, you may not withdraw your
              listed property from the timeshare pool until the days of your
              property have been used by another member of the timeshare pool. */}
              {/* Once you have used hosts homeswap days, you may not withdraw your
              listed property from the homeswap club until the days of your
              property have been used by another member of the homeswap club. */}
              Once you have used hosts homeswap days, you may not withdraw your
              listed property from the homeswap club until the days of your
              property have been used by another member of the homeswap club
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">5.</span>
            <span className="ek-ka-ek-ching">
              {/* Unused timeshare pool days do not accumulate from one year to
              another. */}
              Unused homeswap days do not accumulate from one year to another.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">6.</span>
            <span className="ek-ka-ek-ching">
              {/* If you book a timeshare stay for 1 week and do not use the full
              week, the remaining days of that week are forfeited. However you
              may not proportionately cut the amount of days that your property
              has put into the timeshare pool. */}
              {/* If you book a homeswap stay for 1 week and do not use the full
              week, the remaining days of that week are forfeited. However you
              may not proportionately cut the amount of days that your property
              has put into the homeswap club. */}
              We advise that homeswap time be counted in weeks (rounded off to
              the nearest full week) rather than days. This will facilitate non
              simultaneous homeswaps to take place thereby giving our homeswap
              hosts ease of planning their calendars.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">7.</span>
            <span className="ek-ka-ek-ching">
              {/* Timeshare pool stays are completely free except for an optional
              cleaning fee to the maximum of 40€ per stay. */}
              {/* Homeswap stays are completely free except for an optional cleaning
              fee to the maximum of 40€ per stay. */}
              Homeswap stays are completely free except for an optional cleaning
              fee to the maximum of 40€ per stay.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">8.</span>
            <span className="ek-ka-ek-ching">
              House rules must always be clear and respected.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">9.</span>
            <span className="ek-ka-ek-ching">
              {/* In order to qualify for the timeshare pool, your property must be
              listed on the platform for guest rental. In other words, if your
              property is not listed on the platform as a rental property for
              guests, you cannot register for the timeshare pool. */}
              {/* In order to qualify for the homeswap club, your property must be
              listed on the platform for guest rental. In other words, if your
              property is not listed on the platform as a rental property for
              guests, you cannot register for the hosts homeswap club. */}
              In order to qualify for the homeswap club, your property must be
              listed on the platform for guest rental. In other words, if your
              property is not listed on the platform as a rental property for
              guests, you cannot register for the hosts homeswap club.
            </span>
          </div>
        </div>
      </div>
      <Footer />;
    </>
  );
};

export default Timesharerules;
