import React from "react";
import "../Components/Choose.css";
import Navbar from "./Navbar";
import Footer from "../Components/Footer";
const Choose = () => {
  return (
    <>
      <Navbar />
      <section className="Choose-sec-main">
        <div className="Choose-sec-div">
          <div className="Choose-thard">
            <span className="time-span-text">
              Choose a cancellation policy that best suits your needs.
            </span>
            <span className="mt-3 para-span-time-share">Flexible</span>
            <span className="para-span-time-share">
              Full refund* up to 1 day prior to arrival.
            </span>
            <span className="mt-3 para-span-time-share">
              Discounted Non-refundable
            </span>
            <span className="para-span-time-share">
              {" "}
              Guests pay 10% less, but will not be refunded in case of a
              cancellation, regardless of when they{" "}
            </span>
            <span className="para-span-time-share">book or cancel. </span>{" "}
            <span className="mt-3 para-span-time-share">Moderate</span>
            <span className="para-span-time-share">
              Full* refund 7 days prior to arrival.
            </span>
            <span className="para-span-time-share">
              If booking is made less than 7 days prior to arrival, full* refund
              if cancellation is made within 24{" "}
            </span>
            <span className="para-span-time-share">hours of booking.</span>
            <span className="para-span-time-share">
              After that, 50% refund.
            </span>
            <span className="mt-3 para-span-time-share">24 hour/30 day</span>
            <span className="para-span-time-share">
              Full* refund for cancellations up to 30 days before check-in.
              After that, 50% refund.
            </span>
            <span className="para-span-time-share">
              Full* refund for cancellations made within 24 hours of booking.
              After that 50% refund.
            </span>
            <span className="mt-3 para-span-time-share">Strict</span>
            <span className="para-span-time-share">
              Full* refund for cancellations made within 48 hours of booking, if
              the check-in date is at least 14{" "}
            </span>
            <span className="para-span-time-share">days away.</span>
            <span className="para-span-time-share">
              50% refund for cancellations made at least 7 days before check-in.
            </span>
            <span className="para-span-time-share">
              No refunds for cancellations made within 7 days of check-in.
            </span>
            <span className="mt-3 para-span-time-share">24 hour no refund</span>
            <span className="para-span-time-share">
              Full* refund if cancellation is made within 24 hours of booking.
              After that no refund.
            </span>
            <span className="mt-3 para-span-time-share">
              Long stay cancellations ( 28 days or more)
            </span>
            <span className="para-span-time-share">Strict Long stay</span>
            <span className="para-span-time-share">
              Full* refund if canceled within 48 hours of booking and at least
              30 days before check-in.
            </span>
            <span className="para-span-time-share">
              After that, the first 14 days of the stay are non-refundable.
            </span>
            <span className="mt-3 para-span-time-share">
              Flexible Long stay
            </span>
            <span className="para-span-time-share">
              Full* refund if canceled up to 30 days before check-in.
            </span>
            <span className="para-span-time-share">
              After that, the first 14 days of the stay are non-refundable.
            </span>
            <span className="mt-3 para-span-time-share">
              *Please note that 3% of the paid amount is not refunded under any
              circumstances.
            </span>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Choose;
