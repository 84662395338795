import React from "react";
import "../Components/Timesharerules.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import LoginNavbar from "../Components/LoginNavbar";
const Knowmore = () => {
  const nav = localStorage.getItem("login");
  return (
    <>
      {!nav ? <Navbar /> : <LoginNavbar />}
      <section className="download-section-2">
        <div className="download-div-main">
          <div className="download-div">
            <span className="time-span-text">
              Safely register your property on our website and be prepared to
              start receiving your guests once you download our App
            </span>
            <span className="mt-4 download-span">
              {/* Our App will be ready to download as per the countdown clock. In
              the meantime, register your property on our platform and when you
              download the App we will bring your guests to you! */}
              Sign up for free and list your property on our platform and when
              you download the App we will bring your guests to you!
            </span>
            <span>
              The best part is, once you have had 3 completed bookings (and
              fulfill a few other conditions), if you are registered on our
              <b style={{ marginLeft: "0.3rem" }}>
                FREE HOMESWAP FOR HOSTS PROGRAM*{" "}
              </b>{" "}
              you will be eligible for a free accommodation from any other
              available property on the hosts homeswap program.So go ahead and
              list your property and let's get started!
            </span>
            <span className="mt-4">
              <i>*terms and conditions apply.</i>
            </span>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Knowmore;
