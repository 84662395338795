import React, { useState, useEffect } from "react";
import "../Components/Addpaymentmethods.css";
import visalogo from "../Assets/Images/visalogo.jpg";
import mastercard from "../Assets/Images/mastercard.png";
import jdb from "../Assets/Images/jcb.png";
import Discover from "../Assets/Images/Discover.png";
import dinersclub from "../Assets/Images/dinersclub.png";
import UnionPay from "../Assets/Images/UnionPay.png";
import AmeExp from "../Assets/Images/AmeExp.png";
import tick from "../Assets/Images/tick.png";
import axios from "../Schemas/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "./Footer";
import LoginNavbar from "./LoginNavbar";
import Modal from "react-bootstrap/Modal";
import aryy from "../Assets/Images/backarry.png";
import { Backdrop, CircularProgress } from "@mui/material";
import back from "../Assets/Images/Backicon.png";

const Paymentmethods = () => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("login"));
  const [data, setData] = useState();
  const [del, setDel] = useState();
  const [pm, setPm] = useState();
  const [Id, setId] = useState(0);
  const [modal, setModal] = useState(0);
  const [loading, setloading] = useState(false);
  const [show, setShow] = useState(false);
  const Navigate = useNavigate();

  let route = useLocation();
  const getdata = route.state;

  // console.log(getdata);
  // console.log(pm);
  const BookingRequestApis = () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("card_token", pm);
    formdata.append("cleaning_fees", getdata.cleaning_fees);
    formdata.append("booking_fees", getdata.booking_fees);
    formdata.append("service_fees", getdata.service_fees);
    formdata.append("total_charge", getdata.total_charge);
    formdata.append("description", getdata.description);
    formdata.append("property_id", getdata.property_id);
    formdata.append("start_date", getdata.start_date);
    formdata.append("end_date", getdata.end_date);
    formdata.append("guests", getdata.guests);
    formdata.append("coupon", getdata.coupons);
    formdata.append("discount", 0);

    // console.log(final_subtotal, "ffffff");
    axios({
      method: "post",
      url: "/bookings",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token.data.token.token,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setloading(false);
        // console.log(data, " bnbnbnbnb");
        // setneardata(data);
        if (data.status === 0) {
          navigate("/PaymentFailed");
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        } else if (data.status === 1) {
          // setShow(true);
          navigate("/Congratulations");
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        setloading(false);
        toast.error(err, {
          theme: "colored",
          position: "top-right",
        });
      });
  };
  // console.log(getdata, "gggggggg");
  const GetCard = () => {
    setloading(true);
    axios({
      method: "get",
      url: "/my_cards",
      headers: {
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setloading(false);
        setData(data?.data);
        setDel(data?.data[0].card_id);
        setPm(data?.data[0].payment_method);
        // console.log(data?.data[0]);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    GetCard();
  }, []);
  // console.log(del);
  const DelCard = () => {
    setloading(true);
    axios({
      method: "post",
      url: "/delete_card",
      data: {
        card_id: del,
      },
      headers: {
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        setloading(false);
        if (data.status === 0) {
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        } else if (data.status === 1) {
          GetCard();
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        setloading(false);
      });
  };

  return (
    <>
      <LoginNavbar />
      {/* <Popup show={show} onHide={() => setShow(false)} /> */}
      <section className="payment-main-section90">
        <div>
          <span className="backbtn" onClick={() => Navigate(-1)}>
            <img src={back} alt="" />
          </span>
        </div>
        <div className="payment-main-dib90">
          <div className="paymnet-add-div">
            <div className="payment-span-divv-head">
              <span className="Payment-Options-span">Payment Methods</span>
            </div>
            {/* <div
              className="add-logocard-div99"
              onClick={() => Navigate("/Payment")}
            >
              <img alt="" className="add-logo-pls" />
              <span className="Add-New-Card-span">Add New Card</span>
            </div> */}
          </div>
          {!loading && data?.length >= 1 ? (
            <div className="paymnet-three-main-div">
              {data?.map((curelem, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="payment-input67"
                      onClick={() => {
                        setId(curelem.payment_method);
                      }}
                    >
                      <img
                        src={
                          curelem.brand == "Visa"
                            ? visalogo
                            : curelem.brand == "MasterCard"
                            ? mastercard
                            : curelem.brand == "JCB"
                            ? jdb
                            : curelem.brand == "discover"
                            ? Discover
                            : curelem.brand == "diners"
                            ? dinersclub
                            : curelem.brand == "union"
                            ? UnionPay
                            : curelem.brand == "American Express"
                            ? AmeExp
                            : null
                        }
                        alt=""
                        className="visaimg-img"
                      />
                      <span
                        onClick={() => setShow(true)}
                        className="card-span-divv"
                      >
                        XXXX XXXX XXXX {curelem.last4}
                      </span>
                      {/* <div className="svg-tick-green-divv">
                        {" "}
                        <img
                          className="Tick-logo-img-src"
                          src={Id == curelem.payment_method ? tick : null}
                        />
                      </div> */}
                      <div
                        className="dustbin-logo-black"
                        onClick={() => DelCard()}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_25_2595)">
                            <path
                              d="M17 6H22V8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8H2V6H7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
                              fill="#211B24"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_25_2595">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          ) : (
            <div className="Not-found mb-5">No properties found.</div>
          )}
          {/* <div className="Add-payment-btnn-div">
            <button
              className="btn-apply-to-submit-form"
              onClick={() => Navigate("/Payment")}
            >
              Add Payment method
            </button>
          </div> */}
        </div>
        {!loading && <div></div>}
        {loading && (
          <div>
            <Backdrop
              sx={{
                color: "#9c04f5",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default Paymentmethods;
