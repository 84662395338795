import React, { useState, useEffect, useContext } from "react";
import "../Components/PropertyDetails.css";
import Star from "../Assets/Images/Star.png";
import Flagblack from "../Assets/Images/Flagblack.png";
import phone from "../Assets/Images/Phone.png";
import message from "../Assets/Images/Message.png";
import downarrow from "../Assets/Images/Downarrow.png";
import Bed from "../Assets/Images/Bed.png";
import group from "../Assets/Images/Group.png";
import bedroom from "../Assets/Images/bedroom.png";
import Bathroom from "../Assets/Images/Bathroom.png";
import leftarrow from "../Assets/Images/Leftarrow.png";
import aryy from "../Assets/Images/backarry.png";
import yellowstar from "../Assets/Images/Yellowstar.png";
import greystar from "../Assets/Images/greystar.png";
import SwiperPropertydetails from "../Components/SwiperPropertydetails";
import axios from "../Schemas/Api";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import LoginNavbar from "../Components/LoginNavbar";
import { CircularProgress } from "@mui/material";
import mapstyle from "./mapStyles";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import GoogleMaps from "./GoogleMaps";
import back from "../Assets/Images/Backicon.png";
import { GlobalContext } from "../GlobalContext";

const PropertyDetails = () => {
  const { setOkShow } = useContext(GlobalContext);

  const [showMore, setShowMore] = useState(false);
  const [loading, setlaoding] = useState(false);
  const [reviewHere, setReviewHere] = useState([0]);
  const [reviewHere2, setReviewHere2] = useState([0]);
  const [House, setHouse] = useState([0]);
  let route = useLocation();
  const maindata = route?.state?.newdata;
  const [idds, setidss] = useState(maindata?.id?.beBhai);
  // console.log(idds, "okokokokokokokok");
  // console.log(maindata, "maindata.......................");

  const Ratingindex = maindata?.id?.property_data.average_ratings;
  // const Ratingindex = "1";

  // console.log(Ratingindex, "Ratingindex..........");
  const handleClick = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    // if (maindata?.index === "1") {
    //   setidss(maindata?.property_detail?.id);
    // } else if (maindata?.index === "2") {
    //   setidss(maindata?.property_data?.id);
    // }
    setidss(idds);
    // console.log(
    //   "🚀 ~ file: PropertyDetails.js:49 ~ useEffect ~ maindata?.id.beBhai:",
    //   maindata?.id.beBhai
    // );
  }, []);

  const [showAmeties, setShowAmeties] = useState(false);

  const handleChange = () => {
    setShowAmeties(!showAmeties);
  };

  const [showReviews, setShowReviews] = useState(false);

  const handleOpen = () => {
    setShowReviews(!showReviews);
  };

  const token = JSON.parse(localStorage.getItem("login"));
  // console.log(token, "stored response");
  const [detail, setDetail] = useState([]);
  // console.log(detail, "detail ho gai");
  const propertydetail = async () => {
    setlaoding(true);
    const formdata = new FormData();
    // console.log("callll");
    formdata.append("property_id", idds);

    try {
      let pdetails = {
        url: "/property_detail",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };

      axios(pdetails)
        .then((res) => res.data)
        .then((respo) => {
          // console.log(respo, "bhot dur miljaaa");
          setlaoding(false);
          setDetail([respo.data?.property_detail]);
          setReviewHere(respo.data?.reviews);
          setReviewHere2(respo.data?.reviews);
          setHouse(respo.data?.property_detail?.house_rules);
        });
    } catch (error) {
      // console.log("userap...", error);
      setlaoding(false);
    }
  };
  useEffect(() => {
    propertydetail();
  }, []);

  // console.log(reviewHere);

  const Bookin = async () => {
    const formdata = new FormData();
    // console.log("callll");
    formdata.append("start_date");

    try {
      let pdetails = {
        url: "/bookings",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };

      axios(pdetails)
        .then((res) => res.data)
        .then((respo) => { });
    } catch (error) {
      // console.log("userap...", error);
    }
  };
  useEffect(() => {
    propertydetail();
  }, []);

  // console.log(detail);

  const Navigat = useNavigate();

  const handleclick = async () => {
    if (token?.data?.is_guest === true) {
      setOkShow(true);
    } else {
      Navigat("/BookingRequest ", {
        state: maindata,
      });
    }
  };

  const handlReport = async () => {
    if (token?.data?.is_guest === true) {
      setOkShow(true);
    } else {
      Navigat("/Reporting", {
        state: maindata,
      });
    }
  };
  const navigate = useNavigate();
  // console.log(detail, "dure se ");
  return (
    <>
      <LoginNavbar />

      <div>
        <span className="backbtn" onClick={() => navigate(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <div className="homeparentmain">
        {loading ? (
          <div className="circular2">
            <CircularProgress />
          </div>
        ) : (
          <div className="Home-slider-merge-divv">
            <div className="SwiperProperty-home-divv-ppx">
              <SwiperPropertydetails />
            </div>
            {detail.map((currelem, index) => {
              const Amenities = currelem?.amenities?.slice(0, 10);
              const Amenities2 = currelem?.amenities?.slice(10);
              return (
                <div key={index} className="MAin-slider-containor-divv">
                  <div className="MAin-slider-containor-divv">
                    <div className="Slider-second-div-ppx">
                      <div className="Texas-Grand-Middle-div-ppx">
                        <div className="yexas-merge-div">
                          <div className="grandview-texas-us-ppx">
                            <span className="grandview-texas-us-span">
                              {currelem?.address}
                            </span>
                          </div>
                          <div className="Assible-parking-main-div">
                            <span className="Accecible-parking-us-span">
                              {currelem?.amenities[0]?.amenity} and more
                            </span>
                          </div>
                        </div>
                        <div className="merge-div-rating-tff mt-4">
                          <div className="rating-four-texas-div">
                            <img className="Star-raing-ppx" src={Star} />
                            <span className="Star-rating-span-ppx">
                              {" "}
                              {currelem.average_ratings}
                            </span>
                          </div>
                          <div className="three-four-four-main-div"></div>
                        </div>
                      </div>

                      <div className="report-people-main-div">
                        <div className="Report-this-listing-div-ppx mt-4">
                          <span className="two-rs-us-span">
                            €{currelem.price_per_night}
                          </span>
                          <span className="per-main-div"> / </span>
                          <span className="night-per-main-div">night</span>
                        </div>
                        <div
                          onClick={handlReport}
                          className="report-this-flag-divv mt-4"
                        >
                          <img className="Flag-imgg-report" src={Flagblack} />
                          <span className="report-flag-div-span">
                            {" "}
                            Report this listing
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="Underline-main-divv mt-4"></div>

                    <div className="entire-home-profile">
                      <div className="entire-home-profile-bell">
                        <div className="profile-Jerome-Bell-main-divv mt-3">
                          <span className="entire-jerome-bell-div">
                            Entire home hosted by
                          </span>
                        </div>
                        <div className="profile-plus-name-divv mt-3">
                          <img
                            className="profile-imgg-name"
                            src={currelem.host_profile}
                          />
                          <span className="profile-Jermo-name-ppx">
                            {currelem.host_name}
                          </span>
                        </div>
                      </div>
                      <div className="Book-now-main-divv-ppx">
                        <button
                          className="Book-second-divv-btnn"
                          onClick={() => handleclick()}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                    <div className="Underline-main-divv mt-4"></div>
                    <div className="Lorem-span-main-divv mt-4">
                      <div className="Read-lorem-main-divv-ppx">
                        <span className="lorem-div-span-para">
                          {currelem.neighbourhood_description}
                        </span>
                        <br />
                        {showMore && (
                          <span className="lorem-div-span-para">
                            {currelem.about_property}
                          </span>
                        )}
                        <div className="lorem-show-more-divv-ppx mt-4">
                          <button
                            className="Lorem-read-mor-btnn"
                            onClick={handleClick}
                          >
                            {showMore ? (
                              <span className="Read-less-arrow-span">
                                Read Less
                              </span>
                            ) : (
                              <span className="Read-less-arrow-span">
                                Read More
                              </span>
                            )}
                            <img
                              className="Lorem-imgg-downarrow"
                              src={downarrow}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="Guests-bed-bathroom-main-divv">
                      <div className="Guests-bed-fourteen-beds-ppx">
                        <div className="Guests-bed-nine-bats-ppx mt-3">
                          <div className="Guests-fourten-imgg-divvv">
                            <img
                              className="Guests-fourten-imgg-ppx"
                              src={bedroom}
                            />
                          </div>
                          <div className="Guests-spann-divv-ppx">
                            <span className="Guests-fourten-spann-ppx">
                              {" "}
                              {currelem.beds.length}
                            </span>
                            <span className="Guests-para-spann-ppx">
                              Bedrooms
                            </span>
                          </div>
                        </div>
                        <div className="Guests-bed-nine-bats-ppx mt-3">
                          <div className="Guests-fourten-imgg-divvv">
                            <img
                              className="Guests-fourten-imgg-ppx"
                              src={group}
                            />
                          </div>
                          <div className="Guests-spann-divv-ppx">
                            <span className="Guests-fourten-spann-ppx">
                              {" "}
                              {currelem.child + currelem.adult}
                            </span>
                            <span className="Guests-para-spann-ppx">
                              Guests
                            </span>
                          </div>
                        </div>
                        <div className="Guests-bed-nine-bats-ppx mt-3">
                          <div className="Guests-fourten-imgg-divvv">
                            <img
                              className="Guests-fourten-imgg-ppx"
                              src={Bathroom}
                            />
                          </div>
                          <div className="Guests-spann-divv-ppx">
                            <span className="Guests-fourten-spann-ppx">
                              {" "}
                              {currelem.bathroom.length}
                            </span>
                            <span className="Guests-para-spann-ppx">
                              Bathrooms
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Total_beds_and_bathrooms">
                      <div className="mt-4">
                        <span className="What-this-span-offer-para">
                          Where you'll sleep
                        </span>
                      </div>
                      <div className="img-bath-main-dixx-ppx mt-3">

                        {currelem?.beds.map((itm, index) => {
                          return (
                            <div className="All_baths_and_beds">
                              <h5>Bedroom {index + 1}</h5>
                              {itm?.beds.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="imgg-divv-title-bath"
                                  // onClick={() => handleCategoryClick(itm.id)}

                                  >
                                    <img
                                      className="imgg-divv-itmm"
                                      src={Bed}
                                    />

                                    <span className="house-mera mt-2">{item.type}</span>
                                  </div>
                                );

                              })}

                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-4">
                        <span className="What-this-span-offer-para">
                          Where you'll shower
                        </span>
                      </div>
                      <div className="img-bath-main-dixx-ppx mt-4">
                        {currelem?.bathroom.map((itm, index) => {
                          return (
                            <div className="All_baths_and_beds">
                              <h5>Bathroom {index + 1}</h5>
                              {itm?.beds.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="imgg-divv-title-bath"
                                  // onClick={() => handleCategoryClick(itm.id)}

                                  >
                                    <img
                                      className="imgg-divv-itmm"
                                      src={Bathroom}
                                    />

                                    <span className="house-mera mt-2">{item.type}</span>
                                  </div>
                                );

                              })}

                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="What-this-span-main-divv-ppx">
                      <div className="What-this-second-offer-ppx mt-5">
                        <span className="What-this-span-offer-para">
                          What this place offer
                        </span>
                      </div>
                      <div className="What-this-span-btnn-divv">
                        <div className="What-list-divvv-btnnss">
                          {Amenities.map((item, index) => {
                            return (
                              <button
                                key={index}
                                className="what-this-list-btn"
                              // className="What-this-list-mountain-btnnss"
                              >
                                {item.amenity}
                              </button>
                            );
                          })}
                          {/* <button
                            className="what-this-list-btn"
                            // className="What-this-list-mountain-btnnss"
                          >
                            {currelem?.amenities[0]?.amenity}
                          </button>
                          <button
                            className="what-this-list-btn"
                            // className="What-this-list-kitchen-btnnss"
                          >
                            {currelem?.amenities[1]?.amenity}
                          </button>
                          <button
                            className="what-this-list-btn"
                            // className="What-this-list-wifi-btnnss"
                          >
                            {currelem?.amenities[2]?.amenity}
                          </button>
                          <button
                            className="what-this-list-btn"
                            // className="What-this-list-dedicated-btnnss"
                          >
                            {currelem?.amenities[3]?.amenity}
                          </button>
                          <button
                            className="what-this-list-btn"
                            // className="What-this-list-Free-btnnss"
                          >
                            {currelem?.amenities[4]?.amenity}
                          </button>
                          <button
                            className="what-this-list-btn"
                            // className="What-this-list-security-btnnss"
                          >
                            {currelem?.amenities[5]?.amenity}
                          </button>
                          <button
                            className="what-this-list-btn"
                            // className="What-this-list-view-btnnss"
                          >
                            {currelem?.amenities[6]?.amenity}
                          </button>
                          <button
                            className="what-this-list-btn"
                            // className="What-this-list-kit-btnnss"
                          >

                            {currelem.amenities[7].amenity}
                          </button> */}

                          {showAmeties && (
                            <div className="What-list-divvv-btnnss">
                              {Amenities2.map((item, index) => {
                                return (
                                  <button
                                    key={index}
                                    className="what-this-list-btn"
                                  // className="What-this-list-mountain-btnnss"
                                  >
                                    {item.amenity}
                                  </button>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="lorem-show-more-divv-ppx mt-4">
                        <button
                          className="Lorem-read-mor-btnn"
                          onClick={handleChange}
                        >
                          {showAmeties ? (
                            <span className="Read-less-arrow-span">
                              Show less amenities
                            </span>
                          ) : (
                            <span className="Read-less-arrow-span">
                              Show all amenities
                            </span>
                          )}
                          <img
                            className="Lorem-imgg-downarrow"
                            src={downarrow}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="Underline-main-divv mt-4"></div>
                    <div className="Find-on-map-main-divv mt-4 ">
                      <span className="Find-on-map-span-para">Find on map</span>
                    </div>

                    <div className="mapouter">
                      <div className="gmap_canvas">
                        {/* <iframe
                          width="100%"
                          height="200px"
                          id="gmap_canvas"
                          src="https://maps.google.com/maps?q=Wyckoff&t=&z=9&ie=UTF8&iwloc=&output=embed"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                        ></iframe> */}
                        {/* <Map
                          bootstrapURLKeys={{
                            key: "AIzaSyB86XJujeIEHyCA9YZE15d3rKYfKNVfP0A",
                          }}
                          defaultZoom={13}
                          options={{styles: mapstyle}}
                          defaultCenter={{
                            lat: 24.4539,
                            lng: 54.3773,
                          }}
                        /> */}
                        <GoogleMaps
                          MyVar={{
                            lat: parseFloat(currelem.latitude),
                            long: parseFloat(currelem.longitude),
                          }}
                        />
                      </div>
                    </div>
                    {/* <div
                      className="map-google-home"
                      style={{width: "100%", height: "550px"}}
                    >
                      <Map
                        bootstrapURLKeys={{
                          key: "AIzaSyB86XJujeIEHyCA9YZE15d3rKYfKNVfP0A",
                        }}
                        defaultZoom={13}
                        options={{styles: mapstyle}}
                        defaultCenter={{
                          lat: 41.01438,
                          lng: -74.16619,
                        }}
                      />
                    </div> */}

                    {/* <div className="Both-Merge-Avail-House-main">
                      <div className="Availability-house-main-divv mt-5">
                        <div className="Availability-four-dec-divv">
                          <div className="Avail-down-spn-divv">
                            <span className="Avail-down-arr-dec-divv">
                              Availability
                            </span>
                          </div>
                          <div className="Avail-down-arr-imgg-divv">
                            <img className="Avail-left-arrow" src={leftarrow} />
                          </div>
                        </div>
                        <div className="four-dec-twenty-divv mt-1">
                          <span className="four-dec-span-twenty">
                            4 -20 December
                          </span>
                        </div>
                        <div className="Four-underline-spnn-divv mt-3"></div>
                      </div>
                      <div className="Availability-house-main-divv mt-5">
                        <div className="Availability-four-dec-divv">
                          <div className="Avail-down-spn-divv">
                            <span className="Avail-down-arr-dec-divv">
                              House Rules
                            </span>
                          </div>
                          <div className="Avail-down-arr-imgg-divv">
                            <img className="Avail-left-arrow" src={leftarrow} />
                          </div>
                        </div>
                        <div className="four-dec-twenty-divv mt-1">
                          <span className="four-dec-span-twenty">
                            Check-In after 4:00 PM
                          </span>
                        </div>
                        <div className="Four-underline-spnn-divv mt-3"></div>
                      </div>
                    </div> */}
                    <div className="Health-safety-main-divv mt-3">
                      <div className="Health-safety-four-dec-divv">
                        <div className="Health-safety-spn-divv">
                          <span className="Health-safety-arr-dec-divv">
                            House Rules
                          </span>
                        </div>
                        {/* <div className="Health-safety-arr-imgg-divv">
                          <img
                            className="Health-safety-left-arrow"
                            src={leftarrow}
                          />
                        </div> */}
                      </div>
                      {House.map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="Health-lorem-divv-ppx mt-1"
                            >
                              <div className="Health-lorem-spann-divv">
                                <span className="Health-lorem-spann-para-ppx">
                                  {item.house_rule}
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="Underline-main-divv mt-3"></div>
                    {/* <div className="Health-safety-main-divv mt-3">
                      <div className="Health-safety-four-dec-divv">
                        <div className="Health-safety-spn-divv">
                          <span className="Health-safety-arr-dec-divv">
                            Health & Safety
                          </span>
                        </div>
                        <div className="Health-safety-arr-imgg-divv">
                          <img
                            className="Health-safety-left-arrow"
                            src={leftarrow}
                          />
                        </div>
                      </div>
                      <div className="Health-lorem-divv-ppx mt-1">
                        <div className="Health-lorem-spann-divv">
                          <span className="Health-lorem-spann-para-ppx">
                            Lorem ipsum dolor sit amet consectetur. Id interdum
                            vulputate nisl tortor. Eget mattis vitae sed eget.
                            Justo turpis urna qu
                          </span>
                        </div>
                        <div className="Underline-main-divv mt-3"></div>
                      </div>
                    </div> */}
                    <div className="Health-safety-main-divv mt-3">
                      <div className="Health-safety-four-dec-divv">
                        <div className="Health-safety-spn-divv">
                          <span className="Health-safety-arr-dec-divv">
                            Cancellation Policy
                          </span>
                        </div>
                        {/* <div className="Health-safety-arr-imgg-divv">
                          <img
                            className="Health-safety-left-arrow"
                            src={leftarrow}
                            alt=""
                          />
                        </div> */}
                      </div>
                      <div className="Health-lorem-divv-ppx mt-1">
                        <div className="Health-lorem-spann-divv">
                          <b>{currelem?.cancellation_policy.title}</b> <br />
                          <span className="Health-lorem-spann-para-ppx">
                            {currelem?.cancellation_policy.policy}
                          </span>
                        </div>
                        <div className="Underline-main-divv mt-3"></div>
                      </div>
                    </div>
                    <div className="Reviews-see-all-rating-main-div">
                      {reviewHere.slice(0, 3).map((item, index) => {
                        const RatingStar = () => {
                          if (item?.rating === 5) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 4) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 3) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 2) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 1) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 0) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          }
                        };
                        return (
                          <>
                            <div
                              key={index}
                              className="Reviews-rating-desc-mian-divv"
                            >
                              <div className="jermo-reviews-date-june-divv mt-3">
                                <div className="Bell-rev-date-june-divv">
                                  <div className="Reviews-jermo-bell-mian-divv">
                                    <div className="Reviews-profile-photo-divv">
                                      <img
                                        className="Reviews-profile-imgg"
                                        src={item?.profile_pic}
                                      />
                                    </div>
                                    <div className="Jerome-Bell-name-tagg">
                                      <div className="Jerome-PROF-BEL-tagg">
                                        <span className="Jerome-Bell-spnn-name">
                                          <b>{item?.fullname}</b>
                                        </span>
                                        <RatingStar />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="date-jermo-text-june-divv">
                                  <span className="date-jermo-spann-june-divv">
                                    {item?.create_date}
                                  </span>
                                </div>
                              </div>
                              <div className="lorem-date-jermo-divv mt-1">
                                <span className="lorem-jermo-spn-divv-aat">
                                  {item?.review}
                                </span>
                              </div>
                              <div className="Underline-main-divv mt-3"></div>
                            </div>
                          </>
                        );
                      })}

                      {showReviews && (
                        <div className="Reviews-see-all-rating-main-div">
                          {reviewHere2.slice(3).map((item, index) => {
                            const RatingStar = () => {
                              if (item.rating === 5) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 4) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 3) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 2) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 1) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 0) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            };
                            return (
                              <>
                                <div
                                  key={index}
                                  className="Reviews-rating-desc-mian-divv"
                                >
                                  <div className="jermo-reviews-date-june-divv mt-3">
                                    <div className="Bell-rev-date-june-divv">
                                      <div className="Reviews-jermo-bell-mian-divv">
                                        <div className="Reviews-profile-photo-divv">
                                          <img
                                            className="Reviews-profile-imgg"
                                            src={item?.profile_pic}
                                          />
                                        </div>
                                        <div className="Jerome-Bell-name-tagg">
                                          <div className="Jerome-PROF-BEL-tagg">
                                            <span className="Jerome-Bell-spnn-name">
                                              <b>{item?.fullname}</b>
                                            </span>
                                            <RatingStar />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="date-jermo-text-june-divv">
                                      <span className="date-jermo-spann-june-divv">
                                        {item?.create_date}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="lorem-date-jermo-divv mt-1">
                                    <span className="lorem-jermo-spn-divv-aat">
                                      {item?.review}
                                    </span>
                                  </div>
                                  <div className="Underline-main-divv mt-3"></div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className="lorem-show-more-divv-ppx mt-4">
                      {Ratingindex > 0 && (
                        <button
                          className="Lorem-read-mor-btnn"
                          onClick={handleOpen}
                        >
                          {showMore ? (
                            <span className="Read-less-arrow-span">
                              See less Reviews
                            </span>
                          ) : (
                            <span className="Read-less-arrow-span">
                              See all Reviews
                            </span>
                          )}
                          <img
                            className="Lorem-imgg-downarrow"
                            src={downarrow}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PropertyDetails;
