export const Comfortdata = [
  {
    id: 1,
    text: "Essential amenities / comfort",
    backEndName: "comfort",
    is_selected: false,
  },
];
export const Decordata = [
  {
    id: 2,
    text: "Decor",
    backEndName: "decor",
    is_selected: false,
  },
];
export const Safetydata = [
  {
    id: 3,
    text: "Safety of the area",
    backEndName: "safety_on_area",
    is_selected: false,
  },
];
export const proxdata = [
  {
    id: 4,
    text: "Proximity to local attraction",
    backEndName: "proxemity_to_local_attraction",
    is_selected: false,
  },
];
export const Timesharedata = [
  {
    id: 7,
    text: "Wifi",
    is_selected: true,
    backEndName: "wifi",
  },

  {
    id: 8,
    text: "Parking",
    is_selected: true,
    backEndName: "parking",
  },
  {
    id: 8,
    text: "Daily cleaning",
    is_selected: true,
    backEndName: "daily_cleaning",
  },
];
