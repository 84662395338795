import React, { useState, useEffect } from "react";
import "../Components/Payment.css";
import Modal from "react-bootstrap/Modal";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "../Schemas/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../Components/Footer";
import LoginNavbar from "../Components/LoginNavbar";
import back from "../Assets/Images/Backicon.png";
import { Backdrop, CircularProgress } from "@mui/material";

const Payment = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [add, setAdd] = useState();
  const Navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const token = JSON.parse(localStorage.getItem("login"));

  // const handleSubmit = async (event) => {
  //   const stripeid = JSON.parse(localStorage.getItem("Stripe"));
  //   event.preventDefault();
  //   console.log("click");
  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   const cardElement = elements.getElement(CardElement);
  //   const result = await stripe.createToken(cardElement);
  //   console.log(result, "dfgyusfydsgyh");
  //   console.log(result.token.id, "dfgyusfydsgyh");
  //   console.log(result.token.card.id, "dfgyusfydsgyh");

  //   if (!result.error) {
  //     setloading(true);
  //     axios({
  //       method: "post",
  //       url: "/add_card",
  //       data: {
  //         card_id: result.token.card.id,
  //         card_token: result.token.id,
  //       },
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: token.data.token.token,
  //       },
  //     })
  //       .then((res) => res.data)
  //       .then((data) => {
  //         setloading(false);
  //         // console.log(data, "wxsxccvvv");
  //         // setAdd(data);
  //         if (data.status === 0) {
  //           toast.error(data.message, {
  //             position: "top-right",
  //             theme: "colored",
  //           });
  //         } else if (data.status === 1) {
  //           toast.success(data.message, {
  //             position: "top-right",
  //             theme: "colored",
  //           });
  //         }
  //         setloading(false);
  //         if (data.status === 1) {
  //           Navigate("/AddPaymentmethods");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setloading(false);
  //       });
  //   }
  // };

  // const fakecard = async (event) => {
  //   console.log("click");
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }
  //   setloading(true);

  //   const cardElement = elements.getElement(CardElement);
  //   const result = await stripe.createToken(cardElement);
  //   console.log(result, "result");

  //   if (result && result.token) {
  //     console.log(result.token.id, "token");
  //     console.log(result.token.card.id, "card id");

  //     try {
  //       const response = await axios.post(
  //         "/add_card",
  //         {
  //           card_id: result.token.card.id,
  //           card_token: result.token.id,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `${token?.data?.token?.token}`,
  //           },
  //         }
  //       );
  //       console.log(response, "response");
  //       if (response.data.status === 1) {
  //         toast.success(response.data.message, {
  //           position: "top-right",
  //           theme: "colored",
  //         });
  //         Navigate("/AddPaymentmethods");
  //       } else if (response.data.status === 0) {
  //         toast.error(response.data.message, {
  //           position: "top-right",
  //           theme: "colored",
  //         });
  //       }
  //       setloading(false);
  //     } catch (error) {
  //       toast.error(error.message, {
  //         position: "top-right",
  //         theme: "colored",
  //       });
  //       setloading(false);
  //       console.log(error, "error");
  //     }
  //   } else {
  //     setloading(false);
  //   }
  // };
  const fakecard = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setloading(true);

    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);

    if (result.error) {
      // Handle Stripe errors
      toast.error(result.error.message, {
        position: "top-right",
        theme: "colored",
      });
      setloading(false);
      return;
    }

    try {
      const response = await axios.post(
        "/add_card",
        {
          card_id: result.token.card.id,
          card_token: result.token.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token?.data?.token?.token}`,
          },
        }
      );
      // console.log(response, "response");
      if (response.data.status === 1) {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
        Navigate("/AddPaymentmethods");
      } else if (response.data.status === 0) {
        toast.error(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      setloading(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        theme: "colored",
      });
      setloading(false);
      // console.log(error, "error");
    }
  };

  return (
    <>
      {/* <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      > */}
      {/* <Modal.Header closeButton>
            
          </Modal.Header> */}
      <LoginNavbar />
      <div>
        <span className="backbtn" onClick={() => Navigate(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <form id="payment-form">
        <div className="Payment-card-main-divv-ppx">
          <div className="Payment-ppx-cvv-hh">
            <span className="spandivv-ppx-payment">Add Card</span>
          </div>

          <div className="Payment-divv-section">
            <div className="Payment-divv-sec-mid">
              <div className="stripe-payment-home-divv">
                <CardElement options={{ hidePostalCode: true }} />
              </div>
            </div>
          </div>

          <div className="Button-main-divv-pay">
            <div className="btnn-divv-class-first">
              <button
                type="submit"
                className="btn-pay-stripe-main"
                onClick={fakecard}
              >
                Save Card
              </button>
            </div>
          </div>
        </div>
        {!loading && <div></div>}
        {loading && (
          <div>
            <Backdrop
              sx={{
                color: "#9c04f5",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
            // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </form>
      {/* </Modal> */}
      <Footer />
    </>
  );
};
export default Payment;
