import * as Yup from "yup";

export const SingUpSchemas = Yup.object({
  email: Yup.string().email().required("Please Enter Your Email"),
  password: Yup.string().min(4).required("Please Enter Your Password "),
});

export const OnSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  // taxpayerid: Yup.string().min(2).max(25).required("Please Enter Tax Payer Id"),
  // taxpayer: Yup.string().min(2).max(25).required("Please Enter Tax Payer Name"),

  email: Yup.string().email().required("Please Enter Your Email"),
  password: Yup.string().min(4).required("Please Enter Your Password "),
  Mobile: Yup.number().required("Please Enter Your Mobile Number"),
  tums: Yup.boolean().oneOf([true], "I agree to the terms and conditions"),
  Privacy: Yup.boolean().oneOf([true], "I agree to the terms and conditions"),
});

export const OffSchemas = Yup.object({
  email: Yup.string().email().required("Please Enter Your Email"),
});

export const OutSchemas = Yup.object({
  CurrentPassword: Yup.string()
    .min(4)
    .required("Please Enter  Current Password"),
  NewPassword: Yup.string().min(4).required("Please Enter Your Password"),
  ConfirmNewPassword: Yup.string()
    .required("Please Enter Your ConfirmNewPassword")
    .oneOf([Yup.ref("NewPassword"), null], "Password Must Match"),
});

export const intSchemas = Yup.object({
  text: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  number: Yup.number().required("Please Enter Your Number"),
});

export const HiSchemas = Yup.object({
  identity_type: Yup.string().required("Please Enter Your Id"),
});
export const ortSchemas = Yup.object({
  text: Yup.string().min(2).max(25).required("Please Enter Your Name on Card"),
  Cardnumber: Yup.number().max(16).required("Please Enter Your Card Number"),
});
export const OkSchemas = Yup.object({
  title: Yup.string().required("Required field can't be empty "),
  // address: Yup.string().required("Required field can't be empty "),
  city: Yup.string().required("Required field can't be empty "),
  category: Yup.string().required("Required field can't be empty "),
  state: Yup.string().required("Required field can't be empty "),
  // zipcode: Yup.string().required("Required field can't be empty "),
  // guest_space: Yup.string().required("Required field can't be empty "),
  // bedrooms: Yup.string().required("Required field can't be empty "),
  // beds: Yup.string().required("Required field can't be empty "),
  // Bathrooms: Yup.string().required("Required field can't be empty "),
  // message: Yup.string().required("Required field can't be empty "),
  //  price: Yup.number().max(99999).required("Required field can't be empty "),
  //  price: Yup.string()
  //   .matches(
  //     /^(?!0\.00)([1-9]\d{0,4}|0)(\.\d{1,2})?$/,
  //     "Price value must be in this format 12345.78 and should be between 1 and 99,999.99 "
  //   )
  //   .required("Required field can't be empty "),
  // type_of_property: Yup.string().required("Required field can't be empty "),
  // type_of_place: Yup.string().required("Required field can't be empty "),
  Neighborhood: Yup.string().required("Required field can't be empty "),
  Property: Yup.string().required("Required field can't be empty "),
  policy: Yup.string().required("Please select Cancellation Policy"),
  sizeunety: Yup.string().required("Required field can't be empty "),
  Size: Yup.string().max(5).required("Required field can't be empty "),
  Maximum: Yup.string().required("Required field can't be empty "),
  Maximumc: Yup.string().required("Required field can't be empty "),
  // fname: Yup.string().required("Required field can't be empty "),
  // cleaning: Yup.string()
  //   .matches(
  //     /^(?!0\.00)([1-9]\d{0,4}|0)(\.\d{1,2})?$/,
  //     "Price value must be in this format 12345.78 and should be between 1 and 99,999.99 "
  //   )
  //   .required("Required field can't be empty "),
});
export const PauseAddSchemas = Yup.object({
  title: Yup.string().required("Required field can't be empty "),
  // address: Yup.string().required("Required field can't be empty "),
  city: Yup.string().required("Required field can't be empty "),
  category: Yup.string().required("Required field can't be empty "),
  state: Yup.string().required("Required field can't be empty "),
  // zipcode: Yup.string().required("Required field can't be empty "),
  // guest_space: Yup.string().required("Required field can't be empty "),
  // bedrooms: Yup.string().required("Required field can't be empty "),
  // beds: Yup.string().required("Required field can't be empty "),
  // Bathrooms: Yup.string().required("Required field can't be empty "),
  // message: Yup.string().required("Required field can't be empty "),
  price: Yup.number().max(99999).required("Required field can't be empty "),
  //  price: Yup.string()
  //   .matches(
  //     /^(?!0\.00)([1-9]\d{0,4}|0)(\.\d{1,2})?$/,
  //     "Price value must be in this format 12345.78 and should be between 1 and 99,999.99 "
  //   )
  //   .required("Required field can't be empty "),
  // type_of_property: Yup.string().required("Required field can't be empty "),
  // type_of_place: Yup.string().required("Required field can't be empty "),
  Neighborhood: Yup.string().required("Required field can't be empty "),
  Property: Yup.string().required("Required field can't be empty "),
  policy: Yup.string().required("Please select Cancellation Policy"),
  sizeunety: Yup.string().required("Required field can't be empty "),
  Size: Yup.number().max(99999).required("Required field can't be empty "),
  Maximum: Yup.number().required("Required field can't be empty "),
  Maximumc: Yup.number().required("Required field can't be empty "),
  // fname: Yup.string().required("Required field can't be empty "),
  // cleaning: Yup.string()
  //   .matches(
  //     /^(?!0\.00)([1-9]\d{0,4}|0)(\.\d{1,2})?$/,
  //     "Price value must be in this format 12345.78 and should be between 1 and 99,999.99 "
  //   )
  //   .required("Required field can't be empty "),
});

export const EditSchemas = Yup.object({
  title: Yup.string().required("Required field can't be empty "),
  // address: Yup.string().required("Required field can't be empty "),
  city: Yup.string().required("Required field can't be empty "),
  category: Yup.string().required("Required field can't be empty "),
  state: Yup.string().required("Required field can't be empty "),
  // zipcode: Yup.string().required("Required field can't be empty "),
  // guest_space: Yup.string().required("Required field can't be empty "),
  // bedrooms: Yup.string().required("Required field can't be empty "),
  // beds: Yup.string().required("Required field can't be empty "),
  // Bathrooms: Yup.string().required("Required field can't be empty "),
  // message: Yup.string().required("Required field can't be empty "),
  price: Yup.number().max(99999).required("Required field can't be empty "),
  //  price: Yup.string()
  //   .matches(
  //     /^(?!0\.00)([1-9]\d{0,4}|0)(\.\d{1,2})?$/,
  //     "Price value must be in this format 12345.78 and should be between 1 and 99,999.99 "
  //   )
  //   .required("Required field can't be empty "),
  // type_of_property: Yup.string().required("Required field can't be empty "),
  // type_of_place: Yup.string().required("Required field can't be empty "),
  Neighborhood: Yup.string().required("Required field can't be empty "),
  Property: Yup.string().required("Required field can't be empty "),
  // policy: Yup.string().required("Please select Cancellation Policy"),
  sizeunety: Yup.string().required("Required field can't be empty "),
  Size: Yup.string().max(5).required("Required field can't be empty "),
  Maximum: Yup.string().required("Required field can't be empty "),
  Maximumc: Yup.string().required("Required field can't be empty "),
  // fname: Yup.string().required("Required field can't be empty "),
  // cleaning: Yup.string()
  //   .matches(
  //     /^(?!0\.00)([1-9]\d{0,4}|0)(\.\d{1,2})?$/,
  //     "Price value must be in this format 12345.78 and should be between 1 and 99,999.99 "
  //   )
  //   .required("Required field can't be empty "),
});
export const HelloSchemas = Yup.object({
  bedrooms: Yup.string().required("Required field can't be empty"),
  bathrooms: Yup.string().required("Required field can't be empty"),
});
export const PauseSchemas = Yup.object({
  title: Yup.string(),
  // address: Yup.string(),
  city: Yup.string(),
  category: Yup.string(),
  state: Yup.string(),
  // zipcode: Yup.string(),
  // guest_space: Yup.string(),
  bedrooms: Yup.string(),
  beds: Yup.string(),
  Bathrooms: Yup.string(),
  // message: Yup.string(),
  price: Yup.string().max(5),
  // type_of_property: Yup.string(),
  // type_of_place: Yup.string(),
  Neighborhood: Yup.string(),
  Property: Yup.string(),
  policy: Yup.string(),
  sizeunety: Yup.string(),
  Size: Yup.string().max(5),
  // fname: Yup.string(),
});
export const SearchSchemas = Yup.object({
  // Search: Yup.string().required("Please Enter Your destination "),
  checkInDate: Yup.string().required("Please Enter Your checkInDate "),
  checkout: Yup.string().required("Please Enter Your checkInDate "),
});

export const CohostSchemas = Yup.object({
  referralid: Yup.string().required("Please Enter Your id"),
});
export const BankSchemas = Yup.object({
  Bankname: Yup.string().required("Please Enter Your bank name"),
  Accountnumber: Yup.number().required("Please Enter Your account number"),
  Beneficiaryname: Yup.string().required("Please Enter Your beneficiary name"),
});
