import React, { useState, useEffect } from "react";
import "../Components/ApplyforTimeshare.css";
import {
  Timesharedata,
  Safetydata,
  proxdata,
  Decordata,
  Comfortdata,
} from "./DataofTimeshare";
import { useFormik } from "formik";
import { HelloSchemas } from "../Schemas";
import axios from "../Schemas/Api";
import LoginNavbar from "./LoginNavbar";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import Mal from "../Assets/Images/Maltepalplus.png";

const initialValues = {
  beds: "",
  bedrooms: "",
  safety: "",
  proximity: "",
};
const ApplyforTimeshare = () => {
  // const id = localStorage.getItem("Propid");
  const token = JSON.parse(localStorage.getItem("login"));
  const [CheckBoxes, setCheckBoxes] = useState(Timesharedata);
  // console.log(CheckBoxes, "fffffffff");
  const [safetycheck, setSafetycheck] = useState(Safetydata);
  const [decorcheck, setDecorcheck] = useState(Decordata);
  const [proxcheck, setProxcheck] = useState(proxdata);
  const [comfortcheck, setComfortcheck] = useState(Comfortdata);
  const [checkboxdata, setcheckboxdata] = useState(false);
  const [comfort, setComfort] = useState(false);
  const [proximity, setProximity] = useState(false);
  const [Decor, setDecor] = useState(false);
  const [safety, setSafety] = useState(false);
  const [loading, setloading] = useState(false);
  const [images, setImages] = useState([]);
  const [img, setImgs] = useState([]);
  const [imageCount, setImageCount] = useState(0);
  const [amenities, setAmenities] = useState([]);
  const [freecleaning, setFreecleaning] = useState([]);
  // console.log(amenities);
  const [imgCount, setImgCount] = useState(0);

  const handleImageChange = (event) => {
    // setImages(event.target.files[0])
    const selectedImages = Array.from(event.target.files);

    if (imageCount + selectedImages.length <= 5) {
      setImages([...images, ...selectedImages]);
      setImageCount(imageCount + selectedImages.length);
    } else {
      alert(
        `You can only select a maximum of 5 images. You tried to select ${selectedImages.length} image.`
      );
    }
  };
  const handleImgChange = (e) => {
    // setImages(event.target.files[0])
    const selectedImages = Array.from(e.target.files);

    if (imgCount + selectedImages.length <= 5) {
      setImgs([...img, ...selectedImages]);
      setImgCount(imgCount + selectedImages.length);
    } else {
      alert(
        `You can only select a maximum of 5 images. You tried to select ${selectedImages.length} image.`
      );
    }
  };
  const handimgleDelete = (id) => {
    setImages(images.filter((image, i) => i !== id));
    setImageCount(imageCount - 1);
  };
  const handimgDelete = (index) => {
    setImgs(img.filter((image, i) => i !== index));
    setImgCount(imgCount - 1);
  };
  const navigate = useNavigate();

  let route = useLocation();
  const getitem = route.state.id;

  // console.log(getitem, "bgfdftd");

  const handlechange = (event) => {
    setcheckboxdata(event.target.checked);
    // console.log(event.target.checked);
  };

  const onSubmit = async () => {
    const formdata = new FormData();
    if (checkboxdata)
      Timesharedata.map((item) => {
        formdata.append(item.backEndName, item.isSelected === true ? 1 : 0);
      });
    proxdata.map((item) => {
      formdata.append(item.backEndName, item.isSelected === true ? 1 : 0);
    });
    Safetydata.map((item) => {
      formdata.append(item.backEndName, item.isSelected === true ? 1 : 0);
    });
    Decordata.map((item) => {
      formdata.append(item.backEndName, item.isSelected === true ? 1 : 0);
    });
    Comfortdata.map((item) => {
      formdata.append(item.backEndName, item.isSelected === true ? 1 : 0);
    });

    formdata.append("safety_detail", values.safety);
    formdata.append("proxemity_detail", values.proximity);
    formdata.append("number_of_bedrooms", values.beds);
    formdata.append("number_of_bathrooms", values.bedrooms);
    formdata.append("property_id", getitem.images[0].property_id);

    for (let i = 0; i < img.length; i++) {
      formdata.append("comfort_images", images[i]);
    }
    for (let i = 0; i < images.length; i++) {
      formdata.append("decor_images", images[i]);
    }

    try {
      const timesshare = {
        url: "/list_for_timeshare",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token.data.token.token}`,
        },
      };
      axios(timesshare)
        .then((res) => res.data)
        .then((response) => {
          // console.log(response);
          if (response.status === 0) {
            toast.error(response.message, {
              position: "top-right",
              theme: "colored",
            });
          } else if (response.status === 1) {
            toast.success(response.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          setloading(false);
          if (response.status === 1) {
            // navigate("/MyProfile/MyProperty");
          }
        })
        .catch((error) => {
          setloading(false);
          toast.error(error, {
            position: "top-right",
            theme: "colored",
          });
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };
  // console.log(getitem.images[0].property_id, "hhhhhhhhhhh");

  const getdetails = async () => {
    try {
      const timesshare = {
        url: "/property_detail_for_timeshare",
        method: "Post",
        data: {
          property_id: getitem.images[0].property_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token.data.token.token}`,
        },
      };
      axios(timesshare)
        .then((res) => res.data)
        .then((response) => {
          // console.log(response.data, "dtftteftefteftfteft");
          setAmenities(response.data);
          setFreecleaning(response.data.aminities);
        })
        .catch((error) => {});
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };
  useEffect(() => {
    getdetails();
  }, []);
  // console.log(getdetails, "sgdsgdgds");
  // console.log(getitem.images[0].property_id, "sadfs......");
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  useEffect(() => {
    setFieldValue("beds", amenities.beds);
    setFieldValue("bedrooms", amenities.bedrooms);
    // console.log(token.token);
  }, [amenities]);

  return (
    <>
      <LoginNavbar />
      <form onSubmit={handleSubmit}>
        <div className="Apply-for-Time-MAin-divv">
          {loading ? (
            <div className="loding-rolar">
              <CircularProgress />
            </div>
          ) : (
            <div className="Apply-for-Time-section-two">
              <div className="Timeshare-check-box-divv">
                <span className="Check-boxx-apply-divv">
                  Apply to join timeshare club
                </span>
              </div>
              <div className="Check-boxxes-seven-divv">
                <div className="Chexboxx-here-stored">
                  <div>
                    {comfort ? (
                      <div className="add-delet-option">
                        <label className="Ht-ml-for" htmlFor={"comfort-mal"}>
                          {" "}
                          <img className="mal-te-img" src={Mal} alt="" />
                        </label>
                        <input
                          id="comfort-mal"
                          type="file"
                          multiple
                          onChange={handleImgChange}
                          className="Malte-pal-cls"
                        />

                        <div className="overflowing-add-immgg">
                          {img?.map((image, index) => {
                            let imgURL = URL.createObjectURL(image);
                            return (
                              <div key={index} className="sab-img-ka-div">
                                <img
                                  src={imgURL}
                                  alt={`Image-${index}`}
                                  className="add-kiya-huva-pic"
                                  style={{
                                    width: "79.71px",
                                    height: "79.71px",
                                  }}
                                />
                                <button
                                  className="Btn-dada-delet"
                                  onClick={() => handimgDelete(index)}
                                >
                                  <svg
                                    className="Ma-svg-top"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_25_3172)">
                                      <path
                                        d="M7.0014 6.17516L9.8889 3.28766L10.7137 4.11249L7.82623 6.99999L10.7137 9.88749L9.8889 10.7123L7.0014 7.82483L4.1139 10.7123L3.28906 9.88749L6.17656 6.99999L3.28906 4.11249L4.1139 3.28766L7.0014 6.17516Z"
                                        fill="#211B24"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_25_3172">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {comfortcheck.map((object, index) => {
                    return (
                      <div key={index} className="Boxxes-main-items mt-3">
                        <span className="box-check-spann">{object.text}</span>
                        <input
                          className="check-box-input-divv"
                          type="checkbox"
                          id={object.id}
                          onChange={handlechange}
                          disabled={amenities.is_comfort ? false : true}
                          onClick={() => {
                            if (object.id === 1) {
                              setComfort(!comfort);
                            }
                            setComfortcheck((preval) => {
                              let temp = [...preval];
                              let temp2 = temp[index];
                              temp2 = {
                                ...temp2,
                                isSelected: !temp2.isSelected,
                              };
                              temp[index] = temp2;
                              return temp;
                            });
                          }}
                          checked={object.isSelected ? true : false}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="Chexboxx-here-stored">
                  <div>
                    {Decor ? (
                      <div className="add-delet-option">
                        <label className="Ht-ml-for" htmlFor={"decor-mal"}>
                          {" "}
                          <img className="mal-te-img" src={Mal} alt="" />
                        </label>
                        <input
                          id="decor-mal"
                          type="file"
                          multiple
                          onChange={handleImageChange}
                          className="Malte-pal-cls"
                        />
                        {/* <div>
                         <img src={Mal} multiple onChange={handleImageChange} alt="" />
                       </div> */}
                        <div className="overflowing-add-immgg">
                          {images?.map((img, index) => {
                            let imgURL = URL.createObjectURL(img);
                            return (
                              <div key={index} className="sab-img-ka-div">
                                <img
                                  src={imgURL}
                                  alt={`Image-${index}`}
                                  className="add-kiya-huva-pic"
                                  style={{
                                    width: "79.71px",
                                    height: "79.71px",
                                  }}
                                />
                                <button
                                  className="Btn-dada-delet"
                                  onClick={() => handimgleDelete(index)}
                                >
                                  <svg
                                    className="Ma-svg-top"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_25_3172)">
                                      <path
                                        d="M7.0014 6.17516L9.8889 3.28766L10.7137 4.11249L7.82623 6.99999L10.7137 9.88749L9.8889 10.7123L7.0014 7.82483L4.1139 10.7123L3.28906 9.88749L6.17656 6.99999L3.28906 4.11249L4.1139 3.28766L7.0014 6.17516Z"
                                        fill="#211B24"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_25_3172">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {decorcheck.map((object, index) => {
                    return (
                      <div key={index} className="Boxxes-main-items">
                        <span className="box-check-spann">{object.text}</span>
                        <input
                          className="check-box-input-divv"
                          type="checkbox"
                          id={object.id}
                          onChange={handlechange}
                          onClick={() => {
                            if (object.id === 2) {
                              setDecor(!Decor);
                            }
                            setDecorcheck((preval) => {
                              let temp = [...preval];
                              let temp2 = temp[index];
                              temp2 = {
                                ...temp2,
                                isSelected: !temp2.isSelected,
                              };
                              temp[index] = temp2;
                              return temp;
                            });
                          }}
                          checked={object.isSelected ? true : false}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="Chexboxx-here-stored">
                  {safetycheck.map((object, index) => {
                    return (
                      <div key={index} className="Boxxes-main-items">
                        <span className="box-check-spann">{object.text}</span>
                        <input
                          className="check-box-input-divv"
                          type="checkbox"
                          id={object.id}
                          onChange={handlechange}
                          onClick={() => {
                            if (object.id === 3) {
                              setSafety(!safety);
                            }
                            setSafetycheck((preval) => {
                              let temp = [...preval];
                              let temp2 = temp[index];
                              temp2 = {
                                ...temp2,
                                isSelected: !temp2.isSelected,
                              };
                              temp[index] = temp2;
                              return temp;
                            });
                          }}
                          checked={object.isSelected ? true : false}
                        />
                      </div>
                    );
                  })}
                  <div>
                    {safety ? (
                      <input
                        className="othars-input-txt"
                        type="text"
                        name="safety"
                        value={values.safety}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="Chexboxx-here-stored">
                  {proxcheck.map((object, index) => {
                    return (
                      <div key={index} className="Boxxes-main-items">
                        <span className="box-check-spann">{object.text}</span>
                        <input
                          className="check-box-input-divv"
                          type="checkbox"
                          id={object.id}
                          onChange={handlechange}
                          onClick={() => {
                            if (object.id === 4) {
                              setProximity(!proximity);
                            }
                            setProxcheck((preval) => {
                              let temp = [...preval];
                              let temp2 = temp[index];
                              temp2 = {
                                ...temp2,
                                isSelected: !temp2.isSelected,
                              };
                              temp[index] = temp2;
                              return temp;
                            });
                          }}
                          checked={object.isSelected ? true : false}
                        />
                      </div>
                    );
                  })}
                  <div>
                    {proximity ? (
                      <input
                        className="othars-input-txt"
                        type="text"
                        name="proximity"
                        value={values.proximity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="Chexboxx-here-stored">
                  {amenities &&
                    amenities?.aminities?.map((item, i) => {
                      return (
                        <div key={i} className="Boxxes-main-items">
                          <span className="box-check-spann">
                            {item.display_name}
                          </span>
                          <input
                            className="check-box-input-divv"
                            type="checkbox"
                            id={item.id}
                            onChange={handlechange}
                            disabled={true}
                            checked={item.is_selected == true ? true : false}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="flex-kar-dono-ko-divv">
                <div className="Beds-room-select-item">
                  <span>Beds</span>
                  <div className="Beds-items-thard-section">
                    <input
                      type="text"
                      name="beds"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.beds}
                      disabled={true}
                      className="open-beds-selact-tag"
                    ></input>
                  </div>
                </div>
                <div className="Beds-room-select-item">
                  <span>Bedrooms</span>
                  <div className="Beds-items-thard-section">
                    <input
                      type="text"
                      name="bedrooms"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bedrooms}
                      disabled={true}
                      className="open-beds-selact-tag"
                    />
                  </div>
                </div>
              </div>
              <div className="apply-btnn-divv-time">
                <button type="submit" className="btn-apply-to-submit-form">
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ApplyforTimeshare;
