import React from "react";
import "../Components/Verify.css";
import sedo from "../Assets/Images/Sedo.png";
import Bangloz from "../Assets/Images/BanglozHome.png";
import Very from "../Assets/Images/VeryImg.png";
import Boolpan from "../Assets/Images/Boolpan.png";
import Otp from "./Otp";
const Verify = () => {
  return (
    <>
      <section className="Acoount-main-sec">
        <div className="Acount-main-div">
          <div className="img-div-p">
            <img className="girl-img-main" src={Bangloz} alt="" />
          </div>
          <div className="sedo-div-2">
            <img className="girl-img-main" src={sedo} alt="" />
          </div>
          <div className="span-div-p">
            <span className="find-tha-span">Safe online payments</span>
            <div className="mt-3">
              <span className="perfect-span">
                Find the perfect home for your holiday accommodation
              </span>
            </div>
          </div>
          <div className="back-div">
            <span className="Back-span">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_18_833)">
                  <path
                    d="M7.828 10.9999H20V12.9999H7.828L13.192 18.3639L11.778 19.7779L4 11.9999L11.778 4.22192L13.192 5.63592L7.828 10.9999Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_18_833">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Back
            </span>
            <div className="i-m-g-div">
              <img className="Login-logo-img" src={Very} alt="" />

              <div className="mt-2">
                <span className="l-o-span">Verify</span>
              </div>
              <Otp />
              <div className="mt-3">
                <span className="mobile-otp-span">
                  Enter OTP Code sent to your mobile number
                </span>
              </div>
              <div>
                <span className="numbar-span">+1 98980 98980</span>
                <img src={Boolpan} alt="" />
              </div>
              <div className="btn-margin">
                <button className="Lo-btn-1">Verify</button>
              </div>
              <div className="d-r-main-div mt-4">
                <span className="dont-span">Resend Code</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Verify;
