import React, { useState, useEffect } from "react";
import "../Components/Jointimeshare.css";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

const Jointimeshare = () => {
  const [conproperty, setConproperty] = useState([]);
  const [wishlistdata, setwishlistdata] = useState([]);
  const [propertyadd, setPropertyadd] = useState([]);
  const [loading, setloading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("login"));
  const GetTimelist = () => {
    setLoading(true);
    axios({
      method: "get",
      url: "/timeshare_properties",
      headers: {
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        // console.log("🚀 ~ file: Jointimeshare.js:34 ~ .then ~ data:", data);
        setConproperty(data.properties);
        if (data.status == 0) {
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };
  useEffect(() => {
    GetTimelist();
  }, []);
  // console.log(conproperty[0].property_data.wishlist.property_id);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(() => {});
  }, []);
  // console.log(conproperty);
  const wishlist = async (id) => {
    const data = {
      property_id: conproperty[0].property_data.wishlist.property_id,
    };
    // console.log(data, "hhhhhhhhhhhh");
    try {
      const res = await axios.post(
        "/wishlist",
        {
          property_id: data.property_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token.data.token.token}`,
          },
        }
      );
      // console.log(res);
      if (res.data.status === 1) {
        GetTimelist();
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (res.data.status === 0) {
        GetTimelist();
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const handledetail = async (id) => {
    navigate("/Requestfortimeshare", {
      state: {
        id,
      },
    });
  };
  const Popup = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Property-card-divv-popup">
            <div className="Property-ppx-pop mt-2">
              <span className="spandivv-ppx-property-ook">
                Please note that your timeshare allowance is limited to a
                maximum of 7 days. Should you wish to stay for less then 7 days,
                please inform your host.<br></br>
                <a href="https://homevr.online/Timesharepoolstarranking">
                  Please conduct timeshare rules
                </a>
              </span>
            </div>

            <div className="Button-divv-property">
              <div className="btnn-divv-pro-popup">
                <button
                  onClick={() => handledetail(conproperty)}
                  className="btn-pro-later-popup"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };
  const ico = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2257)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z"
          fill="#9C04F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2257">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const ico2 = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2238)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2238">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      <Popup show={show} onHide={() => setShow(false)} />
      <section className="MyProperty-main-section">
        <div className="MyProperty-main-div">
          <div className="my-prop-and-add">
            <span className="My-prop">Timeshare</span>
          </div>

          {!Loading && conproperty?.length >= 1 ? (
            <div className="map-upar-div">
              {conproperty?.map((curelem, index) => {
                return (
                  <div key={index} className="mainnn-divvv-dusri-bar">
                    <div className="api-imgis">
                      <img
                        className="Prop-home-1"
                        src={
                          curelem.images[0]
                            ? curelem.images[0].picture_name
                            : null
                        }
                        alt="images"
                      />
                    </div>

                    <div className="d-flex">
                      <span className="Grandview-span-1">
                        {curelem.property_data.address}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="Accessible-span">{curelem.Detail}</span>
                    </div>
                    <div className="mt-3 span-naghit-div-main">
                      <div>
                        <span className="night-span">
                          ${curelem.property_data.price_per_night}
                        </span>
                        <span className="night-span-2"> / night</span>
                      </div>
                      <div className="svg-map-khud">
                        <div
                          onClick={() =>
                            wishlist(curelem.property_data.wishlist.id)
                          }
                        >
                          {curelem.property_data.wishlist.wishlist === true ? (
                            <span>{ico}</span>
                          ) : curelem.property_data.wishlist.wishlist ===
                            false ? (
                            <span>{ico2}</span>
                          ) : (
                            <span>{ico2}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="btn-na-mate-mhnat">
                      <div>
                        <button
                          className="btn-similar"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          Book your Timeshare
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No properties found.</div>
          )}
        </div>
      </section>
      {Loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Jointimeshare;
