import React from "react";
import "../Components/HowItWorks.css";
import houseimg from "../Assets/Images/houseimg.png";
import brothers from "../Assets/Images/brothersimg.png";
import kyeimg from "../Assets/Images/keyimg.png";
import SerachaBar from "../Components/SerachaBar";
import { motion } from "framer-motion";
const HowItWorks = () => {
  return (
    <>
      <section className="HowItWorks-main-section">
        <div className="HowItWorks-main-div">
          <div className="How-it-Works-div-1">
            <motion.span
              initial={{ scale: 0.8, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="How-it-Works-span">How it Works</motion.span>
          </div>
          <div className="alll-mainn-divv">
            <div>
              <div className="card-main-div-3">
                <motion.img
                  initial={{ scale: 0.8, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="houseimg-img-1" src={houseimg} alt="" />
                <motion.div
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  className="Duis-main-div">
                  <span className="Duis-efficitur-span">For travellers</span>
                </motion.div>
                <motion.div
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  className=" Vivamus-et-consequat-span">
                  <span className="Vestibulum-span">
                    Download the App and register.Seach for your preferred
                    accommodation. Confirm and pay for your booking. Done!
                  </span>
                </motion.div>
              </div>
            </div>
            <div>
              <div className="card-main-div-3">
                <motion.img
                  initial={{ scale: 0.8, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}

                  className="houseimg-img-1" src={brothers} alt="" />
                <motion.div
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  className="Duis-main-div">
                  <span className="Duis-efficitur-span">
                    Hosts free timeshare
                  </span>
                </motion.div>
                <motion.div
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  className=" Vivamus-et-consequat-span">
                  <span className="Vestibulum-span">
                    Apply to add your listed property to the hosts timeshare
                    pool.Once your property is accepted and graded, agree to the
                    timeshare rules.Book your free accommodation and enjoy!
                  </span>
                </motion.div>
              </div>
            </div>
            <div>
              <div className="card-main-div-3">
                <motion.img
                  initial={{ scale: 0.1, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="houseimg-img-1" src={kyeimg} alt="" />
                <motion.div
                 initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                 className="Duis-main-div">
                  <span className="Duis-efficitur-span">For hosts</span>
                </motion.div>
                <motion.div
                   initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                 className=" Vivamus-et-consequat-span">
                  <span className="Vestibulum-span">
                    Download the App and register.List your property/space
                    according to the terms best suited to you.Sit back and let
                    us direct your guests to you.
                  </span>
                </motion.div>
              </div>
            </div>
          </div>
          <div className="How-it-Works-div-2">
            <button className="Get-Started-butn">Get Started</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
