import React, { useRef, useState, useEffect } from "react";
import "../Components/NearYou.css";
import home2 from "../Assets/Images/Banglozimg.png";
import home3 from "../Assets/Images/Bangloz2.png";
import home4 from "../Assets/Images/Bangloz3.png";
import home5 from "../Assets/Images/Bangloz4.png";
import home6 from "../Assets/Images/Bangloz5.png";
import Laft from "../Assets/Images/Lafticon.png";
import raghit from "../Assets/Images/RaghitIcon.png";
import { CircularProgress } from "@mui/material";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import "../Components/Style.css";
// import required modules
import { Pagination } from "swiper";

const NearYou = () => {
  const btnref = useRef(null);

  const deta = [
    {
      Bangloz: home2,
      star: "4.2",
      Location: "Grandview, Texas, US",
      Price: "$202",
      Detail: "Accessible parking spot and more",
    },
    {
      Bangloz: home3,
      star: "4.2",
      Location: "Grandview, Texas, US",
      Price: "$202",
      Detail: "Accessible parking spot and more",
    },
    {
      Bangloz: home4,
      star: "4.0",
      Location: "Grandview, Texas, US",
      Price: "$202",
      Detail: "Accessible parking spot and more",
    },
    {
      Bangloz: home5,
      star: "3.8",
      Location: "Grandview, Texas, US",
      Price: "$202",
      Detail: "Accessible parking spot and more",
    },
    {
      Bangloz: home6,
      star: "3.8",
      Location: "Grandview, Texas, US",
      Price: "$202",
      Detail: "Accessible parking spot and more",
    },
  ];
  // const [Getcategory, setGetcategory] = useState([]);

  return (
    <>
      <section className="NearYou-main-section">
        <div className="NearYou-main-div">
          <div className="margin-dena-ka-liya"></div>
          <div className="Near-You-img-div-span">
            <div className="d-flex">
              <span className="Popular-span">Near Me</span>
            </div>
            <div className="laft-raghit-img-div">
              <span
                className=""
                onClick={() => btnref.current.swiper.slidePrev()}
              >
                <img className="Laft-img" src={Laft} alt="" />
              </span>
              <span
                className=""
                onClick={() => btnref.current.swiper.slideNext()}
              >
                <img className="raghit-img" src={raghit} alt="" />
              </span>
            </div>
          </div>
          <Swiper
            ref={btnref}
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
            loop={true}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 10,
              },

              "@0.90": {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              // "@1.50": {
              //   slidesPerView: 4,
              //   spaceBetween: 10,
              // },
            }}
          >
            {deta.map((curelem, index) => {
              return (
                <SwiperSlide className="ronny-p" key={index}>
                  {" "}
                  <div className="mainnn-divvv-svg">
                    <img
                      className="bangloz-img-1"
                      src={curelem.Bangloz}
                      alt=""
                    />
                    <div className="svg-span-div-main">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2_446)">
                          <path
                            d="M7 9.91671L3.57117 12.0109L4.50333 8.10254L1.4525 5.48921L5.45708 5.16837L7 1.45837L8.54292 5.16837L12.5481 5.48921L9.49667 8.10254L10.4288 12.0109L7 9.91671Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2_446">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>{curelem.star}</span>
                    </div>
                    <div className="d-flex">
                      <span className="Grandview-span-1">
                        {curelem.Location}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="Accessible-span">{curelem.Detail}</span>
                    </div>
                    <div className="mt-3 span-naghit-div-main">
                      <div>
                        <span className="night-span">{curelem.Price} </span>
                        <span className="night-span-2">/ night</span>
                      </div>
                      <div>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2_442)">
                            <path
                              d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
                              fill="#211B24"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2_442">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default NearYou;
