import React, { useState } from "react";
import "../Components/SerachaBar.css";
import Line from "../Assets/Images/SerachaBarLine.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Datep from "../Assets/Images/Tarik.png";
const SerachaBar = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      <section className="SerachaBar-main-section">
        <div className="SerachaBar-main-div">
          <div className="section-thard-div">
            <div className="sandha-kiya-huva">
              <div className="local-and-goin-div">
                <span>Location</span>
                <div className="mt-3">
                  <span className="Going-span">Where are you going?</span>
                </div>
              </div>
              <div>
                <img className="block-non-div" src={Line} alt="" />
              </div>
              <div>
                <span>Check in</span>
                <div className="mt-2 Date-pikey-q">
                  <span className="Add-span-datey">Add Dates</span>
                  <label htmlFor="icon" className="peaky-blainder">
                    <img className="img-tarik-date" src={Datep} alt="" />

                    <DatePicker
                      id="icon"
                      showIcon
                      selected={startDate}
                      className="Date-time-out"
                      onChange={(date) => setStartDate(date)}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="displya-none-ka">
              <img className="block-non-div" src={Line} alt="" />
            </div>
            <div className="sandha-kiya-div">
              <div>
                <span>Check Out</span>
                <div className="mt-2 Date-pikey-q">
                  <span className="Add-span-datey">Add Dates</span>
                  <label htmlFor="icon" className="peaky-blainder">
                    <img className="img-tarik-date" src={Datep} alt="" />

                    <DatePicker
                      id="icon"
                      showIcon
                      selected={startDate}
                      className="Date-time-out"
                      onChange={(date) => setStartDate(date)}
                    />
                  </label>
                </div>
              </div>
              <div>
                <img className="block-non-div" src={Line} alt="" />
              </div>
              <div>
                <span>Pets</span>
                <div className="mt-4">
                  <select className="pets-select" name="cars" id="cars">
                    <option value="volvo">Cat</option>
                    <option value="saab">Dog</option>
                    <option value="opel">Rabbit</option>
                    <option value="audi">Goat</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="displya-none-ka">
              <img className="block-non-div" src={Line} alt="" />
            </div>
            <div className="sandha-kiya-div-second">
              <div>
                <span>Guest</span>
                <div className="mt-4">
                  <select className="pets-select" name="cars" id="cars">
                    <option value="volvo">Add Guest</option>
                    <option value="saab">Saab</option>
                    <option value="opel">Opel</option>
                    <option value="audi">Audi</option>
                  </select>
                </div>
              </div>

              <form class="example" action="/action_page.php">
                <button className="mnb-vcxz-okm" type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SerachaBar;
