import React, { useState, useEffect } from "react";
import "../Components/MyProperty.css";
import Prop1 from "../Assets/Images/Property1.png";
import Prop2 from "../Assets/Images/Property2.png";
import Prop3 from "../Assets/Images/Property3.png";
import Prop4 from "../Assets/Images/Property4.png";
import Prop5 from "../Assets/Images/Property5.png";
import Prop6 from "../Assets/Images/Property6.png";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

const MyProperty = () => {
  const id = JSON.parse(localStorage.getItem("Propid"));
  const [propertyadd, setPropertyadd] = useState([]);
  const [image, setimage] = useState([]);
  const token = JSON.parse(localStorage.getItem("login"));
  const [idd, setidd] = useState("");
  const [save, setSave] = useState();
  const [show, setShow] = useState(false);
  const pauseid = JSON.parse(localStorage.getItem("Pauseid"));
  const [loding, setloading] = useState(false);
  const Propertget = async () => {
    setloading(true);
    const formdata = new FormData();
    try {
      let cofe = {
        url: "/my_property",
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons);
          setloading(false);
          setimage(respons.data);
          setPropertyadd(respons.data);
        });
    } catch (error) {
      // console.log("userap...", error);
      setloading(false);
      toast.error(error.response.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    Propertget();
  }, []);
  const [Loading, setLoading] = useState(false);
  const Navigat = useNavigate();
  const onSubmit = async (id, index) => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("property_id", id);

    try {
      let AddProperty = {
        url: "/delete_property",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(AddProperty)
        .then((res) => res.data)
        .then((respons) => {
          toast.success(respons.message, {
            position: "top-right",
            theme: "colored",
          });
          // console.log(respons);
          if (respons.message === "Property removed successfully.") {
            // Propertget();
            setPropertyadd(propertyadd.filter((item, i) => i !== index));
          }
          setloading(false);
        })
        .catch((error) => {
          if (error) {
            setloading(false);
          }
        });
    } catch (error) {
      // console.log(error);
      // setloading(false);
      setloading(false);
    }
  };
  const handledetail = async (id) => {
    navigate("/ApplyforTimeshare", {
      state: {
        id,
      },
    });
  };
  // console.log(propertyadd);

  const navigate = useNavigate();
  const hendel = (id) => {
    localStorage.setItem("Propid", JSON.stringify(id));
    navigate("/EditProperty");
  };

  const move = (id) => {
    const newdata = {
      index: "2",
      id: { ...id, beBhai: id.property_data?.id },
    };
    navigate("/PropertyDetails", {
      state: {
        newdata,
      },
    });
  };
  const propid = (id) => {
    navigate("/EditProperty", {
      state: {
        id,
      },
    });
  };

  const GetSavelist = () => {
    try {
      axios({
        method: "get",
        url: "/get_pending_property_list",
        headers: {
          authorization: `${token.data.token.token}`,
        },
      })
        .then((res) => res.data)
        .then((data) => {
          setSave(data.data);
          // console.log(data);
        });
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    GetSavelist();
  }, []);

  return (
    <>
      <section className="MyProperty-main-section">
        <div className="MyProperty-main-div">
          <div className="my-prop-and-add">
            <span className="My-prop">Apply to join timeshare club</span>
          </div>

          {!Loading && propertyadd?.length >= 1 ? (
            <div className="map-upar-div">
              {propertyadd?.map((curelem, index) => {
                return (
                  <div key={index} className="mainnn-divvv-dusri-bar">
                    <div className="api-imgis" onClick={() => move(curelem)}>
                      <img
                        className="Prop-home-1"
                        src={curelem?.images[0].picture_name}
                        alt="images"
                      />
                    </div>

                    <div className="svg-span-div-main">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2_446)">
                          <path
                            d="M7 9.91671L3.57117 12.0109L4.50333 8.10254L1.4525 5.48921L5.45708 5.16837L7 1.45837L8.54292 5.16837L12.5481 5.48921L9.49667 8.10254L10.4288 12.0109L7 9.91671Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2_446">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>{curelem?.property_data.average_ratings}</span>
                    </div>
                    <div className="d-flex">
                      <span className="Grandview-span-1">
                        {curelem?.property_data.address}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="Accessible-span">{curelem?.Detail}</span>
                    </div>
                    <div className="mt-3 span-naghit-div-main">
                      <div>
                        <span className="night-span">
                          €{curelem?.property_data.price_per_night}
                        </span>
                        <span className="night-span-2"> / night</span>
                      </div>
                      <div className="svg-map-khud">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => propid(curelem)}
                        >
                          <svg
                            width="18"
                            height="20"
                            viewBox="0 0 18 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.414 14L13.556 3.85801L12.142 2.44401L2 12.586V14H3.414ZM4.243 16H0V11.757L11.435 0.322007C11.6225 0.134536 11.8768 0.0292206 12.142 0.0292206C12.4072 0.0292206 12.6615 0.134536 12.849 0.322007L15.678 3.15101C15.8655 3.33853 15.9708 3.59284 15.9708 3.85801C15.9708 4.12317 15.8655 4.37748 15.678 4.56501L4.243 16ZM0 18H18V20H0V18Z"
                              fill="#211B24"
                            />
                          </svg>
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            onSubmit(curelem?.property_data.id, index)
                          }
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z"
                              fill="#211B24"
                            />
                            {Loading ? "Loading.." : ""}
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="btn-na-mate-mhnat">
                      <div>
                        <button
                          className="btn-similar"
                          onClick={() => {
                            handledetail(curelem);
                          }}
                        >
                          Apply to join timeshare club
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div className="Not-found">No properties found.</div>{" "}
            </>
          )}
        </div>
      </section>
      {loding && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default MyProperty;
