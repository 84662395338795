import React from "react";
import { useNavigate } from "react-router-dom";

const Aboutpayment = () => {
  const Navigat = useNavigate();
  return (
    <>
      <section className="PaymentPayouts-main-section">
        <div className="PaymentPayouts-main-div">
          <div>
            <span className="pymanbt-span">Payout</span>
          </div>
          <div className="to-main-div">
            <div>
              <div className="mt-4">
                <span className="travel-span">Need Help?</span>
              </div>
              <div className="mt-4 svg-matrhord">
                <span
                  className="all-span-mathor"
                  onClick={() => Navigat("/MyProfile/transactionHistory")}
                >
                  All about Hosts payout
                </span>
              </div>
              <div className="mathor-div">
                <div className="mathord-line"></div>
              </div>
              <div className="mt-3 svg-matrhord">
                <span
                  className="all-span-mathor"
                  onClick={() => Navigat("/MyProfile/Yourpayouts")}
                >
                  How are payouts calculated
                </span>
              </div>
              <div className="mathor-div">
                <div className="mathord-line"></div>
              </div>
              <div className="mt-3 svg-matrhord">
                <span
                  className="all-span-mathor"
                  onClick={() => Navigat("/MyProfile/Hostpayment")}
                >
                  Go to your transaction history
                </span>
              </div>
              <div className="mathor-div">
                <div className="mathord-line"></div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
    </>
  );
};

export default Aboutpayment;
