import React, {useEffect, useState} from "react";
import mapStyle from "./mapStyles";
import {GoogleMap, Marker, LoadScript} from "@react-google-maps/api";
import Whitehoulogo from "../Assets/Images/Whitehoulogo.png";

const containerStyle = {
  width: "100%",
  height: "200px",
};

const markerOptions = {
  icon: {
    url: "https://path-to-house-logo.png", // Replace with the URL of your house logo image
    scaledSize: new window.google.maps.Size(50, 50),
  },
};
const apiKey = "AIzaSyDJn3lkmc1GoVe1YMv0ZBzpUnLPlKnAeNQ";

// const roadLines = [];

function CustomMarker() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <img
        src={Whitehoulogo}
        alt="White House"
        style={{width: "24px", height: "24px", zIndex: 2}}
      />
    </div>
  );
}

const GoogleMaps = ({MyVar}) => {
  const [map, setMap] = useState(null);
  const center = {
    lat: MyVar.lat,
    lng: MyVar.long,
  };
  // console.log(MyVar);

  if (map) {
    class CustomMarker extends window.google.maps.OverlayView {
      constructor(position, map) {
        super();
        this.position = position;
        this.map = map;
        this.setMap(map);
      }

      createDiv() {
        const div = document.createElement("div");
        div.style.position = "absolute";
        div.style.width = "68px";
        div.style.height = "68px";
        div.style.borderRadius = "50%";
        div.style.backgroundColor = this.isHovered ? "#C68EFB" : "#FFFFFF";
        div.style.boxShadow = "0px 2px 6px rgba(0, 0, 0, 0.3)";
        div.style.cursor = "pointer";
        div.style.display = "flex";
        div.style.justifyContent = "center";
        div.style.alignItems = "center";

        const background = document.createElement("div");
        background.style.position = "absolute";
        background.style.width = "54px";
        background.style.height = "54px";
        background.style.backgroundColor = "#9C04F5";
        background.style.borderRadius = "50%";
        background.style.opacity = 0.8;
        background.style.zIndex = 1;

        const image = new Image();
        image.src = Whitehoulogo;
        image.alt = "White House";
        image.style.width = "20px";
        image.style.height = "22px";
        image.style.zIndex = 2;

        const handleMouseOver = () => {
          div.style.backgroundColor = "#C68EFB";
        };

        const handleMouseOut = () => {
          div.style.backgroundColor = "#FFFFFF";
        };

        div.addEventListener("mouseover", handleMouseOver);
        div.addEventListener("mouseout", handleMouseOut);

        div.appendChild(background);
        div.appendChild(image);
        return div;
      }

      onAdd() {
        const div = this.createDiv();
        const panes = this.getPanes();
        panes.overlayMouseTarget.appendChild(div);
        this.div = div;
      }

      draw() {
        const overlayProjection = this.getProjection();
        const position = overlayProjection.fromLatLngToDivPixel(this.position);
        this.div.style.left = position.x + "px";
        this.div.style.top = position.y + "px";
      }

      onRemove() {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }

    const marker = new CustomMarker(center, map);
  }

  return (
    <>
      <div style={markerOptions}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={11}
          options={{
            styles: mapStyle,
          }}
          onLoad={(map) => setMap(map)}
          googleMapsApiKey={apiKey}
        >
          <CustomMarker position={center} />
        </GoogleMap>
      </div>
    </>
  );
};

export default GoogleMaps;
