import React, { useContext, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { CohostSchemas } from "../Schemas";

const initialValues = {
  referralid: "",
};
const CohostPopup = ({ show, onHide, Getcohost }) => {
  const navigate = useNavigate();
  const [loding, setLoding] = useState(false);
  const { idget } = useContext(GlobalContext);
  const token = JSON.parse(localStorage.getItem("login"));
  // console.log(idget, "kkkkk");
  const onSubmit = async () => {
    setLoding(true);
    try {
      const response = await axios.post(
        "/add_cohost",
        {
          property_id: idget.property_data.id,
          referral_id: values.referralid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token.data.token.token}`,
          },
        }
      );
      // console.log(response, "ollllooo");
      if (response.data.status === 0) {
        toast.error(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (response.data.status === 1) {
        Getcohost();
        onHide();
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      setLoding(false);
    } catch (error) {
      setLoding(false);
      // console.log(error);
    }
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: CohostSchemas,
    onSubmit,
  });

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered>
        <Modal.Header
          style={{
            display: "grid",
            placeItems: "end",
            width: "100% ",
            justifyContent: "end",
            border: "0",
          }}
          closeButton></Modal.Header>
        <form onSubmit={handleSubmit} className="Property-card-divv-ppx">
          <div className="Property-ppx-cvv mt-2">
            <span className="My-prop">Add Co-host</span>
          </div>
          <div className="Modal_error_taggg">
            <div className="span-selact-rtag">
              <input
                className="input-khud"
                type="text"
                placeholder="Enter your id"
                id="fname"
                name="referralid"
                value={values.referralid}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.referralid && touched.referralid ? (
                <p className="form-errror">{errors.referralid} </p>
              ) : null}
            </div>
          </div>
          <div className="Button-divv-property">
            <div className="btnn-divv-pro-class">
              <button className="btn-new-add-main">Add</button>
            </div>
          </div>
        </form>
      </Modal>
      {!loding && <div></div>}
      {loding && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default CohostPopup;
