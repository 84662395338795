import React, { useState, useEffect } from "react";
import "../Components/Login.css";
import logo from "../Assets/Images/Loginlogo.png";
import Line from "../Assets/Images/LineImg.png";
import Girl from "../Assets/Images/GreenHome.png";
import { useFormik } from "formik";
import { SingUpSchemas } from "../Schemas";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
// import {auth, FacebookProvider, GoogleProvider} from "./config";
import { signInWithPopup } from "firebase/auth";
import { auth, FacebookProvider, GoogleProvider } from "../Firebasa";
// import GoogleLogin, {GoogleLogout} from "react-google-login";
import Ghome1 from "../Assets/Images/Ghome1.webp";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const [close, setclose] = useState(false);
  const Navigat = useNavigate();
  const [loading, setloading] = useState(false);
  const Device_Token = localStorage.getItem("FCM");

  const SocialLogin = (Type) => {
    signInWithPopup(auth, Type == "Google" ? GoogleProvider : FacebookProvider)
      .then(async (response) => {
        setloading(true);
        // console.log(
        //   "Bhai Ye chali ",
        //   Type == "Google" ? "GoogleProvider" : "FacebookProvider"
        // );
        // console.log(response);
        try {
          const res = await axios.post(
            "/social_register",
            {
              social_id: response.user.uid,
              social_type: Type == "Google" ? "Google" : "Facebook",
              device_id: Device_Token,
              device_type: "Web",
              email: response.user.email,
              fullname: response.user.displayName,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // console.log(res);
          // if (res.data.status === 1) {
          //   localStorage.setItem("login", JSON.stringify(res.data));
          //   toast.success(res.data.message, {
          //     position: "top-right",
          //     theme: "colored",
          //   });
          //   Navigat("/GovernmentId");
          // } else if (res.data.status === 0) {
          //   console.log("Get-User....", res);
          //   setloading(false);
          //   toast.error(res.data.message, {
          //     position: "top-right",
          //     theme: "colored",
          //   });
          //   Navigat("/");
          //   localStorage.removeItem("login");
          // }
          if (res.data.data.identity_verified === false) {
            Navigat("/GovernmentId");
            setloading(false);
          } else if (res.data.data.identity_verified === true) {
            localStorage.setItem("login", JSON.stringify(res.data));
            toast.success(res.data.message, {
              position: "top-right",
              theme: "colored",
            });
            Navigat("/Home");
            setloading(false);
          } else if (res.data.status === 0) {
            // console.log("Get-User....", res);
            setloading(false);
            toast.error(res.data.message, {
              position: "top-right",
              theme: "colored",
            });
            // if (res.data.status === 1) {
            //   localStorage.setItem("login", JSON.stringify(res.data));
            //   toast.success(res.data.message, {
            //     position: "top-right",
            //     theme: "colored",
            //   });
            //   Navigat("/GovernmentId");
            // } else if (res.data.status === 0) {
            //   console.log("Get-User....", res);
            //   setloading(false);
            //   toast.error(res.data.message, {
            //     position: "top-right",
            //     theme: "colored",
            //   });
            //   Navigat("/");
            //   localStorage.removeItem("login");
            // }
          }
        } catch (error) {
          // console.log(error);
          setloading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          theme: "colored",
        });
        // console.log(error);
        setloading(false);
      });
  };
  // useEffect(() => {
  //   const loggedInUser = localStorage.getItem("login");
  //   if (loggedInUser) {
  //     Navigat("/Home");
  //   }
  // }, []);
  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("password", values.password);
    formdata.append("email", values.email);
    try {
      let cofe = {
        url: "/login",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          if (respons.status === 0) {
            toast.error(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          }

          // console.log(respons);

          setloading(false);
          if (respons?.data?.identity_verified === false) {
            localStorage.setItem("login", JSON.stringify(respons));
            Navigat("/GovernmentId");
          } else if (
            respons.message === "login successfully" &&
            respons.status === 1
          ) {
            toast.success(respons.message, {
              position: "top-right",
              theme: "colored",
            });
            localStorage.setItem("login", JSON.stringify(respons));
            Navigat("/Home");
          }
          // if (respons.message === "Please check your emails to verify") {
          //   Navigat("/GovernmentId");
          // } else {
          //   localStorage.setItem("login", JSON.stringify(respons));
          //   Navigat("/Home");
          // }
        })
        .catch((error) => {
          setloading(false);
          // console.log(error);
        })
        .catch((error) => {
          setloading(false);
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };

  const { values, handleBlur, touched, handleChange, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: SingUpSchemas,
      onSubmit,
    });
  const eyeopen = (
    <svg
      className="clip-mar"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_1790)">
        <path
          d="M11.9987 3C17.3907 3 21.8767 6.88 22.8177 12C21.8777 17.12 17.3907 21 11.9987 21C6.60669 21 2.12069 17.12 1.17969 12C2.11969 6.88 6.60669 3 11.9987 3ZM11.9987 19C14.0382 18.9996 16.0171 18.3068 17.6115 17.0352C19.206 15.7635 20.3216 13.9883 20.7757 12C20.3199 10.0133 19.2036 8.24 17.6093 6.97003C16.015 5.70005 14.037 5.00853 11.9987 5.00853C9.96038 5.00853 7.98238 5.70005 6.38806 6.97003C4.79375 8.24 3.67745 10.0133 3.22169 12C3.67578 13.9883 4.79136 15.7635 6.38583 17.0352C7.9803 18.3068 9.95922 18.9996 11.9987 19ZM11.9987 16.5C10.8052 16.5 9.66062 16.0259 8.81671 15.182C7.97279 14.3381 7.49869 13.1935 7.49869 12C7.49869 10.8065 7.97279 9.66193 8.81671 8.81802C9.66062 7.97411 10.8052 7.5 11.9987 7.5C13.1922 7.5 14.3368 7.97411 15.1807 8.81802C16.0246 9.66193 16.4987 10.8065 16.4987 12C16.4987 13.1935 16.0246 14.3381 15.1807 15.182C14.3368 16.0259 13.1922 16.5 11.9987 16.5ZM11.9987 14.5C12.6617 14.5 13.2976 14.2366 13.7665 13.7678C14.2353 13.2989 14.4987 12.663 14.4987 12C14.4987 11.337 14.2353 10.7011 13.7665 10.2322C13.2976 9.76339 12.6617 9.5 11.9987 9.5C11.3356 9.5 10.6998 9.76339 10.2309 10.2322C9.76208 10.7011 9.49869 11.337 9.49869 12C9.49869 12.663 9.76208 13.2989 10.2309 13.7678C10.6998 14.2366 11.3356 14.5 11.9987 14.5Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_1790">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const eyeclose = (
    <svg
      className="clip-mar"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_1797)">
        <path
          d="M9.34178 18.782L7.41078 18.264L8.19778 15.325C7.01975 14.8905 5.9249 14.2574 4.96078 13.453L2.80778 15.607L1.39278 14.192L3.54678 12.039C2.33086 10.5828 1.51387 8.83576 1.17578 6.969L3.14378 6.61C3.90278 10.812 7.57878 14 11.9998 14C16.4198 14 20.0968 10.812 20.8558 6.61L22.8238 6.968C22.4861 8.83502 21.6695 10.5824 20.4538 12.039L22.6068 14.192L21.1918 15.607L19.0388 13.453C18.0747 14.2574 16.9798 14.8905 15.8018 15.325L16.5888 18.265L14.6578 18.782L13.8698 15.842C12.6321 16.0541 11.3674 16.0541 10.1298 15.842L9.34178 18.782Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_1797">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  // continue as guest login
  const guestlogin = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("password", "1234");
    formdata.append("email", "guest@gmail.com");
    try {
      let cofe = {
        url: "/login",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          if (respons.status === 0) {
            toast.error(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          } else if (respons.status === 1) {
            toast.success(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          // console.log(respons.data);

          setloading(false);
          if (
            respons.message === "login successfully" &&
            respons.status === 1
          ) {
            localStorage.setItem("login", JSON.stringify(respons));
            Navigat("/Home");
          }
          if (respons.data.identity_verified === false) {
            Navigat("/GovernmentId");
          } else if (respons.data.identity_verified === true) {
            Navigat("/Home");
          }
        })
        .catch((error) => {
          setloading(false);
          // console.log(error);
        })
        .catch((error) => {
          setloading(false);
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };

  return (
    <>
      <section className="Login-main-sec">
        <div className="Login-div-main">
          <div className="img-div-p">
            <img className="girl-img-main" src={Ghome1} alt="" />
          </div>

          <div className="span-div-p">
            <span className="the-span-2">
              {" "}
              Find the perfect home for your holiday accommodation
            </span>
            <div className="mt-3"></div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="i-m-g-div">
              <img
                onClick={() => Navigat("/")}
                className="Login-logo-img"
                src={logo}
                alt=""
              />
              <div>
                <span onClick={() => Navigat("/")} className="ho-span">
                  {" "}
                  @home
                </span>
              </div>
              <h3 className="rentel-span">Vacation Rentals</h3>
              <div className="mt-3">
                <span className="l-o-span">Login</span>
              </div>

              <div className="svg-p-div mt-4">
                <div>
                  <svg
                    className="svg-margin"
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM18 4.238L10.072 11.338L2 4.216V16H18V4.238ZM2.511 2L10.061 8.662L17.502 2H2.511Z"
                      fill="#211B24"
                    />
                  </svg>
                </div>
                <input
                  className="i-t-a"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              {errors.email && touched.email ? (
                <p className="mastu-to-eroor">{errors.email} </p>
              ) : null}
              <div className="svg-p-div mt-4">
                <div>
                  <svg
                    className="svg-margin"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_17_523)">
                      <path
                        d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
                        fill="#211B24"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_17_523">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <input
                  className="i-t-a"
                  type={!close ? "password" : "text"}
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <span onClick={() => setclose(!close)}>
                  {!close ? eyeclose : eyeopen}
                </span>
              </div>
              {errors.password && touched.password ? (
                <p className="mastu-to-eroor">{errors.password} </p>
              ) : null}
              <div className="main-chek mt-3">
                <div className="che-div">
                  <input className="chek-book" type="checkbox" />
                  <div>
                    <span className="me-span">Remember Me</span>
                  </div>
                </div>
                <div>
                  <span
                    className="forgat-span"
                    onClick={() => Navigat("/ForgetPassword")}
                  >
                    Forgot Password?
                  </span>
                </div>
              </div>
              <div className="btn-margin">
                <button type="submit" className="Lo-btn-1">
                  Login
                </button>
              </div>
              <div className="line-span-div">
                <img className="line-img" src={Line} alt="" />
                <span>OR Login with</span>
                <img className="line-img" src={Line} alt="" />
              </div>
              <div className="f-g-btn-div">
                <button
                  type="button"
                  // onClick={() => FacebookLogin()}
                  onClick={() => SocialLogin("Facebook")}
                  className="f-book-btn"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_17_532)">
                      <path
                        d="M15.402 21V14.034H17.735L18.084 11.326H15.402V9.598C15.402 8.814 15.62 8.279 16.744 8.279H18.178V5.857C17.4838 5.78334 16.7861 5.74762 16.088 5.75C14.021 5.75 12.606 7.012 12.606 9.33V11.326H10.268V14.034H12.606V21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H15.402V21Z"
                        fill="#237AEF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_17_532">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <span className="fac-book-span">Facebook</span>
                </button>

                <div className="g-clls">
                  <div className="home-button-ka-liya">
                    <div className="ya-do-button">
                      <div
                        className="g-signin2"
                        data-onsuccess="onSignIn"
                      ></div>
                      <button
                        type="button"
                        // onClick={() => GoogleLogin()}
                        onClick={() => SocialLogin("Google")}
                        className="g-btn-gogale"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.064 5.51C1.89601 3.85324 3.17237 2.46051 4.75043 1.48747C6.32849 0.514427 8.14606 -0.000583569 10 4.96231e-07C12.695 4.96231e-07 14.959 0.99 16.69 2.605L13.823 5.473C12.786 4.482 11.468 3.977 10 3.977C7.395 3.977 5.19 5.737 4.405 8.1C4.205 8.7 4.091 9.34 4.091 10C4.091 10.66 4.205 11.3 4.405 11.9C5.191 14.264 7.395 16.023 10 16.023C11.345 16.023 12.49 15.668 13.386 15.068C13.9054 14.726 14.3501 14.2822 14.6932 13.7635C15.0363 13.2448 15.2706 12.6619 15.382 12.05H10V8.182H19.418C19.536 8.836 19.6 9.518 19.6 10.227C19.6 13.273 18.51 15.837 16.618 17.577C14.964 19.105 12.7 20 10 20C8.68663 20.0005 7.38604 19.7422 6.17254 19.2399C4.95905 18.7375 3.85645 18.0009 2.92776 17.0722C1.99907 16.1436 1.2625 15.041 0.760135 13.8275C0.257774 12.614 -0.000524861 11.3134 8.00714e-07 10C8.00714e-07 8.386 0.386001 6.86 1.064 5.51Z"
                            fill="#C61414"
                          />
                        </svg>
                        <span className="gogale-login"> Google</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <button
                  onClick={() => Navigat("/")}
                  className="home-buttn-login"
                >
                  {" "}
                  Home
                </button>
              </div>
              <div className="mt-4" style={{ cursor: "pointer" }}>
                <span className="Guest-span" onClick={guestlogin}>
                  Continue as Guest.
                </span>
              </div>
              <div className="d-r-main-div">
                <span className="dont-span">Don’t have an account?</span>
                <span
                  className="regi-span"
                  onClick={() => Navigat("/CreateAnAccount")}
                >
                  {" "}
                  Register.
                </span>
              </div>
            </div>
          </form>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Login;
