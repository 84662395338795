import React, { useState, useEffect } from "react";
import "../Components/PropertyDetails.css";
import Star from "../Assets/Images/Star.png";
import downarrow from "../Assets/Images/Downarrow.png";
import Bed from "../Assets/Images/Bed.png";
import group from "../Assets/Images/Group.png";
import bedroom from "../Assets/Images/bedroom.png";
import Bathroom from "../Assets/Images/Bathroom.png";
import leftarrow from "../Assets/Images/Leftarrow.png";
import yellowstar from "../Assets/Images/Yellowstar.png";
import greystar from "../Assets/Images/greystar.png";
import SwiperPropertydetails from "../Components/SwiperPropertydetails";
import axios from "../Schemas/Api";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import back from "../Assets/Images/Backicon.png";
import DateRangePicker from "rsuite/DateRangePicker";
import moment, { isDate } from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../Components/Footer";
import LoginNavbar from "../Components/LoginNavbar";
import { CircularProgress } from "@mui/material";
import GoogleMaps from "./GoogleMaps";

const TwoPropertyDetails = () => {
  const [Showadd, setShowadd] = useState(false);
  const [Datep, setDatep] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [loading, setlaoding] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  // console.log(selectedDates, "selectedDates");
  const [reviewHere, setReviewHere] = useState([0]);
  const [reviewHere2, setReviewHere2] = useState([0]);
  const [Apidate, setApidate] = useState("");
  // console.log(Apidate, "Apidate");
  const [House, setHouse] = useState([0]);
  let route = useLocation();
  const maindata = route.state.newdata;
  const [idds, setidss] = useState(maindata.id.beBhai);

  useEffect(() => {
    const startDate = moment().startOf("day").toDate();
    const endDate = moment().add(7, "days").endOf("day").toDate();
    setSelectedDates([startDate, endDate]);
  }, []);
  // console.log(selectedDates, "selectedDatesugyg");
  const handleDateChange = (value) => {
    setApidate(value);
    setSelectedDates(value);
    CheckAvaiblity();
  };

  const handleClick = () => {
    setShowMore(!showMore);
  };

  const handleDate = () => {
    setDatep(!Datep);
  };

  const handleAdd = () => {
    setShowadd(!Showadd);
  };
  useEffect(() => {
    setidss(idds);
  }, []);

  const [showAmeties, setShowAmeties] = useState(false);

  const handleChange = () => {
    setShowAmeties(!showAmeties);
  };

  const [showReviews, setShowReviews] = useState(false);

  const handleOpen = () => {
    setShowReviews(!showReviews);
  };

  const token = JSON.parse(localStorage.getItem("login"));
  const [detail, setDetail] = useState([]);

  const propertydetail = async () => {
    setlaoding(true);
    const formdata = new FormData();
    // console.log("callll");
    formdata.append("property_id", idds);

    try {
      let pdetails = {
        url: "/property_detail",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };

      axios(pdetails)
        .then((res) => res.data)
        .then((respo) => {
          // console.log(respo);
          setlaoding(false);
          setDetail([respo.data?.property_detail]);
          setReviewHere(respo.data?.reviews);
          setReviewHere2(respo.data?.reviews);
          setHouse(respo.data?.property_detail?.house_rules);
        });
    } catch (error) {
      // console.log("userap...", error);
      setlaoding(false);
    }
  };
  useEffect(() => {
    propertydetail();
  }, []);

  // console.log(reviewHere);

  const Bookin = async () => {
    const formdata = new FormData();
    // console.log("callll");
    formdata.append("start_date");

    try {
      let pdetails = {
        url: "/bookings",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };

      axios(pdetails)
        .then((res) => res.data)
        .then((respo) => { });
    } catch (error) {
      // console.log("userap...", error);
    }
  };
  useEffect(() => {
    propertydetail();
  }, []);

  // console.log(detail);

  const Navigat = useNavigate();

  const handleclick = async () => {
    Navigat("/BookingRequest ", {
      state: maindata,
    });
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [disabledDates, setDisabledDates] = useState([]);
  // console.log(disabledDates, "disabledDates");
  const getUnavliabledate1 = async () => {
    axios({
      method: "post",
      url: "/unavailable_dates",
      data: {
        property_id: idds,
      },

      headers: {
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setDisabledDates(data);
        // console.log(data, "Date mil jaaa");
      })

      .catch((er) => {
        // console.log(er);
      });
  };
  useEffect(() => {
    getUnavliabledate1();
  }, []);

  // console.log(selectedDates, "selectedDates");

  const [CheckAva, setCheckAva] = useState([]);
  // console.log(CheckAva, "CheckAva");
  const CheckAvaiblity = async () => {
    const data = {
      property_id: idds,
      start_date: moment(Apidate[0]).format("YYYY-MM-DD").toString(),
      end_date: moment(Apidate[1]).format("YYYY-MM-DD").toString(),
    };
    // console.log(
    //   "🚀 ~ file: TwoPropertyDetails.js:206 ~ CheckAvaiblity ~ data:",
    //   data
    // );
    axios({
      url: "/add_unavailable_days",
      method: "post",
      data: data,

      headers: {
        "Content-Type": "application/json",
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log("CheckAvaiblity  data:", data);
        setCheckAva(data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const [Days, setDays] = useState([]);
  // console.log(Days, " kantala");
  const getUnavliabledate = async () => {
    axios({
      method: "post",
      url: "/unavailable_days",
      data: {
        property_id: idds,
      },
      headers: {
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setDays(data.data);
      })
      .catch((er) => {
        // console.log(er);
      });
  };
  useEffect(() => {
    getUnavliabledate();
  }, [selectedDates]);

  const Deleteddate = async (id, index) => {
    // console.log(id, "id lodfas");
    axios({
      method: "post",
      url: "/delete_unavailable_days",
      data: {
        unavailable_id: id,
      },

      headers: {
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        getUnavliabledate();
      })
      .catch((er) => {
        // console.log(er);
      });
  };

  return (
    <>
      <LoginNavbar />
      <div className="">
        <span className="backbtn" onClick={() => Navigat(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <div className="homeparentmain">
        {loading ? (
          <div className="circular2">
            <CircularProgress />
          </div>
        ) : (
          <div className="Home-slider-merge-divv">
            <div className="SwiperProperty-home-divv-ppx">
              <SwiperPropertydetails />
            </div>
            {detail.map((currelem, index) => {
              const Amenities = currelem?.amenities?.slice(0, 10);
              const Amenities2 = currelem?.amenities?.slice(10);
              return (
                <div key={index} className="MAin-slider-containor-divv">
                  <div className="MAin-slider-containor-divv">
                    <div className="Slider-second-div-ppx">
                      <div className="Texas-Grand-Middle-div-ppx">
                        <div className="yexas-merge-div">
                          <div className="grandview-texas-us-ppx">
                            <span className="grandview-texas-us-span">
                              {currelem?.address}
                            </span>
                          </div>
                          <div className="Assible-parking-main-div">
                            <span className="Accecible-parking-us-span">
                              {currelem?.amenities[0]?.amenity} and more
                            </span>
                          </div>
                        </div>
                        <div className="merge-div-rating-tff mt-4">
                          <div className="rating-four-texas-div">
                            <img className="Star-raing-ppx" src={Star} />
                            <span className="Star-rating-span-ppx">
                              {" "}
                              {currelem.average_ratings}
                            </span>
                          </div>
                          <div className="three-four-four-main-div">
                            <span className="Three-four-four-span-ppx">
                              ( People rated)
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="report-people-main-div">
                        <div className="Report-this-listing-div-ppx mt-4">
                          <span className="two-rs-us-span">
                            €{currelem.price_per_night}
                          </span>
                          <span className="per-main-div"> / </span>
                          <span className="night-per-main-div">night</span>
                        </div>
                      </div>
                    </div>
                    <div className="Underline-main-divv mt-4"></div>

                    <div className="entire-home-profile">
                      <div className="entire-home-profile-bell">
                        <div className="profile-Jerome-Bell-main-divv mt-3">
                          <span className="entire-jerome-bell-div">
                            Entire home hosted by
                          </span>
                        </div>
                        <div className="profile-plus-name-divv mt-3">
                          <img
                            className="profile-imgg-name"
                            src={currelem.host_profile}
                          />
                          <span className="profile-Jermo-name-ppx">
                            {currelem.host_name}
                          </span>
                        </div>
                      </div>
                      <div className="Book-now-main-divv-ppx">
                        <div className="Book-second-divv-pxx"></div>
                      </div>
                    </div>
                    <div className="Underline-main-divv mt-4"></div>
                    <div className="Lorem-span-main-divv mt-4">
                      <div className="Read-lorem-main-divv-ppx">
                        <span className="lorem-div-span-para">
                          {currelem.neighbourhood_description}
                        </span>
                        <br />
                        {showMore && (
                          <span className="lorem-div-span-para">
                            {currelem.about_property}
                          </span>
                        )}
                        <div className="lorem-show-more-divv-ppx mt-4">
                          <button
                            className="Lorem-read-mor-btnn"
                            onClick={handleClick}
                          >
                            {showMore ? (
                              <span className="Read-less-arrow-span">
                                Read Less
                              </span>
                            ) : (
                              <span className="Read-less-arrow-span">
                                Read More
                              </span>
                            )}
                            <img
                              className="Lorem-imgg-downarrow"
                              src={downarrow}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="Guests-bed-bathroom-main-divv">
                      <div className="Guests-bed-fourteen-beds-ppx">
                        <div className="Guests-bed-nine-bats-ppx mt-3">
                          <div className="Guests-fourten-imgg-divvv">
                            <img
                              className="Guests-fourten-imgg-ppx"
                              src={bedroom}
                            />
                          </div>
                          <div className="Guests-spann-divv-ppx">
                            <span className="Guests-fourten-spann-ppx">
                              {" "}
                              {currelem.beds.length}
                            </span>
                            <span className="Guests-para-spann-ppx">
                              Bedrooms
                            </span>
                          </div>
                        </div>
                        <div className="Guests-bed-nine-bats-ppx mt-3">
                          <div className="Guests-fourten-imgg-divvv">
                            <img
                              className="Guests-fourten-imgg-ppx"
                              src={group}
                            />
                          </div>
                          <div className="Guests-spann-divv-ppx">
                            <span className="Guests-fourten-spann-ppx">
                              {" "}
                              {currelem.child + currelem.adult}
                            </span>
                            <span className="Guests-para-spann-ppx">
                              Guests
                            </span>
                          </div>
                        </div>
                        <div className="Guests-bed-nine-bats-ppx mt-3">
                          <div className="Guests-fourten-imgg-divvv">
                            <img
                              className="Guests-fourten-imgg-ppx"
                              src={Bathroom}
                            />
                          </div>
                          <div className="Guests-spann-divv-ppx">
                            <span className="Guests-fourten-spann-ppx">
                              {" "}
                              {currelem.bathroom.length}
                            </span>
                            <span className="Guests-para-spann-ppx">
                              Bathrooms
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="Total_beds_and_bathrooms">
                      <div className="mt-4">
                        <span className="What-this-span-offer-para">
                          Where you'll sleep
                        </span>
                      </div>
                      <div className="img-bath-main-dixx-ppx mt-3">

                        {currelem?.beds.map((itm, index) => {
                          return (
                            <div className="All_baths_and_beds">
                              <h5>Bedroom {index + 1}</h5>
                              {itm?.beds.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="imgg-divv-title-bath"
                                  // onClick={() => handleCategoryClick(itm.id)}

                                  >
                                    <img
                                      className="imgg-divv-itmm"
                                      src={Bed}
                                    />

                                    <span className="house-mera mt-2">{item.type}</span>
                                  </div>
                                );

                              })}

                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-4">
                        <span className="What-this-span-offer-para">
                          Where you'll shower
                        </span>
                      </div>
                      <div className="img-bath-main-dixx-ppx mt-4">
                        {currelem?.bathroom.map((itm, index) => {
                          return (
                            <div className="All_baths_and_beds">
                              <h5>Bathroom {index + 1}</h5>
                              {itm?.beds.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="imgg-divv-title-bath"
                                  // onClick={() => handleCategoryClick(itm.id)}

                                  >
                                    <img
                                      className="imgg-divv-itmm"
                                      src={Bathroom}
                                    />

                                    <span className="house-mera mt-2">{item.type}</span>
                                  </div>
                                );

                              })}

                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="What-this-span-main-divv-ppx">
                      <div className="What-this-second-offer-ppx mt-4">
                        <span className="What-this-span-offer-para">
                          What this place offer
                        </span>
                      </div>
                      <div className="What-this-span-btnn-divv mt-3">
                        <div className="What-list-divvv-btnnss">
                          {Amenities.map((item, index) => {
                            return (
                              <button
                                key={index}
                                className="what-this-list-btn"
                              >
                                {item.amenity}
                              </button>
                            );
                          })}
                          {showAmeties && (
                            <div className="What-list-divvv-btnnss">
                              {Amenities2.map((item, index) => {
                                return (
                                  <button
                                    key={index}
                                    className="what-this-list-btn"
                                  >
                                    {item.amenity}
                                  </button>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="lorem-show-more-divv-ppx mt-4">
                        <button
                          className="Lorem-read-mor-btnn"
                          onClick={handleChange}
                        >
                          {showAmeties ? (
                            <span className="Read-less-arrow-span">
                              Show less amenities
                            </span>
                          ) : (
                            <span className="Read-less-arrow-span">
                              Show all amenities
                            </span>
                          )}
                          <img
                            className="Lorem-imgg-downarrow"
                            src={downarrow}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="Underline-main-divv mt-4"></div>
                    <div className="Find-on-map-main-divv mt-4 ">
                      <span className="Find-on-map-span-para">Find on map</span>
                    </div>

                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <GoogleMaps
                          MyVar={{
                            lat: parseFloat(currelem.latitude),
                            long: parseFloat(currelem.longitude),
                          }}
                        />
                      </div>
                    </div>

                    <div className="Underline-main-divv mt-3"></div>

                    <div className="Health-safety-main-divv mt-3">
                      <div className="Health-safety-four-dec-divv">
                        <div className="Health-safety-spn-divv">
                          <span className="Health-safety-arr-dec-divv">
                            Cancellation Policy
                          </span>
                        </div>
                      </div>
                      <div className="Health-lorem-divv-ppx mt-1">
                        <div className="Health-lorem-spann-divv">
                          <b>{currelem?.cancellation_policy.title}</b> <br />
                          <span className="Health-lorem-spann-para-ppx">
                            {currelem?.cancellation_policy.policy}
                          </span>
                        </div>
                        <div className="Underline-main-divv mt-3"></div>
                      </div>
                    </div>
                    <div
                      onClick={handleAdd}
                      className="Health-safety-main-divv mt-3"
                    >
                      <div className="Health-safety-four-dec-divv">
                        <div className="Health-safety-spn-divv">
                          <span className="Health-safety-arr-dec-divv">
                            Unavailable Dates
                          </span>
                        </div>
                        <div className="Health-safety-arr-imgg-divv">
                          <img
                            className="Health-safety-left-arrow"
                            src={leftarrow}
                          />
                        </div>
                      </div>
                      <span>
                        Select tha dates to block your calendat. Guests will not
                        able to make bookings during the blocked dates
                      </span>
                    </div>
                    {Showadd && (
                      <button
                        onClick={handleDate}
                        className="add-btn-Unavailable mt-5 mb-3"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_67_972)">
                            <path
                              d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_67_972">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Add
                      </button>
                    )}
                    <div className="date-unavilebal-ho-gai">
                      {Datep && (
                        <>
                          <DateRangePicker
                            showOneCalendar
                            onChange={handleDateChange}
                            onClick={(e) => {
                              e.preventDefault(CheckAvaiblity);
                            }}
                          />
                        </>
                      )}
                    </div>
                    {Days.map((curelem, index) => {
                      // console.log(Days, "Days");
                      const startDate = new Date(curelem.start_date)
                        .toISOString()
                        .substring(0, 10);
                      // console.log(startDate, "startDate ok");
                      const endDate = new Date(curelem.end_date)
                        .toISOString()
                        .substring(0, 10);
                      // console.log(endDate, "endDate ok");
                      return (
                        <>
                          <div
                            onClick={() => Deleteddate(curelem?.id, index)}
                            className="delet-bhi-kar-sakte-he mt-3"
                          >
                            <span key={index.toString()} className="pacha-char">
                              {startDate}
                            </span>

                            <span key={index.toString()} className="pacha-char">
                              {endDate}
                            </span>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_25_2643)">
                                <path
                                  d="M17 6H22V8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8H2V6H7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
                                  fill="#211B24"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_25_2643">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </>
                      );
                    })}
                    <div className="mt-5"></div>

                    <div className="Reviews-see-all-rating-main-div ">
                      {reviewHere.slice(0, 3).map((item, index) => {
                        const RatingStar = () => {
                          if (item?.rating === 5) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 4) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 3) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 2) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 1) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={yellowstar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          } else if (item?.rating === 0) {
                            return (
                              <div className="Jerome-prof-rating-star-divv">
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                                <div className="jermo-star-divv-yelow">
                                  <img
                                    className="yellow-star-bell-imgg"
                                    src={greystar}
                                  />
                                </div>
                              </div>
                            );
                          }
                        };
                        return (
                          <>
                            <div
                              key={index}
                              className="Reviews-rating-desc-mian-divv"
                            >
                              <div className="jermo-reviews-date-june-divv mt-3">
                                <div className="Bell-rev-date-june-divv">
                                  <div className="Reviews-jermo-bell-mian-divv">
                                    <div className="Reviews-profile-photo-divv">
                                      <img
                                        className="Reviews-profile-imgg"
                                        src={item?.profile_pic}
                                      />
                                    </div>
                                    <div className="Jerome-Bell-name-tagg">
                                      <div className="Jerome-PROF-BEL-tagg">
                                        <span className="Jerome-Bell-spnn-name">
                                          <b>{item?.fullname}</b>
                                        </span>
                                        <RatingStar />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="date-jermo-text-june-divv">
                                  <span className="date-jermo-spann-june-divv">
                                    {item?.create_date}
                                  </span>
                                </div>
                              </div>
                              <div className="lorem-date-jermo-divv mt-1">
                                <span className="lorem-jermo-spn-divv-aat">
                                  {item?.review}
                                </span>
                              </div>
                              <div className="Underline-main-divv mt-3"></div>
                            </div>
                          </>
                        );
                      })}

                      {showReviews && (
                        <div className="Reviews-see-all-rating-main-div">
                          {reviewHere2.slice(3).map((item, index) => {
                            const RatingStar = () => {
                              if (item.rating === 5) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 4) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 3) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 2) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 1) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={yellowstar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (item.rating === 0) {
                                return (
                                  <div className="Jerome-prof-rating-star-divv">
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                    <div className="jermo-star-divv-yelow">
                                      <img
                                        className="yellow-star-bell-imgg"
                                        src={greystar}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            };
                            return (
                              <>
                                <div
                                  key={index}
                                  className="Reviews-rating-desc-mian-divv"
                                >
                                  <div className="jermo-reviews-date-june-divv mt-3">
                                    <div className="Bell-rev-date-june-divv">
                                      <div className="Reviews-jermo-bell-mian-divv">
                                        <div className="Reviews-profile-photo-divv">
                                          <img
                                            className="Reviews-profile-imgg"
                                            src={item?.profile_pic}
                                          />
                                        </div>
                                        <div className="Jerome-Bell-name-tagg">
                                          <div className="Jerome-PROF-BEL-tagg">
                                            <span className="Jerome-Bell-spnn-name">
                                              <b>{item?.fullname}</b>
                                            </span>
                                            <RatingStar />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="date-jermo-text-june-divv">
                                      <span className="date-jermo-spann-june-divv">
                                        {item?.create_date}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="lorem-date-jermo-divv mt-1">
                                    <span className="lorem-jermo-spn-divv-aat">
                                      {item?.review}
                                    </span>
                                  </div>
                                  <div className="Underline-main-divv mt-3"></div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default TwoPropertyDetails;
