import React, { useState, useEffect } from "react";
import "../Components/Continueproperty.css";
import Prop1 from "../Assets/Images/Property1.png";
import Prop2 from "../Assets/Images/Property2.png";
import Prop3 from "../Assets/Images/Property3.png";
import Prop4 from "../Assets/Images/Property4.png";
import Prop5 from "../Assets/Images/Property5.png";
import Prop6 from "../Assets/Images/Property6.png";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

const Continueproperty = () => {
  const [conproperty, setConproperty] = useState([]);
  const [propertyadd, setPropertyadd] = useState([]);
  const [loading, setloading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("login"));
  // console.log(conproperty, "dfdsfdf");
  // const GetSavelist = () => {
  //   setLoading(true);
  //   axios({
  //     method: "get",
  //     url: "/get_pending_property_list",
  //     headers: {
  //       authorization: `${token.data.token.token}`,
  //     },
  //   })
  //     .then((res) => res.data)
  //     .then((data) => {
  //       setConproperty(data.data);
  //       setLoading(false);
  //       console.log(data.data);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };

  const GetSavelist = async () => {
    setLoading(true);

    try {
      const response = await axios.get("/get_pending_property_list", {
        headers: {
          authorization: `${token?.data?.token?.token}`,
        },
      });
      setConproperty(response.data.data);
      setLoading(false);
      // console.log(response);
    } catch (error) {
      // console.log(error, "errprs");
      setLoading(false);
    }
  };

  useEffect(() => {
    GetSavelist();
  }, []);

  const deleteproperty = async (id, index) => {
    // console.log(id, "id ");
    setLoading(true);
    const formdata = new FormData();
    formdata.append("pending_property_id", id);

    try {
      let AddProperty = {
        url: "/delete_pending_property",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token?.data?.token?.token}`,
        },
      };
      axios(AddProperty)
        .then((res) => res.data)
        .then((respons) => {
          toast.success(respons.message, {
            position: "top-right",
            theme: "colored",
          });
          if (respons.message === "Property removed successfully.") {
          }
          setLoading(false);
          GetSavelist();
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };
  const set = (arr) => {
    navigate("/PauseAddProperty", {
      state: {
        arr,
      },
    });
  };

  return (
    <>
      <section className="MyProperty-main-section">
        <div className="MyProperty-main-div">
          <div className="my-prop-and-add">
            <span className="My-prop">Continue Property</span>
          </div>

          {!Loading && conproperty?.length >= 1 ? (
            <div className="map-upar-div">
              {conproperty?.map((curelem, index) => {
                return (
                  <div
                    key={index}
                    className="mainnn-divvv-dusri-bar"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="api-imgis" onClick={() => set(curelem)}>
                      <img
                        className="Prop-home-1"
                        src={
                          curelem?.images[0]
                            ? curelem?.images[0].picture_name
                            : null
                        }
                        alt="images"
                      />
                    </div>

                    <div className="d-flex">
                      <span className="Grandview-span-1">
                        {curelem?.property_detail?.address}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="Accessible-span">{curelem.Detail}</span>
                    </div>
                    <div className="mt-3 span-naghit-div-main">
                      <div>
                        <span className="night-span">
                          ${curelem?.property_detail?.price_per_night}
                        </span>
                        <span className="night-span-2"> / night</span>
                      </div>
                      <div
                        className="svg-map-khud"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          deleteproperty(curelem?.property_detail?.id)
                        }
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z"
                            fill="#211B24"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No properties found.</div>
          )}
        </div>
      </section>
      {Loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Continueproperty;
