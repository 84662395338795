import React from "react";
import "../Components/Footer.css";
// import Footerlogo from "../Assets/Images/Home logo white.png";
import Fbookimg from "../Assets/Images/fbookimg.png";
import timg from "../Assets/Images/tImg.png";
import Insta from "../Assets/Images/insta_ic_bg.png";
import inimg from "../Assets/Images/inimg.png";
import { useNavigate } from "react-router-dom";
import hlogoblackbg from "../Assets/Images/hlogoblackbg.webp";
// import Improtant from "../Components/Improtant";

import { NavLink } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* <Improtant /> */}
      <section className="Footer-main-section">
        <div className="Footer-main-div">
          <div className="FOoter-ek-div">
            <div className="main-thard-all-div">
              <div className="logo-main-span-div">
                <div className="footer-img-span-div">
                  <img className="footer-logo-img" src={hlogoblackbg} alt="" />

                  <div className="Read-More-div">
                    <span
                      className="Read-More-span"
                      onClick={() => navigate("/Who-are-we")}
                    >
                      {" "}
                      Who Are We
                    </span>
                  </div>
                </div>
              </div>
              <div className="alo-to-al">
                <div className="to-by-to-div">
                  <div className="all-span-main-div">
                    <div>
                      <div className="Newsroom-div">
                        <span
                          className="Newsroom-span"
                          onClick={() => navigate("/Guest-rules")}
                        >
                          Guest rules, regulations and recommendations
                        </span>
                      </div>
                      <div className="span-div">
                        <span
                          className="Learn-about-new-span"
                          onClick={() => navigate("/Recommendations")}
                        >
                          Host rules, regulations and recommendations
                        </span>
                      </div>
                      <div className="span-div">
                        <span
                          className="Learn-about-new-span"
                          onClick={() => navigate("/The-review-process")}
                        >
                          How does the review process work?
                        </span>
                      </div>
                      <div className="span-div">
                        <span
                          className="Newsroom-span"
                          onClick={() => navigate("/Booking-Fees")}
                        >
                          Fees Explained
                        </span>
                      </div>
                    </div>

                    <div className="contactusmain">
                      <span className="second-home-span">Contact Us</span>
                      <a
                        className="Social-akkk"
                        href="mailto:info@homevr.online"
                      >
                        info@homevr.online
                      </a>
                      <a
                        className="Social-akkk"
                        href="mailto:support@homevr.online"
                      >
                        support@homevr.online{" "}
                      </a>
                    </div>
                  </div>
                  <div className="Social-main-div">
                    <div>
                      <span className="second-home-span">Social</span>
                    </div>
                    <div className="Fhass-book-div">
                      <img className="Fhass-book-img" src={Fbookimg} alt="" />
                      <div>
                        <img className="Fhass-book-img" src={timg} alt="" />
                      </div>
                      <a
                        href="https://www.instagram.com/homevr_online/?igshid=YmMyMTA2M2Y%3D"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="Fhass-book-img" src={Insta} alt="" />
                      </a>

                      <div>
                        <img className="Fhass-book-img" src={inimg} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ex-tra-d-iv"></div>
            <div className="mt-5 com-gmail">
              <span className="copy-right-span">
                © Copyright 2024. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
