import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../GlobalContext.jsx";
import "../Components/MyProperty.css";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

const MyProperty = () => {
  const id = JSON.parse(localStorage.getItem("Propid"));
  const [propertyadd, setPropertyadd] = useState([]);
  const [image, setimage] = useState([]);
  const token = JSON.parse(localStorage.getItem("login"));
  const [idd, setidd] = useState("");
  const [save, setSave] = useState();
  const [show, setShow] = useState(false);
  const url = JSON.parse(localStorage.getItem("URL"));
  const [okShow, setokShow] = useState(false);
  const pauseid = JSON.parse(localStorage.getItem("Pauseid"));
  const { setIdget } = useContext(GlobalContext);

  const [DelShow, setDelShow] = useState(false);
  const [delid, setDelid] = useState(null);

  // const Propertget = async () => {
  //   setloading(true);
  //   try {
  //     let cofe = {
  //       url: "/my_property",
  //       method: "Get",
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Accept: "application/json",
  //         authorization: `${token.data.token.token}`,
  //       },
  //     };
  //     axios(cofe)
  //       .then((res) => res.data)
  //       .then((respons) => {
  //         console.log(respons);
  //         setloading(false);
  //         setimage(respons.data);
  //         setPropertyadd(respons.data);
  //         if (respons.data[0].property_data.has_bank_details === false) {
  //           setokShow(true);
  //         }
  //       });
  //   } catch (error) {
  //     setloading(false);
  //     toast.error(error.response.message, {
  //       position: "top-right",
  //       theme: "colored",
  //     });
  //   }
  // };
  // useEffect(() => {
  //   Propertget();
  // }, []);
  const propertydata = async () => {
    setloading(true);
    try {
      const response = await axios.get("/my_property", {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      });
      setPropertyadd(response.data.data);
      setloading(false);
      if (response.data?.data[0]?.property_data?.has_bank_details === false) {
        setokShow(true);
      }
      // console.log(response);
    } catch (error) {
      setloading(false);
      // console.log("Error", error);
    }
  };
  useEffect(() => {
    propertydata();
  }, []);

  const [Loading, setLoading] = useState(false);
  const [loding, setLoding] = useState(false);

  const Navigat = useNavigate();
  const [loading, setloading] = useState(false);
  const onSubmit = async (id, index) => {
    setLoding(true);
    const formdata = new FormData();
    formdata.append("property_id", id);
    try {
      let AddProperty = {
        url: "/delete_property",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(AddProperty)
        .then((res) => res.data)
        .then((respons) => {
          toast.success(respons.message, {
            position: "top-right",
            theme: "colored",
          });
          // console.log(respons);
          if (respons.message === "Property removed successfully.") {
            propertydata();
            setDelShow(false);
            setPropertyadd(propertyadd.filter((item, i) => i !== index));
          }
          setLoding(false);
        })
        .catch((error) => {
          if (error) {
            setLoding(false);
          }
        });
    } catch (error) {
      // console.log(error);
      // setloading(false);
      setLoding(false);
    }
  };
  const handledetail = async (id) => {
    navigate("/ApplyforTimeshare", {
      state: {
        id,
      },
    });
  };
  // console.log(propertyadd);

  const navigate = useNavigate();
  const hendel = (id) => {
    localStorage.setItem("Propid", JSON.stringify(id));
    navigate("/EditProperty");
  };

  const move = (id) => {
    const newdata = {
      index: "2",
      id: { ...id, beBhai: id.property_data?.id },
    };
    navigate("/TwoPropertyDetails", {
      state: {
        newdata,
      },
    });
  };
  const Dur = (id) => {
    const newdata = {
      index: "2",
      id: { ...id, beBhai: id.property_data?.id },
    };
    navigate("/TwoPropertyDetails", {
      state: {
        newdata,
      },
    });
  };
  const propid = (id) => {
    navigate("/EditProperty", {
      state: {
        id,
      },
    });
  };
  const handledata = (id) => {
    navigate("/Cohost", {
      state: {
        id,
      },
    });
    setIdget(id);
  };
  const Popup = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* <Modal.Header closeButton>
            
          </Modal.Header> */}
          <div className="Property-card-divv-ppx">
            <div className="Property-ppx-cvv mt-2">
              <span className="spandivv-ppx-property">
                Do you want to continue?
              </span>
            </div>

            {/* <div className="Payment-divv-section">
              <div className="Payment-divv-sec-mid"></div>
            </div> */}

            <div className="Button-divv-property">
              <div className="btnn-divv-pro-class">
                <button
                  onClick={() => Navigat("/AddProperty")}
                  // onClick={(arr) =>
                  //   navigate("/AddProperty", {
                  //     state: arr,
                  //   })
                  // }
                  className="btn-new-add-main"
                >
                  Add New Property
                </button>
              </div>
              <div className="btnn-divv-pro-later">
                <button
                  onClick={() => Navigat("/MyProfile/Continueproperty")}
                  className="btn-pro-later-con"
                >
                  Continue Property
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  const GetSavelist = () => {
    setLoding(true);
    try {
      axios({
        method: "get",
        url: "/get_pending_property_list",
        headers: {
          authorization: `${token.data.token.token}`,
        },
      })
        .then((res) => res.data)
        .then((data) => {
          setLoding(false);

          setSave(data.data);
          // console.log(data);
          // if (data.message === "Pending property_list") {
          //   setShow(true);
          // }
        });
    } catch (error) {
      setLoding(false);

      // console.log(error);
    }
  };
  useEffect(() => {
    GetSavelist();
  }, []);

  const hanSand = (arr) => {
    // Navigat("/MyProfile", {
    //   state: arr,
    // });
    setokShow(false);
  };
  const handledelete = (id) => {
    setDelid(id);
  };
  const Popu = (ok) => {
    return (
      <>
        <Modal
          {...ok}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* <Modal.Header closeButton>
            
          </Modal.Header> */}
          <div className="Property-card-divv-verify">
            <div className="Property-ppx-verify mt-2">
              <span className="spandivv-ppx-property">
                You have not yet entered your bank information! Please include
                it to receive payments for bookings at your property.
              </span>
            </div>
            <div className="Button-divv-property">
              <div className="btnn-divv-pro-later">
                <button
                  className="btnnn-pro-later-main"
                  onClick={() => navigate("/BankDetails")}
                >
                  Add Bank
                </button>
              </div>
              <div className="btnn-divv-pro-class">
                <button onClick={hanSand} className="btn-prop-later-main">
                  Not Now
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };
  // console.log(url?.data?.url, "hhhhhhh");
  const Deleteproperty = (del) => {
    return (
      <>
        <Modal
          {...del}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Property-card-divv-ppx">
            <div className="Property-p-cvv mt-2">
              <span className="spandivv-ppx-property">
                Are you sure you want to Delete this Property?
              </span>
            </div>

            <div className="Button-divv-prop ">
              <div className="btnn-divv-pro-later">
                <button
                  onClick={() => setDelShow(false)}
                  className="btn-pro-later-main"
                >
                  cancel
                </button>
              </div>
              <div className="btnn-divv-pro-later">
                <button
                  onClick={() => onSubmit(delid)}
                  className="btn-okk-later-main"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Deleteproperty show={DelShow} onHide={() => setDelShow(false)} />
      <Popu show={okShow} onHide={() => setokShow(false)} />
      <Popup show={show} onHide={() => setShow(false)} />
      <section className="MyProperty-main-section">
        <div className="MyProperty-main-div">
          <div className="my-prop-and-add">
            <span className="My-prop">My Property</span>
            <div onClick={() => setShow(true)} className="add-btn-my">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_25_2755)">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
                    fill="#9C04F5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_25_2755">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="add-my-span">Add Property</span>
            </div>
          </div>
          {!Loading && propertyadd?.length >= 1 ? (
            <div className="map-upar-div">
              {propertyadd?.map((curelem, index) => {
                return (
                  <div key={index} className="mainnn-divvv-dusri-bar">
                    <div
                      className="api-imgis"
                      // onClick={() =>}\
                      onClick={() => move(curelem)}
                    >
                      <img
                        onClick={() => Dur(curelem)}
                        className="Prop-home-1"
                        src={curelem?.images[0].picture_name}
                        alt="images"
                      />
                    </div>
                    {/* {propertyadd.map((item, index) => {
                  console.log(item.images)
                  return(
                   
                  )
                })} */}
                    <div className="svg-span-div-main">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2_446)">
                          <path
                            d="M7 9.91671L3.57117 12.0109L4.50333 8.10254L1.4525 5.48921L5.45708 5.16837L7 1.45837L8.54292 5.16837L12.5481 5.48921L9.49667 8.10254L10.4288 12.0109L7 9.91671Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2_446">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>{curelem?.property_data.average_ratings}</span>
                    </div>
                    <div className="d-flex">
                      <span className="Grandview-span-1">
                        {curelem?.property_data.address}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="Accessible-span">{curelem?.Detail}</span>
                    </div>
                    <div className="mt-3 span-naghit-div-main">
                      <div>
                        <span className="night-span">
                          €{curelem?.property_data.price_per_night}
                        </span>
                        <span className="night-span-2"> / night</span>
                      </div>
                      <div className="svg-map-khud">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handledata(curelem)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_67_921)">
                              <path
                                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM7 12H9C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12H17C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_67_921">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => propid(curelem)}
                        >
                          <svg
                            width="18"
                            height="20"
                            viewBox="0 0 18 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.414 14L13.556 3.85801L12.142 2.44401L2 12.586V14H3.414ZM4.243 16H0V11.757L11.435 0.322007C11.6225 0.134536 11.8768 0.0292206 12.142 0.0292206C12.4072 0.0292206 12.6615 0.134536 12.849 0.322007L15.678 3.15101C15.8655 3.33853 15.9708 3.59284 15.9708 3.85801C15.9708 4.12317 15.8655 4.37748 15.678 4.56501L4.243 16ZM0 18H18V20H0V18Z"
                              fill="#211B24"
                            />
                          </svg>
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          // onClick={() =>
                          //   onSubmit(curelem?.property_data.id, index)
                          // }
                          onClick={() => {
                            handledelete(curelem?.property_data.id, index);
                            setDelShow(true);
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z"
                              fill="#211B24"
                            />
                            {Loading ? "Loading.." : ""}
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div className="Not-found">No properties found.</div>{" "}
            </>
          )}
        </div>
      </section>
      {!loding && <div></div>}
      {loding && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default MyProperty;
