import React, { useState } from "react";
import "../Components/Wishlist.css";
import axios from "../Schemas/Api";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Wishlist = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  const [wishlistdata, setwishlistdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [propertyadd, setPropertyadd] = useState([]);
  const Navigate = useNavigate();
  // console.log(token.token)

  const getwishlist = async () => {
    setloading(true);
    const formdata = new FormData();
    try {
      let cofe = {
        url: "/my_wishlist",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons.data);
          setwishlistdata(respons.data);
          setloading(false);
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };
  useEffect(() => {
    getwishlist();
  }, []);

  const [Loading, setLoading] = useState(false);
  const Navigat = useNavigate();
  // const onSubmit = async (id, index) => {
  //   setLoading(true);
  //   const formdata = new FormData();
  //   formdata.append("property_id", id);

  //   try {
  //     let AddProperty = {
  //       url: "/delete_property",
  //       method: "Post",
  //       data: formdata,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Accept: "application/json",
  //         authorization: `${token.data.token.token}`,
  //       },
  //     };
  //     axios(AddProperty)
  //       .then((res) => res.data)
  //       .then((respons) => {
  //         toast.success(respons.message, {
  //           position: "top-right",
  //           theme: "colored",
  //         });
  //         if (respons.message === "Property removed successfully.") {
  //           setPropertyadd(propertyadd.filter((item, i) => i !== index));
  //         }
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         if (error) {
  //           setLoading(false);
  //         }
  //       });
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };
  // changes
  const onSubmit = async (id) => {
    setloading(true);
    // console.log(id, "jjjjjjjjj");
    try {
      const res = await axios.post(
        "/wishlist",
        {
          property_id: id,
        },
        {
          headers: {
            authorization: `${token.data.token.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status === 1) {
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        getwishlist();
      } else if (res.data.status === 0) {
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        getwishlist();
      }
      setloading(false);
      // console.log(res);
    } catch (error) {
      setloading(false);
      // console.log(error);
    }
  };
  const ico = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2257)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z"
          fill="#9C04F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2257">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const ico2 = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2238)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2238">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const move = (id) => {
    const newdata = {
      index: "1",
      id: { ...id, beBhai: id.property_data?.id },
    };
    Navigate("/PropertyDetails", {
      state: {
        newdata,
      },
    });
  };

  return (
    <>
      <section className="Wishlist-main-section-Two">
        <div className="Wishlist-main-div-two">
          <div className="my-prop-and-add mt-4">
            <span className="My-prop">Wishlist</span>
          </div>
          {!loading && wishlistdata?.length >= 1 ? (
            <div className="map-upar-div">
              {wishlistdata.map((curelem, index) => {
                return (
                  <div className="mainnn-divvv-dusri-bar" key={index}>
                    <img
                      onClick={() => move(curelem)}
                      className="Prop-home-1"
                      src={curelem.images[0].picture_name}
                      alt=""
                    />
                    <div className="svg-span-div-main">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2_446)">
                          <path
                            d="M7 9.91671L3.57117 12.0109L4.50333 8.10254L1.4525 5.48921L5.45708 5.16837L7 1.45837L8.54292 5.16837L12.5481 5.48921L9.49667 8.10254L10.4288 12.0109L7 9.91671Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2_446">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>
                        {curelem.star}
                        {curelem.property_data.average_ratings}
                      </span>
                    </div>
                    <div className="new-class-span">
                      <span className="Grandview-span-1">
                        {curelem.property_data.address}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="Accessible-span">
                        {curelem.property_data.about_property.slice(0, 20)}...
                      </span>
                    </div>
                    <div className="mt-3 span-naghit-div-main">
                      <div>
                        <span className="night-span">
                          €{curelem.property_data.price_per_night}{" "}
                        </span>
                        <span className="night-span-2">/ night</span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          onSubmit(curelem?.property_data.id, index)
                        }
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z"
                            fill="#211B24"
                          />
                          {Loading ? "Loading.." : ""}
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No properties found.</div>
          )}
        </div>
      </section>
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Wishlist;
