import React from "react";
import "../Components/FindByHosts.css";
import Savannah from "../Assets/Images/Savannahimg.png";
import Boy1 from "../Assets/Images/Floydimg.png";
import Boy2 from "../Assets/Images/GuyHawkinsimg.png";
import Boy3 from "../Assets/Images/Girl4.png";
import Boy4 from "../Assets/Images/Boy5img.png";
import Boy5 from "../Assets/Images/Boy6img.png";
import Boy6 from "../Assets/Images/Girl3img.png";
import Boy7 from "../Assets/Images/Boy8img.png";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import FreeTime from "../Components/FreeTime";
import SerachaBar from "../Components/SerachaBar";
const FindByHosts = () => {
  const deta = [
    {
      image: Savannah,
      Username: "Floyd Miles",
      Location: "See her property",
    },
    {
      image: Boy1,
      Username: "Floyd Miles",
      Location: "See her property",
    },
    {
      image: Boy2,
      Username: "Guy Hawkins",
      Location: "See her property",
    },
    {
      image: Boy3,
      Username: "Guy Hawkins",
      Location: "See her property",
    },
    {
      image: Boy4,
      Username: "Arlene McCoy",
      Location: "See her property",
    },
    {
      image: Boy5,
      Username: "Albert Flores",
      Location: "See her property",
    },
    {
      image: Boy6,
      Username: "Dianne Russell",
      Location: "See her property",
    },
    {
      image: Boy7,
      Username: "Kristin Watson",
      Location: "See her property",
    },
  ];
  return (
    <>
      <FreeTime />
    </>
  );
};

export default FindByHosts;
