import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import HomePage from "./HomePage";
import FindByHosts from "./FindByHosts";
import DownloadApp from "./DownloadApp";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    if (!data) {
    } else {
      navigate("/Home");
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="Display-tag-non">
        <HomePage />
        <FindByHosts />
        <DownloadApp />
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
