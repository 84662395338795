import React, { useRef, useEffect, useState } from "react";
import "../Components/GovernmentId.css";
import TapTap from "../Assets/Images/TapTap.png";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { HiSchemas } from "../Schemas";
import { Backdrop, CircularProgress } from "@mui/material";
const initialValues = {
  identity_type: "",
};

const GovernmentId = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  // console.log(token.token.token);
  const Navigat = useNavigate();
  const inputRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [image, setImage] = useState("");
  const handleImageClick = () => {
    inputRef.current.click();
  };
  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   setImage(event.target.files[0]);
  // };
  // console.log(file);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file type is an image
      if (file.type.startsWith("image")) {
        setImage(file);
      } else {
        // Show toast message
        toast.error("Please select an image file.");
      }
    }
  };

  // const onSubmit = async () => {
  //   setloading(true);
  //   const formdata = new FormData();
  //   formdata.append("gov_id", image);
  //   formdata.append("identity_type", values.identity_type);

  //   try {
  //     let cofe = {
  //       url: "/verify_identity",
  //       method: "Post",
  //       data: formdata,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Accept: "application/json",
  //         Authorization: `${token.data.token.token}`,
  //       },
  //     };

  //     axios(cofe)
  //       .then((res) => res.data)
  //       .then((respons) => {
  //         console.log(respons, "lalalalal");
  //         if (respons.status === 0) {
  //           toast.error(respons.message, {
  //             position: "top-right",
  //             theme: "colored",
  //           });
  //         } else if (respons.status === 1) {
  //           toast.success(respons.message, {
  //             position: "top-right",
  //             theme: "colored",
  //           });
  //           Navigat("/Home");
  //         }
  //         setloading(false);
  //         if (
  //           respons.message === "Identity verification successful." &&
  //           respons.status === 1
  //         ) {
  //           Navigat("/Home");
  //         }
  //         if (
  //           respons.message === "identity verification is already done !" &&
  //           respons.status === 0
  //         ) {
  //           toast.error(respons.message, {
  //             position: "top-right",
  //             theme: "colored",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         setloading(false);
  //         toast.error(error, {
  //           position: "top-right",
  //           theme: "colored",
  //         });
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     setloading(false);
  //   }
  // };

  const onSubmit = async () => {
    setloading(true);

    const formdata = new FormData();
    formdata.append("gov_id", image);
    formdata.append("identity_type", values.identity_type);
    try {
      const response = await axios.post("/verify_identity", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `${token?.data?.token?.token}`,
        },
      });
      if (response.data.status === 0) {
        toast.error(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (response.data.status === 1) {
        toast.success(response.message, {
          position: "top-right",
          theme: "colored",
        });
        localStorage.setItem("login", JSON.stringify(response.data));

        Navigat("/Home");
      }
      // if (response.data.status === 1) {
      //   toast.success(response.message, {
      //     position: "top-right",
      //     theme: "colored",
      //   });

      //   Navigat("/Home");
      // }
      if (
        response.message === "identity verification is already done !" &&
        response.data.status === 0
      ) {
        toast.error(response.message, {
          position: "top-right",
          theme: "colored",
        });
        localStorage.setItem("login", JSON.stringify(response.data));
        Navigat("/Home");
      }

      setloading(false);
      // console.log(response, "identity response");
    } catch (errors) {
      setloading(false);
      // console.log(errors, "errors");
    }
  };
  useEffect(() => {
    if (token?.data?.identity_verified === true) {
      Navigat("/Home");
    }
  }, []);

  const resentemail = async () => {
    setloading(true);
    const formdata = new FormData();
    try {
      let cofe = {
        url: "/resend_verification",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `${token?.data?.token?.token}`,
        },
      };

      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons);
          setloading(false);
          if (respons.status === 0) {
            toast.error(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          setloading(false);
          toast.error(error, {
            position: "top-right",
            theme: "colored",
          });
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };

  const { values, handleBlur, touched, handleChange, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: HiSchemas,
      onSubmit,
    });
  return (
    <>
      <div className="Gov-id-ver-main-div mt-2">
        <div className="Gov-driv-sec-divv">
          <form onSubmit={handleSubmit}>
            <div className="Gov-driv-lic-pass-main">
              <div className="Identity-span-divv">
                <span className="iden-span-tagg">Identity Verification</span>
              </div>
              <div className="Gov-driv-lic-pas-divv-ppx">
                <div className="Gov-id-ver-main">
                  <div className="Gov-idd-span-divv">
                    <select
                      className="Dropdown-gov-id-divv"
                      name="identity_type"
                      value={values.identity_type}
                      onChange={handleChange}
                      // placeholder={"Enter Phone number"}
                      onBlur={handleBlur}
                    >
                      <option value="" disabled selected hidden>
                        Choose Identity Proof
                      </option>
                      <option value="Identity Card"> Identity Card</option>
                      <option value="Driving License">Driving License</option>
                      <option value="Photo of Passport">
                        Photo of Passport
                      </option>
                    </select>
                    {errors.identity_type && touched.identity_type ? (
                      <p className="form-errror">{errors.identity_type} </p>
                    ) : null}
                  </div>

                  <div className="box-containor">
                    <div onClick={handleImageClick}>
                      {image ? (
                        <img
                          className="Tap-to-imgg"
                          src={URL.createObjectURL(image)}
                        />
                      ) : (
                        <img className="Default-to-imgg" src={TapTap} />
                      )}
                      <input
                        className="File-img-gov"
                        onChange={handleImageChange}
                        type="file"
                        name="gov_id"
                        ref={inputRef}
                        value={values.gov_id}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-identity-gov-divv">
                <button
                  // onClick={onSubmit}
                  type="submit"
                  className="Btn-save-Identity"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
          <div className="resend-email-divv-ppx">
            <a className="resend-email-tagg-ppx" onClick={() => resentemail()}>
              Resend email
            </a>
          </div>
        </div>
      </div>
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default GovernmentId;
