import React, { useState, useEffect } from "react";
import "../Components/Requestfortimeshare.css";
import axios from "../Schemas/Api";
import LoginNavbar from "./LoginNavbar";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { format } from "date-fns"; // Import the format function from date-fns
import moment from "moment";

const Requestfortimeshare = () => {
  const [startDate, setStartDate] = useState(new Date());
  const token = JSON.parse(localStorage.getItem("login"));
  const [loading, setloading] = useState(false);

  let route = useLocation();
  const getdata = route.state?.id[0];

  // console.log(getdata?.property_data?.id, "hhhhhhhh");
  // console.log(getdata?.user_property_id, "hhhhhhhh");

  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("property_id", getdata?.property_data?.id);
    formdata.append("start_date", moment(startDate).format("YYYY-MM-DD")); // Format the startDate as "dd/MM/yyyy"
    // console.log(moment(startDate).format("YYYY-MM-DD"), "gggggggggg");
    formdata.append("user_property_id", getdata?.user_property_id);
    // console.log(formdata, "sdghsvdg......");
    try {
      let cofe = {
        url: "/apply_for_timeshare",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token.data.token.token}`,
        },
      };

      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons);
          if (respons.status === 0) {
            toast.error(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          } else if (respons.stutus === 1) {
            toast.success(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          setloading(false);
          if (respons.status === 1) {
            // Navigat("/MyProfile/MyBookings");
          }
        })
        .catch((error) => {
          setloading(false);
          toast.error(error, {
            position: "top-right",
            theme: "colored",
          });
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };

  // const {
  //   handleBlur,
  //   handleChange,
  //   handleSubmit,
  //   setFieldValue,
  //   touched,
  //   errors,
  //   values,
  // } = useFormik({
  //   // initialValues: initialValues,
  //   onSubmit,
  // });

  return (
    <>
      <div className="req-time-main-divv">
        <div className="req-timee-section-div">
          {/* <form onSubmit={handleSubmit}> */}
          <div className="req-time-spann-divv">
            <span className="Check-boxx-apply-divv">Request for timeshare</span>
          </div>
          <div className="reqest-time-start-btn">
            <div className="select-start-date-divv">
              <div className="select-span-divv-date ">
                <span className="select-divv-start-spann">
                  Select start date
                </span>
              </div>
              <div className="select-number-divv-date">
                <input
                  type="date"
                  className="date-picker-time-box"
                  value={format(startDate, "yyyy-MM-dd")} // Set the value in "yyyy-MM-dd" format for the input
                  onChange={(e) => setStartDate(new Date(e.target.value))}
                />
              </div>
            </div>
            <div className="Btn-time-select mt-4">
              <button
                type="submit"
                onClick={() => onSubmit()}
                className="btn-req-time-form"
              >
                Request for timeshare
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
};

export default Requestfortimeshare;
