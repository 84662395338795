import React from "react";
import "../Components/Congratulations.css";
import con from "../Assets/Images/CongreatsIMg.png";
import LoginNavbar from "./LoginNavbar";
import Footer from "./Footer";
import {useNavigate} from "react-router-dom";
const Congratulations = () => {
  const Navigate = useNavigate();
  return (
    <>
      <LoginNavbar />
      <section className="Congratulations-main-section">
        <div className="Congratulations-main-div">
          <div className="thard-con-div">
            <div>
              <img className="con-img-w" src={con} alt="" />
            </div>
            <div className="mt-4">
              <span className="failed-span">Congratulations!</span>
            </div>
            <div className="successfully-div mt-3">
              <span className="like-span">
                You have successfully booked your accommodation.
              </span>
            </div>
            <div className="mt-4">
              <button className="ok-btn" onClick={() => Navigate("/Home")}>
                OK
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Congratulations;
