import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../Components/GuestPopup.css";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../GlobalContext";

const GuestPopup = (props) => {
  const navigate = useNavigate();
  //   const [Okshow, setOkShow] = useState(true);
  const { Okshow, setOkShow } = useContext(GlobalContext);

  const handleClose = () => {
    setOkShow(false);
  };
  const handlelogout = () => {
    localStorage.removeItem("login");
    navigate("/");
    setOkShow(false);
  };

  const Popu = (props) => {
    return <></>;
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="Property-card-divv-ppx">
          <div className="Property-ppx-cvv-00 mt-2">
            <span className="spandivv-ppx-property-00">
              Sorry,you are logged in as a guest.To use all features,please log
              in to your account or create an account
            </span>
          </div>
          <div className="Button-divv-property">
            <div className="btnn-divv-pro-class">
              <button
                className="btn-property-del-main-09"
                onClick={handleClose}
              >
                Back
              </button>
            </div>
            <div className="btnn-divv-pro-later">
              <button className="btn-pro-Del-main-0" onClick={handlelogout}>
                Ok
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GuestPopup;
