import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import LoginNavbar from "../Components/LoginNavbar";

const Whoarewe = () => {
  const bhura = localStorage.getItem("login");

  return (
    <>
      {!bhura ? <Navbar /> : <LoginNavbar />}

      <div className="Wo-ve-are-sec">
        <div className="main-second-thord-thar">
          <div className="main-sec-Thereviewproces">
            <div className="op-elac">
              <span className="time-span-text">
                Who are we? And what we can do for you.
              </span>
            </div>
            <div className="culam-span mt-4">
              {" "}
              <span className="free-span-hoe">
                {/* <i>@home Vacation Rentals</i> presented as a 2-in-1 holiday
                accommodation and Homeswap mobile Application. We not only
                facilitate accommodation bookings but also provide an optional
                <b>FREE</b> hosts homeswap for some property owners registered
                in our platform. */}
                <i>@home Vacation Rentals</i> is an online marketplace presented
                as a 2-in-1 holiday accommodation and Homeswap mobile
                Application. We not only facilitate accommodation bookings but
                also provide an optional <b>FREE</b> homeswap for the hosts
                registered on our platform.
              </span>
            </div>
            <span className="free-span-hoe mb-4">
              {/* <i>@home Vacation Rentals</i> is essentially a broker, who
              facilitates short term rental transactions between travelers and
              home owners wanting to rent out their properties/spaces. We differ
              from existing similar service providers as we reward some of our
              hosts with a 1 week <b>FREE</b> accommodation holiday plan. That's
              our way of showing our gratitude to you for being a part of our
              story. */}
              <i>@home Vacation Rentals</i> is essentially a broker, who
              facilitates short term rental transactions between travelers and
              home owners/hosts wanting to rent out their properties/spaces. We
              differ from existing similar service providers in that we reward
              our hosts with an optional <b>FREE</b> accommodation holiday plan.
              That's our way of showing our gratitude to you for being a part of
              our story.
            </span>
            <span className="missing-span">
              {/* Our mission is to provide diversely, promote togetherness and
              expand opportunities. */}
              Our mission is to provide diversely, promote togetherness and
              expand opportunities.
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Whoarewe;
