import React from "react";
import "../Components/Timesharerules.css";
import Navbar from "../Components/Navbar";
import Footer from "./Footer";
import LoginNavbar from "../Components/LoginNavbar";

const Guestrules = () => {
  const Beta = localStorage.getItem("login");
  return (
    <>
      {!Beta ? <Navbar /> : <LoginNavbar />}

      <div className="Timeshare-main-section-div">
        <div className="time-share-middle">
          <div className="timeshare-span-div">
            <span className="time-span-text">
              Guest rules, regulations and recommendations
            </span>
          </div>
          <div className="Main-para-div mt-4">
            <span className="italic-taxt">
              Lets go over some basic ground rules to help us get along.
            </span>
          </div>
          <div className="first-point-divv mt-4 d-flex">
            <span className="first-span-tagg-ppz">1.</span>
            <span className="ek-ka-ek-bug">
              Respect the house rules! After all, you agreed to them when you
              confirmed your booking.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">2.</span>
            <span className="ek-ka-ek-bug">
              Please be mindful of the fact that often, your hosts have
              neighbors that may not appreciate loud noises early in the morning
              or late at night.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">3.</span>
            <span className="ek-ka-ek-bug">
              If you are planning to have any sort of gathering which will
              include any guests not included in the original booking, you are
              required to obtain prior approval from the host.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">4.</span>
            <span className="ek-ka-ek-bug">
              @home Vacation Rentals users must be over the age of 18.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">5.</span>
            <span className="ek-ka-ek-bug">
              It is expressly against our policy that a guest attempts or agrees
              to mutually (with the host) submit false reviews. Such actions are
              viewed as hostile to our users as they intend to defraud and/or
              mislead users and will result in the suspension and/or blocking of
              the parties concerned for a limited time or indefinitely (
              depending on the severity of the transgression)
            </span>
          </div>

          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">6.</span>
            <span className="ek-ka-ek-bug">
              It is strictly against policy to make any payments directly to the
              host. There is no circumstance in which the host may ask you for a
              payment in cash or direct bank transfer. Should a dispute arise,
              if you are found to be entitled to a refund, you will not be
              refunded for any such payments you made. You will also be blocked
              from using the platform if found to have made any such payments.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">7.</span>
            <span className="ek-ka-ek-bug">
              We strongly recommend that you keep all communication within the
              platform. This will ensure that there is always a trail to refer
              back to should any issues arise. We cannot assist in dispute
              resolution if we do not have both sides of a story and all the
              facts.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">8.</span>
            <span className="ek-ka-ek-bug">
              It is expressly against our policy that guests attempt to: book,
              re-book or extend a stay directly with your host bypassing the
              platform. This will result in the immediate removal of the
              concerned parties from the platform.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">9.</span>
            <span className="ek-ka-ek-bug">
              We recommend that upon check-in the guest photographs and reports
              (both to the host and on the App) any damages. This will assist
              both sides in resolving eventual disputes that may arise.
            </span>
          </div>
          <div className="first-point-divv mt-2 d-flex">
            <span className="first-span-tagg-ppz">10.</span>
            <span className="ek-ka-ek-bug">
              If there are any significant damages caused by the guest (which
              the guest does not dispute within 72 hours of this being reported
              by the host), and reported by the host within 24 hours of the
              guests checkout, the guest is liable to pay for such damages in
              full or partially (depending on the circumstances).
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Guestrules;
