import React, { useEffect, useState } from "react";
import "../Components/MyWallet.css";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "../Schemas/Api";
import moment from "moment";
import Footer from "./Footer";
import LoginNavbar from "./LoginNavbar";
import back from "../Assets/Images/Backicon.png";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Paymenthistory = () => {
  const token = JSON.parse(localStorage.getItem("login"));
  const [loding, setLoding] = useState(false);
  const [Loading, setLoading] = useState(false);

  const [Propaty, setproperty] = useState([]);
  const Navigate = useNavigate();
  const GetSavelist = () => {
    setLoding(true);
    axios({
      method: "get",
      url: "/payment_sent",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setLoding(false);
        setproperty(data.payment_list);
        // console.log("beta !!!!!", data.payment_list);
      })
      .catch((err) => {
        setLoding(false);
        // console.log(err);
      });
  };
  useEffect(() => {
    GetSavelist();
  }, []);

  const handledetail = async (id) => {
    Navigate("/Invoicehistory", {
      state: {
        id,
      },
    });
  };
  return (
    <>
      <LoginNavbar />
      <div>
        <span className="backbtn" onClick={() => Navigate(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <section className="My-Wallet-section">
        <div className="My-Wallet-div">
          <div className="doler-div">
            <div className="histry-input-btn-div">
              <div className="ignor-div">
                <span className="income-span"> Payment History</span>
              </div>
              <div className="d-p-sn">
                <div className="store-name-div">
                  <span className="store-span">Name</span>
                </div>
                <div className="date-price-div">
                  <span className="date-span">Date</span>
                  <div>
                    <span className="price-span">Price</span>
                  </div>
                </div>
              </div>
              {!Loading && Propaty?.length >= 1 ? (
                <div>
                  {Propaty.map((curelem, index) => {
                    return (
                      <div key={index} className="d-p-sn-div">
                        <div className="store-name-div">
                          <img
                            className="Texas1-img"
                            src={curelem.property_image}
                            alt=""
                          />
                          <span className="store-span-1">
                            {curelem.property_address}
                          </span>
                        </div>
                        <div className="date-price-11">
                          <span className="date-span-1">
                            {" "}
                            {moment(curelem.date)
                              .format("YYYY-MM-DD")
                              .toString()}
                          </span>
                          <div>
                            <span
                              className="price-span-1"
                              onClick={() => {
                                handledetail(curelem);
                              }}
                            >
                              {curelem.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="Not-found">No payments yet.</div>
              )}
            </div>
          </div>
        </div>
      </section>
      {!loding && <div></div>}
      {loding && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Paymenthistory;
