import React from "react";
import "../Components/Improtant.css";
import { useNavigate } from "react-router-dom";
import houseimg from "../Assets/Images/houseimg.png";
import brothers from "../Assets/Images/brothersimg.png";
import kyeimg from "../Assets/Images/keyimg.png";
import Footer from "./Footer";

const Improtant = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="imptant-sec-main">
        <div className="imptant-div-main">
          <div className="How-it-all">
            <span className="information-span">Important information</span>
          </div>
          <div className="contain-impotant-div">
            <div className="Home-and-saport-nav">
              <div>
                <div>
                  <span className="second-home-span">@Home</span>
                </div>
                <div className="Newsroom-div">
                  <span
                    className="Newsroom-span"
                    onClick={() => navigate("/Whoarewe")}
                  >
                    Guest rules regulation
                  </span>
                </div>
                <div className="span-div">
                  <span
                    onClick={() => navigate("/Whoarewe")}
                    className="Learn-about-new-span"
                  >
                    Who are we
                  </span>
                </div>
                <div className="span-div">
                  <span
                    className="Learn-about-new-span"
                    onClick={() => navigate("/Thereviewprocess")}
                  >
                    The review process
                  </span>
                </div>
                <div className="span-div">
                  <span
                    className="Learn-about-new-span"
                    onClick={() => navigate("/Timesharerules")}
                  >
                    Timeshare rules
                  </span>
                </div>
              </div>
              <div className="Support-all-span">
                <div className="Newsroom-div">
                  <span
                    className="Newsroom-span"
                    onClick={() => navigate("/BookingFees")}
                  >
                    Booking fees home
                  </span>
                </div>
                <div className="span-div">
                  <span
                    className="Learn-about-new-span"
                    onClick={() => navigate("/Timesharepoolstarranking")}
                  >
                    Timeshare pool star ranking
                  </span>
                </div>
                <div className="span-div">
                  <span
                    className="Learn-about-new-span"
                    onClick={() => navigate("/Recommendations")}
                  >
                    Host rules regs and recommendations
                  </span>
                </div>
              </div>
              <div>
                <div className="alll-mainn-divv">
                  <h3>How it work</h3>
                  <div>
                    <div>
                      <div className="card-main-div-3">
                        <img className="houseimg-img-1" src={houseimg} alt="" />
                        <div className="Duis-main-div">
                          <span className="Duis-efficitur-span">
                            For travellers
                          </span>
                        </div>
                        <div className="Vivamus-et-consequat-span">
                          <span className="Vestibulum-span">
                            Download the App and register.Seach for your
                            preferred accommodation. Confirm and pay for your
                            booking. Done!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card-main-div-3">
                        <img className="houseimg-img-1" src={brothers} alt="" />
                        <div className="Duis-main-div">
                          <span className="Duis-efficitur-span">
                            Hosts free timeshare
                          </span>
                        </div>
                        <div className="Vivamus-et-consequat-span">
                          <span className="Vestibulum-span">
                            Apply to add your listed property to the hosts
                            timeshare pool.Once your property is accepted and
                            graded, agree to the timeshare rules.Book your free
                            accommodation and enjoy!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card-main-div-3">
                        <img className="houseimg-img-1" src={kyeimg} alt="" />
                        <div className="Duis-main-div">
                          <span className="Duis-efficitur-span">For hosts</span>
                        </div>
                        <div className=" Vivamus-et-consequat-span">
                          <span className="Vestibulum-span">
                            Download the App and register.List your
                            property/space according to the terms best suited to
                            you.Sit back and let us direct your guests to you.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Improtant;
