import React from "react";
import logo from "../Assets/Images/Loginlogo.png";
import Line from "../Assets/Images/LineImg.png";
import intrial from "../Assets/Images/InterialImg.png";
import sedo from "../Assets/Images/Sedo.png";
import "../Components/ForgotPassword.css";
import { useFormik } from "formik";
import { OffSchemas } from "../Schemas";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
const initialValues = {
  email: "",
};
const ForgotPassword = () => {
  const Navigat = useNavigate();
  const onSubmit = async () => {
    const formdata = new FormData();

    formdata.append("email", values.email);
    try {
      let cofe = {
        url: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjQsImV4cCI6MTcxMTc2ODc3M30.kD-pPl1R0burhAhpEsxza2VXRgcduIhFLlZteTivJ3E/user/reset_request",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons);
          localStorage.setItem("login", JSON.stringify(respons));
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: OffSchemas,
      onSubmit,
    });

  // console.log(errors);

  return (
    <>
      <section className="Login-main-sec">
        <div className="Login-div-main">
          <div className="img-div-p">
            <img className="girl-img-main" src={intrial} alt="" />
          </div>
          <div className="sedo-div">
            <img className="girl-img-main" src={sedo} alt="" />
          </div>
          <div className="span-div-p">
            <span className="find-tha-span">Find the perfect place</span>
            <div className="mt-3">
              <span className="perfect-span">
                Find the perfect home for your holiday accommodation
              </span>
            </div>
          </div>
          <div>
            <div className="i-m-g-div">
              <img className="Login-logo-img" src={logo} alt="" />
              <div>
                <span className="ho-span"> @home</span>
              </div>
              <div className="mt-3">
                <span className="l-o-span">Forgot Password</span>
              </div>
              <div className="please-span mt-3">
                <span>
                  Please enter the email address you ‘d like to your password
                  reset information sent to.
                </span>
              </div>
              <form onSubmit={handleSubmit} className="svg-p-div mt-4">
                <div>
                  <svg
                    className="svg-margin"
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM18 4.238L10.072 11.338L2 4.216V16H18V4.238ZM2.511 2L10.061 8.662L17.502 2H2.511Z"
                      fill="#211B24"
                    />
                  </svg>
                </div>
                <input
                  className="i-t-a"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </form>
              {errors.email && touched.email ? (
                <p className="mastu-to-eroor">{errors.email} </p>
              ) : null}

              <div className="btn-margin">
                <button type="submit" className="Lo-btn-1">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
