import React, { useState } from "react";
import "../Components/RateThisProperty.css";
import { GrStar } from "react-icons/gr";
import Close from "../Assets/Images/close2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";

const initialValues = {
  review: "",
};

const RateThisProperty = () => {
  const [selected, setSelected] = useState(1);
  let route = useLocation();
  const dataget = route.state.item;
  // console.log(dataget.booking_id, "sghdgsygdys");
  const StarGenerator = (numofstar = 5) => {
    // console.log(selected, "seleted");
    return Array(numofstar)
      .fill()
      .map((item, i) => (
        <Star
          key={i}
          selected={selected > i}
          onSelected={() => setSelected(i + 1)}
        />
      ));
  };

  const Star = ({ selected, onSelected }) => {
    return (
      <GrStar
        className="fastar-mnb"
        color={selected ? "goldenrod" : "gray"}
        onClick={onSelected}
      />
    );
  };
  const token = JSON.parse(localStorage.getItem("login"));
  // console.log(token.data.token.token);
  const Navigat = useNavigate();
  const [loading, setloading] = useState(false);
  const [ReviewText, setReviewText] = useState();
  // console.log(ReviewText);
  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("rating", selected);
    formdata.append("review", ReviewText);
    formdata.append("booking_id", dataget.booking_id);
    // console.log(formdata, "sdghsvdg......");
    try {
      let cofe = {
        url: "/review",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token.data.token.token}`,
        },
      };

      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          if (respons.status === 0) {
            toast.error(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          } else if (respons.status === 1) {
            toast.success(respons.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          setloading(false);
          if (respons.status === 1) {
            Navigat("/MyProfile/MyBookings");
          }
        })
        .catch((error) => {
          setloading(false);
          toast.error(error, {
            position: "top-right",
            theme: "colored",
          });
        });
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };

  const { handleSubmit } = useFormik({
    initialValues: initialValues,

    onSubmit,
  });
  // console.log(values.review, "IIIIIIIIIIII");

  return (
    <>
      <div className="Model-wrap"></div>
      <section className="RateThisProperty-sec">
        <div className="RateThisProperty-main-divv">
          <form onSubmit={handleSubmit}>
            <div className="thard-div-rating">
              <div className="svg-chaing">
                <button
                  onClick={() => Navigat("/MyProfile/MyBookings")}
                  className="btn-close-img-dono"
                >
                  <img className="close-btn-img" src={Close} alt="" />
                </button>
              </div>
              <div className="svg-pasword">
                <span className="font-chang">Rate this Property</span>
              </div>
              <div className="border-line"></div>
              <div className="mt-4 Grandview-div">
                <img
                  className="rating-img"
                  src={dataget.property_images[0].picture_name}
                  alt=""
                />
                <div className="us-usa-div">
                  <span className="us-span">{dataget.address}</span>
                  <span className="usa-span">
                    {dataget.amenities[0]?.amenity}
                  </span>
                </div>
              </div>
              <div className="mt-5 star-rating-div">{StarGenerator(5)}</div>
              <div className="text-span-div">
                <div className="span-you-div">
                  <span>Write your review</span>
                </div>
                <textarea
                  id="w3review"
                  name="message2"
                  rows="4"
                  className="here-input-2   mt-2"
                  cols="50"
                  value={ReviewText}
                  onChange={(e) => {
                    setReviewText(e.target.value);
                  }}
                  placeholder="Write here"
                ></textarea>
              </div>
              <div className="mt-4">
                <button type="submit" className="Submit-btn-div-22">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default RateThisProperty;
