// import {initializeApp} from "firebase/app";
// import {getMessaging} from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyCmXSuYqeQr53hsDrH2EiGDeFce7u-zdxk",
//   authDomain: "home1-3df4e.firebaseapp.com",
//   projectId: "home1-3df4e",
//   storageBucket: "home1-3df4e.appspot.com",
//   messagingSenderId: "868675651470",
//   appId: "1:868675651470:web:ec812fbb6d237877e546df",
// };

// export const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging(app);

import {initializeApp} from "firebase/app";
import {FacebookAuthProvider, getAuth, GoogleAuthProvider} from "firebase/auth";
import {getMessaging} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBz9CnpqijLQigLJrl3cDtieEGEgNsCF70",
  authDomain: "home-1df4d.firebaseapp.com",
  projectId: "home-1df4d",
  storageBucket: "home-1df4d.appspot.com",
  messagingSenderId: "522797542758",
  appId: "1:522797542758:web:e3e49bbc98a79c13076ca7",
  measurementId: "G-9CQVDYE77C",
  // apiKey: "AIzaSyAkAd0XWniKOHGr04kK3C-ozFsiQx4xcRE",
  // authDomain: "homeapp-3b640.firebaseapp.com",
  // projectId: "homeapp-3b640",
  // storageBucket: "homeapp-3b640.appspot.com",
  // messagingSenderId: "207907725073",
  // appId: "1:207907725073:web:7882e353c8b1d21f32b6b0",
  // measurementId: "G-GLGS5GKS8X",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const GoogleProvider = new GoogleAuthProvider();

const FacebookProvider = new FacebookAuthProvider();

export {auth, GoogleProvider, FacebookProvider};

export const messaging = getMessaging(app);
