import React, { useState, useEffect } from "react";
import back from "../Assets/Images/Backicon.png";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import "../Components/SearchProparty.css";
import Footer from "./Footer";
import LoginNavbar from "./LoginNavbar";
const SearchProparty = () => {
  const navigate = useNavigate();
  const [conproperty, setConproperty] = useState([]);
  const [propertyadd, setPropertyadd] = useState([]);
  const [loading, setloading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const route = useLocation();
  const token = JSON.parse(localStorage.getItem("login"));
  const stateData = route.state;
  const [NavigateKaPuraData, setNavigateKaPuraData] = useState(
    stateData.searchResults.formKaData
  );
  const [searchResult, setSearchResult] = useState(
    stateData.searchResults.mapKaData.Datares.properties
  );

  const ApiChalaoooo = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("address", NavigateKaPuraData.address);
    formdata.append("adult", NavigateKaPuraData.adult);
    formdata.append("child", NavigateKaPuraData.child);
    formdata.append("start_date", NavigateKaPuraData.start_date);
    formdata.append("end_date", NavigateKaPuraData.end_date);

    try {
      let Sea = {
        url: "/search_property",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(Sea)
        .then((res) => res.data)
        .then((respons) => {
          // console.log("Response mil gaya bhai Mojjjj...!!!", respons);
          setSearchResult(respons.data.properties);
          setLoading(false);
        });
    } catch (error) {
      // console.log("Error aa gai bhai bhago...!!!", error);
      setLoading(false);
    }
  };

  const wishlist = async (id) => {
    const data = {
      property_id: id,
    };

    try {
      const res = await axios.post(
        "/wishlist",
        {
          property_id: data.property_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token.data.token.token}`,
          },
        }
      );

      if (res.data.status === 1) {
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        ApiChalaoooo();
      } else if (res.data.status === 0) {
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        ApiChalaoooo();
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handledetail = async (id) => {
    const newdata = {
      index: "1",
      id: { ...id, beBhai: id.property_detail?.id },
      PuraData: { milja: stateData },
    };
    navigate("/PropertyDetails", {
      state: {
        newdata,
      },
    });
  };

  const ico2 = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2238)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2238">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const ico = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_2257)">
        <path
          d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z"
          fill="#9C04F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_2257">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  return (
    <>
      <LoginNavbar />
      <div>
        <span className="backbtn" onClick={() => navigate(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <section className="Searchedpr-main-section">
        <div className="MyProperty-main-div">
          <div className="w-100 mb-3">
            <span className="My-prop">Searched Property</span>
          </div>
          {!Loading && searchResult?.length >= 1 ? (
            <div className="map-upar-div-serach">
              {searchResult?.map((curelem, index) => {
                // console.log(curelem, "ek he aa rhe haa");
                return (
                  <div key={index} className="mainnn-divvv-dusri-bar">
                    <div className="api-imgis">
                      <img
                        onClick={() => handledetail(curelem)}
                        className="Prop-home-sera"
                        src={curelem?.images[0].picture_name}
                        alt="images"
                      />
                    </div>
                    <div className="d-flex">
                      <span className="Grandview-span-1">
                        {curelem.property_detail?.address}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="Accessible-span">{curelem?.Detail}</span>
                    </div>
                    <div className="mt-3 span-naghit-div-main">
                      <div>
                        <span className="night-span">
                          ${curelem.property_detail.price_per_night}
                        </span>
                        <span className="night-span-2"> / night</span>
                      </div>
                      <div onClick={() => wishlist(curelem.property_detail.id)}>
                        {curelem.property_detail.wishlist.wishlist === true ? (
                          <span>{ico}</span>
                        ) : curelem.property_detail.wishlist.wishlist ===
                          false ? (
                          <span>{ico2}</span>
                        ) : (
                          <span>{ico2}</span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No properties found.</div>
          )}
        </div>
      </section>
      {Loading && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default SearchProparty;
