import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "./Payment";
const Stripecontainor = () => {
  const stripePromise = loadStripe(
    "pk_live_51PPL0kBctkE99BgUhZu6D8LFqCy27NSZOzB7NnRGr4WMSaOQsajc7UeRglGEzTKK3bBObpbeO0SnF5jgzKZLoSAu00nJv6Hvzm"
    // "pk_test_51MedQAFCatXUefOhO5ncew3NbXK0iKxJPXVu1Mf7Z1VqHF6yob98hpuwjhScJacLu856jlDo29e6W6wm5jOwbpHW00DEZqSjTc"
    // "pk_test_51MoURYABa0Gyguy7hL33dJHcBhTsEYpWKTrgzaMSwQZ4J89cf9dx2xKBlCZjgvRV9zUP0tns2WsNXHkXJxLz5kXj00fXBoCY9o"
  );
  // console.log(stripePromise);
  return (
    <Elements stripe={stripePromise}>
      <Payment />
    </Elements>
  );
};

export default Stripecontainor;
// const fakecard = async (event) => {
//   console.log("click");
//   event.preventDefault();

//   if (!stripe || !elements) {
//     return;
//   }
//   setloading(true);

//   const cardElement = elements.getElement(CardElement);
//   const result = await stripe.createToken(cardElement);
//   console.log(result, "result");

//   if (result && result.token) {
//     console.log(result.token.id, "token");
//     console.log(result.token.card.id, "card id");

//     try {
//       const response = await axios.post(
//         "/add_card",
//         {
//           card_id: result.token.card.id,
//           card_token: result.token.id,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `${token?.data?.token?.token}`,
//           },
//         }
//       );
//       console.log(response, "response");
//       if (response.status === 1) {
//         toast.success(response.message, {
//           position: "top-right",
//           theme: "colored",
//         });
//       } else if (response.status === 0) {
//         toast.error(response.message, {
//           position: "top-right",
//           theme: "colored",
//         });
//       }
//       setloading(false);
//     } catch (error) {
//       toast.error(error.message, {
//         position: "top-right",
//         theme: "colored",
//       });
//       setloading(false);
//       console.log(error, "error");
//     }
//   } else {
//     console.log("Token creation failed or result.token is undefined.");
//     setloading(false);
//   }
// };
