import React, { useState, useEffect, useRef, useContext } from "react";
import "../Components/LoginNavbar.css";
import logowhite from "../Assets/Images/Home logo white.png";
import Smilyboy from "../Assets/Images/Smilyboy.png";
import Dropdown from "react-bootstrap/Dropdown";
import { Spin as Hamburger } from "hamburger-react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { GlobalContext } from "../GlobalContext";
import axios from "../Schemas/Api";
import blacklogo from "../Assets/Images/Home logo.png"

const LoginNavbar = () => {
  const UserData = JSON.parse(localStorage.getItem("login"));
  const [isOpen, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const token = JSON.parse(localStorage.getItem("login"));
  const [userdeta, setuserdeta] = useState([])

  let menuref = useRef();
  const { setOkShow } = useContext(GlobalContext);

  const Getuser = async () => {
    try {
      setloading(true);
      const res = await axios.get("/getuser", {
        headers: { authorization: `${token.data.token.token}` },
      });
      if (res.data.status === 1) {
        // console.log("Get-User....", res);
        setloading(false);
        setuserdeta(res.data.data);
      } else if (res.data.status === 0) {
        // console.log("Get-User....", res);
        setloading(false);
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        navigate("/");
        localStorage.removeItem("login");
      }
      {
        setloading(false);
        // console.log("Bhai error aa rahi hai jam k", res);
      }
    } catch (error) {
      // console.log("error hai bhai", error);
      setloading(false);
      if (error.message === "Network Error") {
        localStorage.removeItem("login");
        Navigat("/");
        toast.error(error.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    }
  };
  useEffect(() => {
    Getuser();
  }, []);
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setOpen(false);
        }
      }
    };
    // document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  });
  const Navigat = useNavigate();
  const handletoggle = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (!localStorage.getItem("login")) {
      Navigat("/");
    }
  }, []);

  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handlelogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("filterdata");
    toast.success("User Logged Out Successfully", {
      position: "top-right",
      theme: "colored",
    });
    navigate("/");
    setShow(false);
    // window.location.reload(true);
    // window.history.pushState({}, "", "/");
    navigate("/", { replace: true });
  };
  const no = () => {
    navigate("/Home");
    setShow(false);
  };

  const handleguest = () => {
    if (UserData.data.is_guest === true) {
      setOkShow(true);
    } else {
      Navigat("/AddProperty");
    }
  };
  const handleguest1 = () => {
    if (UserData.data.is_guest === true) {
      setOkShow(true);
    } else {
      Navigat("/MyProfile/PersonalInformation");
    }
  };

  const Popup = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="Property-card-divv-ppx">
            <div className="Property-p-cvv mt-2">
              <img className="Work_connectors_logo_blue" src={blacklogo} />
            </div>
            <div className="Property-p-cvv mt-2">
              <span className="spandivv-ppx-property">
                Are you sure you want to log out?
              </span>
            </div>

            <div className="Button-divv-prop">
              <div className="btnn-divv-pro-class">
                <button
                  onClick={handlelogout}
                  className="btn-property-stripe-main"
                >
                  Log Out
                </button>
              </div>
              <div className="btnn-divv-pro-later">
                <button onClick={no} className="btn-prop-later-main">
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Popup show={show} onHide={() => setShow(false)} />
      <div className="login-home-navbar-hamburger-divv">
        <div className="login-home-navbar-main-Divv">
          <div className="login-home-containerr-div">
            <div onClick={() => Navigat("/Home")} className="homefooterlogo2">
              <img className="Home-logo-imgfooter" src={logowhite} alt="" />
            </div>
            <div className="Navbar-main-divv-ppx">
              <div className="Home-about-contactus-divv">
                <div className="Home-spann-section-divv">
                  <NavLink
                    activeclassName={"active"}
                    to={"/Home"}
                    className="Home-spann-sec-para-wwt"
                  >
                    Home
                  </NavLink>
                </div>
                <div className="Home-spann-section-divv">
                  <NavLink
                    activeclassName={"active"}
                    to={"/Who-are-we"}
                    className="Home-spann-sec-para-wwt"
                  >
                    Who We Are
                  </NavLink>
                </div>
                <div className="Home-spann-section-divv">
                  <a
                    href="mailto:info@homevr.online"
                    className="Home-spann-sec-para-wwt"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="Profile-photo-sec-Main">
                <div className="prof-photo-imgg-divv">
                  <img
                    className="prof-photo-imgg-smile-circle"
                    src={userdeta?.profile_pic}
                  />
                </div>
                <span className="Home-spann-sec-para-wwt">
                  {userdeta?.fullname}
                </span>
                <div className="prof-photo-Spann-divv">
                  <div className="prof-photo-Spann-happy-divv">
                    <span className="prof-photo-spnnn-smile-circle"></span>
                  </div>
                  <div className="prof-photo-Spann-Saddd-divv">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="prof-dropdownn-Saddd-divv"
                        id="dropdown-button-dark-example1"
                        variant="secondary"
                      >
                        <svg
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.00016 3.172L7.82816 0.343002L9.24316 1.757L5.00016 6L0.757164 1.757L2.17216 0.343002L5.00016 3.172Z"
                            fill="white"
                          />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark">
                        <Dropdown.Item
                          active
                          // onClick={() =>
                          //   Navigat("/MyProfile/PersonalInformation")
                          // }
                          onClick={handleguest1}
                        >
                          Profile
                        </Dropdown.Item>
                        <Dropdown.Item
                          active
                          // onClick={() => Navigat("/AddProperty")}
                          onClick={handleguest}
                        >
                          Add Property
                        </Dropdown.Item>
                        <Dropdown.Item active onClick={() => setShow(true)}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="hamburger-menu-home-divv">
                  <Hamburger toggle={() => setOpen(!isOpen)} toggled={isOpen} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={!isOpen ? "nonne" : "hamburger-menu-main-sec-divv"}>
          <div className="Home-spann-section-divv">
            <NavLink to={"/Home"} className="Home-spann-home-span">
              Home
            </NavLink>
          </div>
          <div className="Home-spann-section-divv">
            <NavLink to={"/Whoarewe"} className="Home-spann-home-span">
              Who We Are
            </NavLink>
          </div>
          <div className="Home-spann-section-divv">
            <a
              href="mailto:info@homevr.online"
              className="Home-spann-home-span"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginNavbar;
